import { Resources } from './resources.js'
import { Config } from './config.js'
import { Utils } from './utils.js';
import { References } from '../global/references.js';
import { FirebaseFunctions } from './firebaseFunctions.js';

class Global {
    constructor () {
      this.utils = new Utils(this);
      this.references = References;
      this.resourceLangs = Resources.lang;
      this.config = (new Config(this)).config;
      this.checkLangRequest();
      this.config.host.set.call(this.config, this.references.Host.prod);
      this.host = this.config.host.get.call(this.config);
      this.firebaseFunctions = (new FirebaseFunctions(this)).firebaseFunctions;
      this.resources = this.getResources();
      this.resourceNavItems = Resources.lang[this.config.uiLang.get.call(this.config.uiLang)].nav;
      this.user = null;
      this.modules = {};
      this.functions = null;
      this.retrievedData = null;
      this.customSessionData = {};
    }

    logout () {
      const that = this;
      if (that.getUser()) {
        const data = that.modules.Firebase.firebase;
        data.auth().signOut().then(function() {
          console.log('success logged OUT');
          that.relayEvent(that.references.Events.clearOnLogout);
        }).catch(function(error) {
          console.log('ERROR logged OUT ' + error);
        });
      }
    }

    checkLangRequest () {
      const url = new URL(document.location);
      const params = new URLSearchParams(url.search);
  
      const uiLang = params.get("utm_lang");
      if (uiLang && (uiLang == 'heb' || uiLang == 'eng')) {
        this.config.uiLang.set(uiLang);
      }
    }

    // TODO - make larger text, buttons, inputs for mobile

    addModule (module) {
      const instance = new module(this);
      this.modules[instance.name] = instance;
      instance.init();
    }

    setUser (user) {
      this.user = user;
    }

    getUser () {
      return this.user;
    }

    getResources () {
        return Resources.lang[this.config.uiLang.get.call(this.config.uiLang)];
    }

    getResourceLangs () {
        return this.resourceLangs;
    }

    getResourceNavItems () {
        return this.resourceNavItems;
    }

    // getConfig () {
    //     return this.config;
    // }

    checkPwa (rootEl) {
      setTimeout(() => {
        if (window.deferredPrompt) {
          // add install button
          const node = document.createElement('DIV');
          node.id = 'btnPwa';
          const span = document.createElement('SPAN');
          span.id = 'btnPwaInner';
          node.appendChild(span);
          const textnode = document.createTextNode(this.resources.pwa.InstallTitle);
          span.appendChild(textnode);
          rootEl.appendChild(node);
  
          const btnPwa = document.querySelector('#btnPwaInner');
          btnPwa.addEventListener('click', e => {
            window.addEventListener('appinstalled', e => {
              console.log('The IFC app is now installed on this device, thank you and enjoy :)');
            });
            this.doPwaInstall();
          });
        }
      }, 2000);
    }
  
    async doPwaInstall() {
      window.deferredPrompt.prompt();
      console.log('deferredPrompt' + window.deferredPrompt)
      window.deferredPrompt.userChoice.then(function(choiceResult){
        if (choiceResult.outcome === 'accepted') {
          console.log('Your PWA has been installed');
        } else {
          console.log('User chose to not install your PWA');
        }
        window.deferredPrompt = null;
      });
    }

    relayEvent (eventName) {
      for (let prop in this.modules) {
        if (this.modules[prop] && this.modules[prop].eventHandler) {
          this.modules[prop].eventHandler(eventName);
        }
      }
    }
}
  
export { Global };