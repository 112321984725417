class FirebaseFunctions {

    constructor (global) {
        this.firebaseFunctions = this.getFunctions(global);
    }

    getFunctions (global) {
        return {
            getOtherUserByEmail: (data, successCallback, errorCallback) => {
                const getUser = global.functions.httpsCallable('getOtherUserByEmail');
                getUser(data)
                    .then(result => {

                        console.log('getOtherUserByEmail result -----');

                        if (result && result.data) {
                            console.log(result.data);

                            if (result.data.successCode) {

                                // TODO - show loading until this point

                                if (successCallback && typeof successCallback === 'function') { successCallback(result.data); }
                            }
                            else if (result.data.errorCode) {
                                let code = '';
                                switch (result.data.errorCode) {
                                case 'functions/error/getOtherUserByEmail':
                                    code = result.data.errorCode;
                                    break;
                                case 'functions/error-owner-only':
                                    code = result.data.errorCode;
                                    break;
                                default:
                                    code = 'default';
                                }
                                const errorMessage = global.resources.profile.errorTxt.httpsCallable.getOtherUserByEmail[code];
                                if (errorCallback && typeof errorCallback === 'function') { errorCallback(errorMessage); }
                            }
                        }
                    }).catch ((err) => {
                        console.log('Error finding other user - error ' + err);

                        const errorMessage = global.resources.profile.errorTxt.httpsCallable.getOtherUserByEmail.default;
                        if (errorCallback && typeof errorCallback === 'function') { errorCallback(errorMessage); }

                    });        
            },
            addAppRole: (data, successCallback, errorCallback) => {
                // Reference to Firebase function - does not yet call it
                const addAppRole = global.functions.httpsCallable('addAppRole');
                addAppRole(data)
                    .then(result => {
                        console.log('addAppRole result -----');

                        if (result && result.data) {
                            console.log(result.data);

                            if (result.data.successCode) {
                                // TODO - add success message

                                // TODO - refresh credentials - so that will not need to log in/out
                                // reauthenticateUserToken

                                if (successCallback && typeof successCallback === 'function') { successCallback(result.data); }
                            }
                            else if (result.data.errorCode) {
                                let code = '';
                                switch (result.data.errorCode) {
                                    case 'functions/error-owner-only':
                                    code = result.data.errorCode;
                                    break;
                                    case 'functions/error-role-create':
                                    code = result.data.errorCode;
                                    break;
                                    default:
                                    code = 'default';
                                }
                                const errorMessage = global.resources.profile.errorTxt.httpsCallable.addAppRole[code];
                                if (errorCallback && typeof errorCallback === 'function') { errorCallback(errorMessage); }
                            }
                        }
                    }).catch ((err) => {
                        console.log('Error creating admin role - error ' + err);
                        const errorMessage = global.resources.profile.errorTxt.httpsCallable.addAppRole.default;
                        if (errorCallback && typeof errorCallback === 'function') { errorCallback(errorMessage); }
                    });
            }
        };
    }
}
export { FirebaseFunctions };