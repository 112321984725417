import { Login } from '../components/login.js';
import { Notifications } from '../global/notifications.js';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
// import "firebase/messaging";
import "firebase/storage";
import { DataModel } from '../data/data-model.js';

class Firebase {
    constructor (global) {
        this.name = 'Firebase';
        this.global = global;
        this.dataModel = DataModel;
        this.global.retrievedData = new this.dataModel.RetrievedData();
        this.database = null;
        this.storage = null;
        this.dataRoot = null;
        this.storageRoot = null;
        this.firebaseConfig = this.global.config.firebase.firebaseConfig.get.call();
        this.firebase = firebase;
        this.timeoutHandler1 = null;
        this.initiatedConnectStatus = false;
    }

    init () {
        const that = this;

        // Initialize Firebase
        const app = firebase.initializeApp(this.firebaseConfig);
        this.storage = firebase.storage();
        this.database = firebase.database();
        this.global.functions = firebase.functions();
        // console.log(firebase);
        // firebase.analytics();

        this.dataRoot = this.database.ref();
        this.storageRoot = this.storage.ref();
        // this.createDataStructure();

        this.global.addModule(Login);
        this.global.addModule(Notifications);

        firebase.auth().onAuthStateChanged(user => {

            if (user) {
                console.log('User is Now signed in');
                that.refreshUserData(user);
            }
            else {
                console.log('User is Now signed out');
                that.global.setUser(null);
                that.global.relayEvent(that.global.references.Events.userStateChanged);
            }
        });
    }

    // ***** Notes *****
    // Use update to change/add multiple areas of tree that is linked to each other
    // using 1 write command so that if one fails the other will as well.
    // -- var updates = {};
    // -- updates['/posts/' + newPostKey] = postData;
    // -- updates['/user-posts/' + uid + '/' + newPostKey] = postData;
    // -- return firebase.database().ref().update(updates);
    // As opossed to:
    // -- firebase.database().ref().child('/posts/' + newPostKey).set(postData);
    // -- firebase.database().ref().child('/user-posts/' + uid + '/' + newPostKey).set(postData);

    savePushSubscriptionToDatabase (pushSubscription) {
        const subscriptionRef = this.dataRoot;
        const user = this.global.getUser();
        const anonymousId = sessionStorage.getItem('anonymousId');
        let id = '';
        let userType = '';
        const updates = {};

        // check if endpoint exists in Subscriptions/push/user/
        // (if so then do not write to anonymous)


        if (user && user.userId) {
            id = user.userId;
            if (anonymousId) {
                const deleteRef = this.dataRoot.child(`/Subscriptions/push/anonymous/${anonymousId}`);
                const afterDelete = () => sessionStorage.removeItem('anonymousId');
                this.deleteData(deleteRef, afterDelete);
            }
            // "{\"endpoint\":\"https://fcm.googleapis.com/fcm/send/c1d757TDRmI:APA91bHbaQENlHpqm8MkWbTmemQese3X55VXSs5m70V8P1IJ4C7gcZtSjP2C4hun1Scei7NypuC7SF5GsmfRFP7tDXmVoMsZQYbViRgUmNiZUSJwN0orYQjnQZcXHnOB2EpHL8pwjb1B\",\"expirationTime\":null,\"keys\":{\"p256dh\":\"BH0P5H2HGIZRVzgCUbzy7IBnfocypk3VhdHfnAhp_t6G0lSN2AQ5YxJBUPSA9-cnAb8bs9Q5G6DnZnyFO_6CBm8\",\"auth\":\"fyWi3SHOeiEEqrZVll_8AA\"}}"
            // "{\"endpoint\":\"https://fcm.googleapis.com/fcm/send/c1d757TDRmI:APA91bHbaQENlHpqm8MkWbTmemQese3X55VXSs5m70V8P1IJ4C7gcZtSjP2C4hun1Scei7NypuC7SF5GsmfRFP7tDXmVoMsZQYbViRgUmNiZUSJwN0orYQjnQZcXHnOB2EpHL8pwjb1B\",\"expirationTime\":null,\"keys\":{\"p256dh\":\"BH0P5H2HGIZRVzgCUbzy7IBnfocypk3VhdHfnAhp_t6G0lSN2AQ5YxJBUPSA9-cnAb8bs9Q5G6DnZnyFO_6CBm8\",\"auth\":\"fyWi3SHOeiEEqrZVll_8AA\"}}"
            // "{\"endpoint\":\"https://par02p.notify.windows.com/w/?token=BQYAAAA1u941lUqpbDSZ8kV0WmhLlWGS7%2bEHPFVD8Gl8%2fo%2bPD512pA1l9zU8iaFYJbvTC5PLsxPVzjARPSwlMY7GCYBprR0RpfhXbjPIiaTLS6Jj1kGQag9ex1BSQaBFGRBzydemIdGYTwowdTQM1%2fI8cGsjZqq%2f9hiU0qngHg6mWlkxJzkB1B0gCW1sKATpN8mxWe%2fUdJAdffxZ4eylGjbiKgOUhBD6qjHzADF4txU58vqyLJmUh1l3mV2OxQs2FNgjaHgfPazEWivPXu0Sa4AfjQ23ewC0y%2fVDHY9mgFDnkdem9GtmAdnOkjDsKigHGfsXyzYiZmnscZdE2gPML%2fD77lTY\",\"expirationTime\":null,\"keys\":{\"p256dh\":\"BNHao0mTZQpOdcZArpImPEbbeIvOVskUPGvS5afFoP3XYZ_VLcKmDi7Q3V1avYWioDDxFK21R8igVLyaOs6XAdo\",\"auth\":\"tVhULCtSgosbpnvesNJcVA\"}}"
            // "{\"endpoint\":\"https://fcm.googleapis.com/fcm/send/c1d757TDRmI:APA91bHbaQENlHpqm8MkWbTmemQese3X55VXSs5m70V8P1IJ4C7gcZtSjP2C4hun1Scei7NypuC7SF5GsmfRFP7tDXmVoMsZQYbViRgUmNiZUSJwN0orYQjnQZcXHnOB2EpHL8pwjb1B\",\"expirationTime\":null,\"keys\":{\"p256dh\":\"BH0P5H2HGIZRVzgCUbzy7IBnfocypk3VhdHfnAhp_t6G0lSN2AQ5YxJBUPSA9-cnAb8bs9Q5G6DnZnyFO_6CBm8\",\"auth\":\"fyWi3SHOeiEEqrZVll_8AA\"}}"
            userType = 'user';
        }
        else {
            // only do below if pushSubscription.endpoint does not exist in Subscriptions/push/user/
            // (user endpoint will be overwritten for the last devise they used
            // (only show notifs there) and will not be requested on other device if already granted
            // so the subsciption will be only on the last device granted by user)
            // (still will not account for user as annonymous on other devices (notifs will be sent to both))
            // need to also think about it user has multiple login accounts on 1 device
            // if user allow and then blocked, subscription push will through an error
            // (maybe unregister is recieved blocked error)
            id = anonymousId ? anonymousId : this.addItemByKey(subscriptionRef, 'Subscriptions/push/anonymous');
            sessionStorage.setItem('anonymousId', id);
            userType = 'anonymous';
        }
        
        updates[`/Subscriptions/push/${userType}/${id}`] = JSON.stringify(pushSubscription);
        const message = `PushSubscriptions for ${userType} ${id}`;
        this.updateAdd(subscriptionRef, updates, message);
    }
    
    trackUserConnectionStatus (userId) {
        if (this.initiatedConnectStatus === false) {
            this.initiatedConnectStatus = true;

            const userRef = `${this.global.references.DataStructure.users}/${userId}/connection`;

            // Since user can connect from multiple devices / browser tabs, we store each connection instance separately
            // any time that connectionsRef's value is null (i.e. has no children), user am offline
            const myConnectionsRef = this.dataRoot.child(`${userRef}/connections`);
    
            // stores the timestamp of my last disconnect (the last time I was seen online)
            // const lastOnlineRef = this.dataRoot.child(`${userRef}/lastOnline`);
            const lastOnlineRef = this.dataRoot.child(`${userRef}/lastDisconnect`); // should this be /lastDisconnect instead?
    
            const connectedRef = firebase.database().ref('.info/connected');
            connectedRef.on('value', (snap) => {
                if (snap.val() === true) {
                    console.log('user is Connected to database');
    
                    // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                    const connection = myConnectionsRef.push();
                    
                    // When I disconnect, remove this device
                    connection.onDisconnect().remove();

                    // Add this device to my connections list
                    // this value could contain info about the device or a timestamp too
                    const clientInfo = `connectedTime ${firebase.database.ServerValue.TIMESTAMP} | lang ${navigator.language} | online ${navigator.onLine} | platform ${navigator.platform} | userAgent ${navigator.userAgent}`;
                    connection.set(clientInfo); // true
                    
                    // When I disconnect, update the last time I was seen online
                    lastOnlineRef.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
                }
                else {
                    console.log('user is Not Connected to database');
                }
            });
        }
    }

    addItemByKey (parentRef, childName) {
        const newItemKey = parentRef.child(childName).push().key;
        return newItemKey;
    }

    // Update/Add specific data Without changing/deleting other data in tree location
    updateAdd (ref, updates, message, onSuccess) {
        ref.update(updates)
            .then(() => {
                console.log('updated ' + message + ' successfully');
                if (onSuccess && typeof onSuccess === 'function') { onSuccess(); }
            })
            .catch((error) => {
                console.log('error updating ' + message + ' - ' + error);
            });
    }

    updateDataItem (dataRef, value) {
        const updates = {};
        updates[dataRef] = value;
        const message = 'updated data item ' + dataRef + ' to ' + value;
        this.updateAdd(this.dataRoot, updates, message);
    }

    // Add/Replace data after Deleting all previous data in that location
    overwrite (ref, newData, message) {
        ref.set(newData)
            .then(() => {
                console.log('successfully overwriting node data - ' + message);
            });
    }

    // to read data, listen for updates "once" or continuesly using "on"
    readData (path, callback) {
        const dataRef = this.database.ref(path);
        dataRef.once('value', (snapshot) => {
            console.log('read success');
            // console.log(snapshot.val());
            // return snapshot.val();

            if (callback && typeof callback == 'function') {
                // setTimeout(function () { //// TEMP test slow internet / response time
                callback(snapshot.val());
                // }, 5000);
            }

        }, (objError) => {
            console.log(`The read for ${path} failed ` + objError.code);
            // return null;
        });
    }

    // read data and listen for changes
    watchValue (path, callback) {
        const dataRef = this.database.ref(path);
        dataRef.on('value', (snapshot) => {
            // console.log(`watch success ${snapshot.val()}`);

            if (callback && typeof callback == 'function') {
                callback(snapshot.val());
            }

        }, (objError) => {
            // console.log(`The watch for ${path} failed ` + objError.code);
        });
    }

    updateProfile (updates, callback) {
        const user = firebase.auth().currentUser;

        user.updateProfile(updates).then(function() {
            if (callback && typeof callback == 'function') {
                // TODO - update realtime database for User
                callback();
            }
        }).catch(function(error) {
            console.log('The update profile failed ' + error);
        });
    }

    updateEmail (newEmail, callback, errorCallback) {
        const user = firebase.auth().currentUser;

        user.updateEmail(newEmail).then(function() {
            if (callback && typeof callback == 'function') {
                // TODO - update realtime database for User
                callback();
            }
        }).catch(function(error) {
            if (errorCallback && typeof errorCallback == 'function') {
                errorCallback(error);
            }
        });
    }
    
    // retrieve/read results in ordered format by key
    orderByKey (path) {
        const dataRef = this.database.ref(path);
        dataRef.orderByKey().on('child_added', (snapshot) => {
            // will print for each item in path
            console.log(snapshot.key);
        });
    }

    // retrieve/read results ordered by field value within node
    orderByChild (path, orderBy) {
        const dataRef = this.database.ref(path);
        dataRef.orderByChild(orderBy).on('child_added', (snapshot) => {
            // will print for each item in path
            console.log(snapshot.val()[orderBy]);
        });
    }

    deleteData (dataRef, callback, onError) {
        dataRef.remove()
            .then(function() {
                console.log("Delete succeeded");
                if (callback && typeof callback === 'function') { callback(); }
            })
            .catch(function(error) {
                console.log("Delete failed: " + error.message);
                if (onError && typeof onError === 'function') { onError(error.message); }
            });
    }

    deleteStorage (dataRef, callback) {
        dataRef.delete()
            .then(function() {
                console.log("Delete succeeded");
                if (callback && typeof callback === 'function') { callback(); }
            })
            .catch(function(error) {
                console.log("Delete failed: " + error.message)
            });
    }

    // writeUserData(newKey, newVal) {
    //     const data = {
    //         name: newKey,
    //         val: newVal
    //     };
    //     const userRef =  this.database.ref('test1/' + newKey);
    //     const message = 'new user added with id ' + newKey;
    //     this.overwrite(userRef, data, message);
    // }

    // TODO - create triggers to update/delete data that is linked to other parts of tree
    // (cloud functions listening to value changes)

    reauthenticateUserToken(password, successCallback, errorCallback) {
        const credentials = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, password);
        firebase.auth().currentUser.reauthenticateWithCredential(credentials)
            .then(result => {
                console.log('User successfully reauthenticated. New ID token should be valid.');
                if (successCallback && typeof successCallback === 'function') { successCallback(result.user); }
            })
            .catch(error => {
                console.log('Error reauthenticating ID token');
                if (errorCallback && typeof errorCallback === 'function') { errorCallback(error); }
            });
    }

    getUserDataModel (userId, data) {
        return this.dataModel.UserData(userId, data);
    }

    refreshUserData (user) {
        const that = this;
        if (user) {
            // check user claims (roles)
            user.getIdTokenResult().then(getIdTokenResult => {
    
                // console.log('---- claim logs ----');
                // console.log(getIdTokenResult.claims.admin);
                // console.log(getIdTokenResult.claims)
                // console.log('---- claim logs ----');
    
                // TODO - check why getIdTokenResult.claims.email_verified = false
                // also user.emailVerified = false
                // need to verify email?
    
                // providerData: Array(1)
                //     displayName: null
                //     email: "jimmyjlevy@gmail.com"
                //     phoneNumber: null
                //     photoURL: null
                //     providerId: "password"
                //     uid: "jimmyjlevy@gmail.com"
    
                let providerData = null;
                if (user.providerData && user.providerData.length) {
                    // user.providerData[0].accessLevel = getIdTokenResult.claims.accessLevel || null;
                    providerData = user.providerData[0];
                }
                const claims = getIdTokenResult.claims.accessLevel || null;
    
                that.global.utils.getData(that.global.references.DataStructure.users, (users) => {
                    that.global.retrievedData.users = users;
                    if (users) {
                        let userId = Object.keys(users).find(key => { return users[key].providerData.email.normalize() === providerData.email.normalize() });
                        if (!userId) {
                            // if user updated email address
                            userId = Object.keys(users).find(key => { return key === claims.userId });
                        }
                        const userData = users[userId];
                        //!!!!! start TEMP until all previously registered users are added !!!!////
                        if (!userData) {
                            that.updateUserInfoOnDatabase(providerData.email, { providerData: providerData, claims: claims });
                            console.log('Warning - registered user was not previously updated to database - please refresh page');
                            // TODO - send callback with window reload, then can keep this
                            // incase a user is added through firebase
                            return;
                        }
                        //!!!!! end TEMP until all previously registered users are added !!!!////

                        this.trackUserConnectionStatus(userId);

                        userData['userId'] = userId;
                        userData.providerData = providerData;
                        userData.accessLevel = claims;

                        userData.accessLevel.ifcTeamMeber = userData.accessLevel.ifcTeamMeber || null;
                        userData.accessLevel.isInfluencer = userData.accessLevel.isInfluencer || null;
                        userData.accessLevel.associatedGroups = userData.accessLevel.associatedGroups || null;
                        userData.accessLevel.associatedPosts = userData.accessLevel.associatedPosts || null;
                        userData.accessLevel.associatedInfluencers = userData.accessLevel.associatedInfluencers || null;
                        userData.accessLevel.associatedPrivatePojects = userData.accessLevel.associatedPrivatePojects || null;

                        // update user database
                        that.updateUserInfoOnDatabase(userData.providerData.email, null, true, userId, userData);

                        // update user claims
                        if (userData.accessLevel && userData.accessLevel.role && userData.accessLevel.editRights) {
                            const role = Object.getOwnPropertyNames(userData.accessLevel.role)[0];
                            const rights = userData.accessLevel.editRights;

                            that.global.firebaseFunctions.addAppRole({
                                email: userData.providerData.email,
                                type: role,
                                isNewRegister: false,
                                updatedRights: rights,
                                userId: userId,
                                isUpdate: true,
                                ifcTeamMeber: userData.accessLevel.ifcTeamMeber,
                                isInfluencer: userData.accessLevel.isInfluencer,
                                associatedGroups: userData.accessLevel.associatedGroups,
                                associatedPosts: userData.accessLevel.associatedPosts,
                                associatedInfluencers: userData.accessLevel.associatedInfluencers,
                                associatedPrivatePojects: userData.accessLevel.associatedPrivatePojects
                            },
                            (addRoleResult) => {
                                console.log(`Success updating accessLevel after login`);
                            },
                            (errorMessage) => {
                                console.log(`Error in updating accessLevel after login ${errorMessage}`);
                            });
                        }
                    }
                });
            });
        }
        else {
            this.global.setUser(null);
            this.global.relayEvent(this.global.references.Events.userStateChanged);
        }
    }

    updateUserInfoOnDatabase (email, firebaseUserData, isUpdate, userId, userData, isOtherUser) {
        const addUserRef = this.dataRoot;

        // add user to db with auto gen key
        const userKey = isUpdate ? userId : this.addItemByKey(addUserRef, 'Users');
        
        const newData = isUpdate ? userData : this.getUserDataModel(userKey, firebaseUserData);

        this.clearUnusedData(newData, this.global.references.DataStructure.users);

        if (!isOtherUser) {
            this.global.setUser(newData);
            if (window.pushSubscription && this.global.getUser()) {
                this.savePushSubscriptionToDatabase(window.pushSubscription)
            }
            this.global.relayEvent(this.global.references.Events.userStateChanged);
        }

        const updates = {};
        updates['/Users/' + userKey] = newData;

        const message = 'user ' + email;
        this.updateAdd(addUserRef, updates, message);
    }

    addUserToDatabase (email, userId, userData, onSuccess) {
        const addUserRef = this.dataRoot;
        this.clearUnusedData(userData, this.global.references.DataStructure.users);

        const updates = {};
        updates['/Users/' + userId] = userData;

        const message = 'user ' + email;
        this.updateAdd(addUserRef, updates, message, onSuccess);
    }

    clearUnusedData (dataObject, type) {
        if (dataObject) {
            switch (type) {
                case this.global.references.DataStructure.users:
                    dataObject.roleAccess = null;
                    break;
            }
            return dataObject;
        }
    }

    deleteVideo (videoId) {
        const that = this;
        const videoData = this.global.retrievedData.videos.featuredVideos[videoId];
        
        // TODO - put all paths in references.js

        const updates = {};
        updates['/Deleted/videos/' + videoId] = videoData;
        const message = 'deleted video ' + videoId;
        this.updateAdd(this.dataRoot, updates, message);

        this.storageDeleteRecoverVideoCover(videoId, videoData.poster);

        const afterDelete = () => {
            that.global.retrievedData.recentlyDeleted.videos[videoId] = videoData;
            setTimeout(() => { that.global.relayEvent(that.global.references.Events.videoDeleted); }, 0);
        };
        
        const deleteRef = this.dataRoot.child('/Videos/' + videoId);
        this.deleteData(deleteRef, afterDelete);
    }

    deletePost (postId) {
        const that = this;
        const postData = this.global.retrievedData.posts[postId];
        
        const updates = {};
        updates['/Deleted/posts/' + postId] = postData;
        const message = 'deleted post ' + postId;
        this.updateAdd(this.dataRoot, updates, message);

        if (postData.media.image) {
            this.storageDeleteRecoverPostPhoto(postId, postData.media.image);
        }

        const afterDelete = () => {
            that.global.retrievedData.recentlyDeleted.posts[postId] = postData;
            setTimeout(() => { that.global.relayEvent(that.global.references.Events.postDeleted); }, 0);
        };
        
        const deleteRef = this.dataRoot.child('/Posts/' + postId);
        this.deleteData(deleteRef, afterDelete);
    }

    deleteGroup (groupId) {
        const that = this;
        const groupData = this.global.retrievedData.groups[groupId];
        
        const updates = {};
        updates['/Deleted/groups/' + groupId] = groupData;
        const message = 'deleted group ' + groupId;
        this.updateAdd(this.dataRoot, updates, message);

        // TODO - delete admin on user's data (associatedGroups)
        // add them back as admins on restore group

        this.storageDeleteRecoverGroupLogo(groupId, groupData.logo);

        const afterDelete = () => {
            that.global.retrievedData.recentlyDeleted.groups[groupId] = groupData;
            setTimeout(() => { that.global.relayEvent(that.global.references.Events.groupDeleted); }, 0);
        };
        
        // TODO - make groups uppercase g (for root of database elements)
        const deleteRef = this.dataRoot.child('/groups/' + groupId);
        this.deleteData(deleteRef, afterDelete);
    }

    deleteUser (userId, onSuccess, onError) {
        const that = this;
        this.global.utils.getData(this.global.references.DataStructure.users, (users) => {
            this.global.retrievedData.users = users;
            const userData = users[userId];

            const updates = {};
            updates['/Deleted/users/' + userId] = userData;
            const message = 'deleted user ' + userId;
            this.updateAdd(this.dataRoot, updates, message);
            // TODO - permenantly delete after 30 days

            // TODO - DELETE user on Firebase Auth

            // TODO - DELETE user associate content
            // delete the following
            // Influencer if isInfluencer
            // as Admin from accouciated groups
            // posts/videos updoaded
            // Subscriptions??

            if (userData.providerData.photoURL) {
                this.storageDeleteRecoverProfileImage(userId, userData.providerData.photoURL);
            }

            const afterDelete = () => {
                const user = firebase.auth().currentUser;
                user.delete().then(function() {
                    // TODO - at this point user is deleted from auth,
                    // if database delete fails need to think of how to delete later
                    that.global.retrievedData.recentlyDeleted.users[userId] = userData;
                    setTimeout(() => { that.global.relayEvent(that.global.references.Events.userDeleted); }, 0);
                    if (onSuccess && typeof onSuccess === 'function') { onSuccess(); }
                }).catch(function(error) {
                    if (onError && typeof onError === 'function') { onError(error.message); }
                });
            };
            
            const deleteRef = this.dataRoot.child('/Users/' + userId);
            this.deleteData(deleteRef, afterDelete, onError);
        });
    }

    deleteDatabaseItem (path, onSuccess, onError) {       
        const deleteRef = this.dataRoot.child(path);
        this.deleteData(deleteRef, onSuccess, onError);
    }

    addEditPost (dataObj, imageFile, imageName, keepOriginalImage, isEdit, postId, createdDate, isRecover) {
        // TODO on undo (isRecover) remove from Deleted on database

        const addPostRef = this.dataRoot;
        const postKey = isEdit ? postId : this.addItemByKey(addPostRef, 'Posts');
        const postData = isRecover ? dataObj : this.dataModel.Post(postKey, createdDate, dataObj);
        const message = 'post ' + postKey;
        const updates = {};

        // TODO - update UserData.accessLevel.associatedPosts && Group.associatedPosts
        // check if group of influencer
        // group Group.associatedPosts = postId
        // influencer Influencer.accessLevel.associatedPosts = postId

        const addPost = () => {
            updates['/Posts/' + postKey] = postData;
            this.updateAdd(addPostRef, updates, message);

            setTimeout(() => { this.global.relayEvent(this.global.references.Events.postAddedUpdated); }, 0);
            // TODO find better way then timeout to relay event above
        };

        if (keepOriginalImage || !imageFile) {
            addPost();
            if (isRecover) {
                this.storageDeleteRecoverPostPhoto(postId, postData.media.image, isRecover);
            }
        }
        else if (imageFile) {
            this.storageUploadPostPhoto(imageFile, imageName, postKey, () => {
                addPost();
            });
        }
    }

    addEditVideo(dataObj, imageFile, imageName, keepOriginalCover, isEdit, videoId, uploadDate, isRecover) {
        // TODO on undo (isRecover) remove from Deleted on database
        
        const addVideoRef = this.dataRoot;
        const videoKey = isEdit ? videoId : this.addItemByKey(addVideoRef, 'Videos');
        const videoData = isRecover ? dataObj : this.dataModel.Video(videoKey, uploadDate, dataObj);
        const message = 'video ' + videoKey;
        const updates = {};

        const addVideo = () => {
            updates['/Videos/' + videoKey] = videoData;
            this.updateAdd(addVideoRef, updates, message);
            setTimeout(() => { this.global.relayEvent(this.global.references.Events.newVideoAdded); }, 0);
        };

        if (keepOriginalCover) {
            addVideo();
            if (isRecover) {
                this.storageDeleteRecoverVideoCover(videoId, videoData.poster, isRecover);
            }
        }
        else {
            this.storageUploadVideoCover(imageFile, imageName, videoKey, () => {
                addVideo();
            });
        }
    }

    addEditGroup (dataObj, imageFile, imageName, keepOriginalLogo, isEdit, groupId, createdDate, isRecover, allUsers) {
        // TODO on undo (isRecover) remove from Deleted on database

        const addGroupRef = this.dataRoot;
        const groupKey = isEdit ? groupId : this.addItemByKey(addGroupRef, 'groups');
        const groupData = isRecover ? dataObj : this.dataModel.Group(groupKey, createdDate, dataObj);
        const message = 'group ' + groupKey;
        const updates = {};

        const addGroup = () => {
            updates['/groups/' + groupKey] = groupData;
            this.updateAdd(addGroupRef, updates, message);

            setTimeout(() => { this.global.relayEvent(this.global.references.Events.groupAddedUpdated); }, 0);
            // TODO find better way then timeout to relay event above
        };

        const addAdmins = () => {
            const doAdd = () => {
                let userData = null;
                for (let prop in dataObj.groupAdmins) {
                    if (allUsers && allUsers[prop]) {
                        userData = allUsers[prop];
                        userData.accessLevel.ifcTeamMeber =  userData.accessLevel.ifcTeamMeber || null;
                        userData.accessLevel.isInfluencer = userData.accessLevel.isInfluencer || null;
                        userData.accessLevel.associatedGroups = userData.accessLevel.associatedGroups ? userData.accessLevel.associatedGroups : {};
                        if (!!dataObj.groupAdmins[prop]) {
                            userData.accessLevel.associatedGroups[groupId] = true;
                        }
                        else {
                            delete userData.accessLevel.associatedGroups[groupId];
                        }
                        if (Object.keys(userData.accessLevel.associatedGroups).length === 0) {
                            userData.accessLevel.associatedGroups = null;
                        }
                        userData.accessLevel.associatedPosts = userData.accessLevel.associatedPosts || null;
                        userData.accessLevel.associatedInfluencers = userData.accessLevel.associatedInfluencers || null;
                        userData.accessLevel.associatedPrivatePojects = userData.accessLevel.associatedPrivatePojects || null;

                        // update database for user's associatedGroups
                        this.updateUserInfoOnDatabase(userData.providerData.email, null, true, userData.userId, userData, true);
                        
                        // update claims for user's associatedGroups
                        if (userData.accessLevel && userData.accessLevel.role && userData.accessLevel.editRights) {
                            const role = Object.getOwnPropertyNames(userData.accessLevel.role)[0];
                            const rights = userData.accessLevel.editRights;
                            
                            this.global.firebaseFunctions.addAppRole({
                                email: userData.providerData.email,
                                type: role,
                                isNewRegister: false,
                                updatedRights: rights,
                                userId: userData.userId,
                                isUpdate: true,
                                ifcTeamMeber: userData.accessLevel.ifcTeamMeber,
                                isInfluencer: userData.accessLevel.isInfluencer,
                                associatedGroups: userData.accessLevel.associatedGroups,
                                associatedPosts: userData.accessLevel.associatedPosts,
                                associatedInfluencers: userData.accessLevel.associatedInfluencers,
                                associatedPrivatePojects: userData.accessLevel.associatedPrivatePojects
                            },
                            (addRoleResult) => {
                                console.log(`Success adding role after login`);
                            },
                            (errorMessage) => {
                                console.log(`Error in adding role after login ${errorMessage}`);
                            });
                        }
                    }
                }
                

                setTimeout(() => { this.global.relayEvent(this.global.references.Events.groupAddedUpdated); }, 0);
                // TODO find better way then timeout to relay event above
            };

            if (!allUsers) {
                this.global.utils.getData(this.global.references.DataStructure.users, (users) => {
                    this.global.retrievedData.users = users;
                    if (users) {
                        allUsers = users;
                        doAdd();
                    }
                });
            }
            else {
                doAdd();
            }
        };
        // check if to do this on delete group (delete asscociated groups from users)
        // then add back admins if isRecover

        if (keepOriginalLogo) {
            addGroup();
            addAdmins();
            if (isRecover) {
                this.storageDeleteRecoverGroupLogo(groupId, groupData.logo, isRecover);
            }
        }
        else {
            this.storageUploadGroupLogo(imageFile, imageName, groupKey, () => {
                addGroup();
                addAdmins();
            });
        }
    }

    storageDeleteRecoverPostPhoto (postId, imageName, isRecover) {
        // mark metadata as Deleted
        const isDeleted = isRecover ? null : true;
        const updtedmetadata = {
            customMetadata: {
                isDeleted: isDeleted
            }
        };
        const message = 'isDeleted';
        const imageRef = this.storageRoot.child('/public/posts/' + postId + '/' + imageName);
        this.storageUpdateMetaData(imageRef, updtedmetadata, message);
    }

    storageDeleteRecoverGroupLogo (groupId, imageName, isRecover) {
        // mark metadata as Deleted
        const isDeleted = isRecover ? null : true;
        const updtedmetadata = {
            customMetadata: {
                isDeleted: isDeleted
            }
        };
        const message = 'isDeleted';
        const imageRef = this.storageRoot.child('/public/groups/' + groupId + '/' + imageName);
        this.storageUpdateMetaData(imageRef, updtedmetadata, message);
    }

    storageDeleteRecoverVideoCover (videoId, imageName, isRecover) {
        // mark metadata as Deleted
        const isDeleted = isRecover ? null : true;
        const updtedmetadata = {
            customMetadata: {
                isDeleted: isDeleted
            }
        };
        const message = 'isDeleted';
        // const ref = this.storageRoot.child('/public/videos/' + videoId + '/' + imageName);
        const imageRef = this.storageRoot.child('/public/videos/' + videoId + '/' + imageName);
        this.storageUpdateMetaData(imageRef, updtedmetadata, message);
    }

    storageDeleteRecoverProfileImage (userId, imageName, isRecover) {
        // mark metadata as Deleted
        const isDeleted = isRecover ? null : true;
        const updtedmetadata = {
            customMetadata: {
                isDeleted: isDeleted
            }
        };
        const message = 'isDeleted';
        const imageRef = this.storageRoot.child('/public/users/' + userId + '/' + imageName);
        this.storageUpdateMetaData(imageRef, updtedmetadata, message);
    }

    addRemoveInfluencer(dataObj, userId, isInfluencer, isRecover) {
        const addInfluencerRef = this.dataRoot;

        // TODO on undo (isRecover) remove from Deleted on database
        
        /// TODO get Influencer id where Influencer.userId = userId
        // do below as callback
        const influencerRef = addInfluencerRef.child("Influencers").orderByChild("userId").equalTo(userId);
        // const dataRef = this.database.ref(path);
        influencerRef.once('value', (snapshot) => {
            const existingKey = snapshot.val() ? Object.keys(snapshot.val())[0] : null;
            const influencerKey = existingKey ? existingKey : this.addItemByKey(addInfluencerRef, 'Influencers');
            const InfluencersData = existingKey ? null : (isRecover ? dataObj : this.dataModel.Influencer(userId, dataObj));
            const message = 'influencer ' + influencerKey;
            const updates = {};

            
            updates['/Influencers/' + influencerKey] = InfluencersData;
            this.updateAdd(addInfluencerRef, updates, message);
            
            const isDelete = !!existingKey; // TODO - using !!existingKey creates a situatoin if an influenecer is accidently in the database, then adding him will remove instead (need to fix)
            if (isDelete) {
                console.log('Deleting Influencer');
                console.log(snapshot.val()[Object.keys(snapshot.val())[0]]);
                // Todo - add dataObj to Deleted (for recover)
                //     const updatesDeleted = {};
                //     updatesDeleted['/Deleted/influencers/' + influencerKey] = snapshot.val()[Object.keys(snapshot.val())[0]];
                //     const message = 'deleted influencer ' + influencerKey;
                //     this.updateAdd(this.dataRoot, updatesDeleted, message);
                setTimeout(() => { this.global.relayEvent(this.global.references.Events.influencerDeleted); }, 0);
            }
            else {
                setTimeout(() => { this.global.relayEvent(this.global.references.Events.influencerAdded); }, 0);
            }

        }, (objError) => {
            console.log(`The read checking influencer failed ` + objError.code);
            // return null;
        });

    }

    tempAddItem (videoId) {
        // const uploadDate = (new Date()).getTime();
        // const addPostRef = this.dataRoot;
        // const updates = {};
        // updates['/Videos/' + videoId + '/uploadDate'] = uploadDate;
        // const message = 'new temp Item added: ' + '/Videos/' + videoId + '/uploadDate/' + uploadDate;

        // this.updateAdd(addPostRef, updates, message);
    }

    handleFiles (inputFile) {
        return inputFile.files[0];
    }

    storageUpdateMetaData (ref, updtedmetadata, message) {
        ref.updateMetadata(updtedmetadata).then(function(metadata) {
            console.log(`Successfully updated the storage file's ${message} metadata`);
            console.log(metadata);
        }).catch(function(error) {
            console.log(`There was an error in updating the storage file's ${message} metadata - Error ${error}`);
        });
    }

    storageUploadVideoCover (imageFile, imageName, videoId, doVideoAdd) {
        const metadata = {
            customMetadata: {
                contentType: imageFile.type,
                originalName: imageFile.name,
                size: imageFile.size,
                forVideoId: videoId
            }
        };

        const videos = this.global.retrievedData.videos.featuredVideos;
        if (videos && Object.keys(videos).length) {
            if (videos[videoId] && videos[videoId].poster) {
                const deletePreviousRef = this.storageRoot.child('/public/videos/' + videoId + '/' + videos[videoId].poster);
                this.deleteStorage(deletePreviousRef);
            }
        }

        const ref = this.storageRoot.child('/public/videos/' + videoId + '/' + imageName);
        ref.put(imageFile, metadata).then(function (snapshot) {
            doVideoAdd();
            console.log('Uploaded image file for ' + videoId + ' successfully!');
            // console.log(snapshot);

            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                // console.log('File available at', downloadURL);
            });
        }).catch(function (err) {
            console.log('Error in Uploading image file for ' + videoId + '. Error - ' + err);
        });
    }

    storageUploadGroupLogo (imageFile, imageName, groupId, doGroupAdd) {
        const metadata = {
            customMetadata: {
                contentType: imageFile.type,
                originalName: imageFile.name,
                size: imageFile.size,
                forGroupId: groupId
            }
        };

        const groups = this.global.retrievedData.groups;
        if (groups && Object.keys(groups).length) {
            if (groups[groupId] && groups[groupId].logo) {
                const deletePreviousRef = this.storageRoot.child('/public/groups/' + groupId + '/' + groups[groupId].logo);
                this.deleteStorage(deletePreviousRef);
            }
        }

        const ref = this.storageRoot.child('/public/groups/' + groupId + '/' + imageName);
        ref.put(imageFile, metadata).then(function (snapshot) {
            doGroupAdd();
            console.log('Uploaded image file for ' + groupId + ' successfully!');
            // console.log(snapshot);

            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                // console.log('File available at', downloadURL);
            });
        }).catch(function (err) {
            console.log('Error in Uploading image file for ' + groupId + '. Error - ' + err);
        });
    }

    storageUploadPostPhoto (imageFile, imageName, postId, doAddPost) {
        const metadata = {
            customMetadata: {
                contentType: imageFile.type,
                originalName: imageFile.name,
                size: imageFile.size,
                forPostId: postId
            }
        };

        const posts = this.global.retrievedData.posts;
        if (posts && Object.keys(posts).length) {
            if (posts[postId] && posts[postId].media.image) {
                const deletePreviousRef = this.storageRoot.child('/public/posts/' + postId + '/' + posts[postId].media.image);
                this.deleteStorage(deletePreviousRef);
            }
        }

        const ref = this.storageRoot.child('/public/posts/' + postId + '/' + imageName);
        ref.put(imageFile, metadata).then(function (snapshot) {
            doAddPost();
            console.log('Uploaded image file for ' + postId + ' successfully!');
            // console.log(snapshot);

            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                // console.log('File available at', downloadURL);
            });
        }).catch(function (err) {
            console.log('Error in Uploading image file for ' + postId + '. Error - ' + err);
        });
    }

    storageUploadProfileImage (imageFile, imageName, user) {
        const that = this;
        const metadata = {
            customMetadata: {
                contentType: imageFile.type,
                originalName: imageFile.name,
                size: imageFile.size,
                forUserId: user.userId
            }
        };
        
        if (user.providerData && user.providerData.photoURL) {
            const deletePreviousRef = this.storageRoot.child('/public/users/' + user.userId + '/' + user.providerData.photoURL);
            this.deleteStorage(deletePreviousRef);
        }

        const ref = this.storageRoot.child('/public/users/' + user.userId + '/' + imageName);
        ref.put(imageFile, metadata).then(function (snapshot) {
            console.log('Uploaded image file for ' + user.userId + ' successfully!');
            // console.log(snapshot);
            const updates = {};
            updates['photoURL'] = imageName;
            that.updateProfile(updates, () => {
                that.global.relayEvent(that.global.references.Events.userStateChanged);
                that.refreshUserData(firebase.auth().currentUser);
            });

            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                // console.log('File available at', downloadURL);
            });
        }).catch(function (err) {
            console.log('Error in Uploading image file for ' + user.userId + '. Error - ' + err);
        });
    }

    // use transactions to execute reads and writes as atomic units
    // meaning write based on the results of a read
    incrementValue (path) {
        const datRef = this.dataRoot.child(path);
        datRef.transaction((value) => {
            value = value ? value + 1 : 1; // <--- read then write
            return value;
        });
    }
    decreaseValue (path) {
        const datRef = this.dataRoot.child(path);
        datRef.transaction((value) => {
            value = value && (value - 1 > 0) ? value - 1 : 0; // <--- read then write
            return value;
        });
    }

    postReact (addSubtact, type, userId, postId) {
        const addUserRef = this.dataRoot;

        // reactions: {
        //     total: { count: 0 },
        //     like: { userIds: {}, count: 0 },
        //     happy: { userIds: {}, count: 0 },

        const pathId = `${this.global.references.DataStructure.posts}/${postId}/reactions/type/${type}/userIds/${userId}`;
        const pathCount = `${this.global.references.DataStructure.posts}/${postId}/reactions/type/${type}/count`;
        const pathTotal = `${this.global.references.DataStructure.posts}/${postId}/reactions/total/count`;
        const pathTotalId = `${this.global.references.DataStructure.posts}/${postId}/reactions/total/userIds/${userId}`;
        const addRemoveVal = addSubtact == 'incrementValue' ? true : null;

        const updates = {};
        updates[pathId] = addRemoveVal;
        const message = `added/removed user ${userId} post ${type} from type`;
        this.updateAdd(addUserRef, updates, message);

        const updatesTotal = {};
        updatesTotal[pathTotalId] = addRemoveVal;
        const messageTotal = `added/removed user ${userId} post ${type} from total`;
        this.updateAdd(addUserRef, updatesTotal, messageTotal);

        this.global.modules.Firebase[addSubtact](pathCount);
        this.global.modules.Firebase[addSubtact](pathTotal);
    }

    eventHandler (eventName) {
        switch (eventName) {
            case this.global.references.Events.userStateChanged:
            case this.global.references.Events.updateUserItems:
                clearTimeout(this.timeoutHandler1);
                this.timeoutHandler1 = setTimeout(() => {
                    // console.log('eventHandler Firebase - user state changed / update user items - showHideData');
                    this.showHideData();
                }, 400);
                break;
            default:
                break;
        }
    }
    
    showHideData () {
        // show/hide admin only elements

        const checkPermissionsItems = document.querySelectorAll('.checkPermissions');
        checkPermissionsItems.forEach(item => {
            this.checkPermissions(item);
        });
        
        const user = this.global.getUser();
        const loggedinOnlyItems = document.querySelectorAll('.loggedinOnly');
        loggedinOnlyItems.forEach(item => {
            if (user) {
                item.classList.remove('hidden');
            }
            else {
                item.classList.add('hidden');
            }
        });

        // console.log('show/hide editing');

        // checkPermissionsItems.forEach(item => {
        //     if (user && user.providerData && user.providerData.accessLevel) { // providerData.accessLevel.role
        //         if (user.providerData.accessLevel.role && (user.providerData.accessLevel.role.admin || user.providerData.accessLevel.role.owner)) {
        //             item.classList.remove('hidden');
        //         }
        //         else {
        //             item.classList.add('hidden');
        //         }
        //     }
        //     else {
        //         item.classList.add('hidden');
        //     }
        // });

        // const ownerOnlyItems = document.querySelectorAll('.ownerOnly');
        // ownerOnlyItems.forEach(item => {
        //     if (user && user.providerData && user.providerData.accessLevel) {
        //         if (user.providerData.accessLevel.role && user.providerData.accessLevel.role.owner) {
        //             item.classList.remove('hidden');
        //         }
        //         else {
        //             item.classList.add('hidden');
        //         }
        //     }
        //     else {
        //         item.classList.add('hidden');
        //     }
        // });
    }

    checkPermissions (htmlElement) {
        // htmlElement.classList.remove('hidden');
        // return;
        const user = this.global.getUser();
        const groups = this.global.retrievedData.groups;
        
        let featureToShow = null;
        if (htmlElement.classList.contains('edit_featuredVideos')) {
            featureToShow = 'featuredVideos';
        }
        else if (htmlElement.classList.contains('edit_editGroups')) {
            featureToShow = 'editGroups';
        }
        else if (htmlElement.classList.contains('edit_editTeam')) {
            featureToShow = 'editTeam';
        }
        else if (htmlElement.classList.contains('edit_editPosts')) {
            featureToShow = 'editPosts';
        }
        else if (htmlElement.classList.contains('edit_assignRoles')) {
            featureToShow = 'assignRoles';
        }
        else if (htmlElement.classList.contains('edit_assignGroupAdmins')) {
            featureToShow = 'assignGroupAdmins';
        }
        
        htmlElement.classList.add('hidden');
        const contentId = htmlElement.getAttribute('data-content-id');

        const isHiddenGroup = htmlElement.parentNode.classList.contains('isHiddenGroup');
        if (isHiddenGroup) {
            htmlElement.parentNode.classList.remove('show');
            htmlElement.parentNode.classList.add('hide');
        }
        const isHiddenPost = htmlElement.parentNode.parentNode.classList.contains('isHiddenPost');
        if (isHiddenPost) {
            htmlElement.parentNode.parentNode.classList.remove('show');
            htmlElement.parentNode.parentNode.classList.add('hide');
        }

        if (user) {
            if (user.accessLevel) {
                if (user.accessLevel.editRights) {
                    
                    if (isHiddenGroup) {
                        if ((contentId && user.accessLevel.associatedGroups && user.accessLevel.associatedGroups[contentId]) || user.accessLevel.editRights[featureToShow]) {
                            htmlElement.parentNode.classList.remove('hide');
                            htmlElement.parentNode.classList.add('show');
                            this.global.utils.readMoreTruncate(htmlElement.parentNode.querySelector('.groupItemDesc'), '.groupItemDescText', 5);
                        }
                        else {
                            htmlElement.parentNode.classList.remove('show');
                            htmlElement.parentNode.classList.add('hide');
                        }
                    }

                    if (isHiddenPost) {
                        const postedById = htmlElement.parentNode.parentNode.getAttribute('data-posted-by-id');
                        const showhidden = () => {
                            htmlElement.parentNode.parentNode.classList.remove('hide');
                            htmlElement.parentNode.parentNode.classList.add('show');
                            this.global.utils.readMoreTruncate(htmlElement.parentNode.parentNode.querySelectorAll('.postText'), null, 12);
                        };

                        if (user.accessLevel.editRights[featureToShow]) {
                            showhidden();
                        }

                        // edit group post
                        if (postedById && user.accessLevel.associatedGroups && user.accessLevel.associatedGroups[postedById]) {
                            showhidden();
                        }
                        // edit influencer post
                        else if (postedById) {
                            if (user.accessLevel.isInfluencer && user.accessLevel.userId === postedById) {
                                showhidden();
                            }
                            else if (user.accessLevel.associatedInfluencers && user.accessLevel.associatedInfluencers[postedById]) {
                                showhidden();
                            }
                        }
                    }

                    if(user.accessLevel.editRights[featureToShow]) {
                        htmlElement.classList.remove('hidden');
                    }
                    else {
                        if (featureToShow === 'editPosts') {
                            const postedById = htmlElement.parentNode.parentNode.getAttribute('data-posted-by-id');

                            // add post
                            if (htmlElement.classList.contains('edit_addPosts')) {
                                if (user.accessLevel.associatedGroups || user.accessLevel.associatedInfluencers || user.accessLevel.isInfluencer) {
                                    htmlElement.classList.remove('hidden');
                                }
                            }
                            // edit group post
                            else if (postedById && user.accessLevel.associatedGroups && user.accessLevel.associatedGroups[postedById]) {
                                htmlElement.classList.remove('hidden');
                            }
                            // edit influencer post
                            else if (postedById) {
                                if (user.accessLevel.isInfluencer && user.accessLevel.userId === postedById) {
                                    htmlElement.classList.remove('hidden');
                                }
                                else if (user.accessLevel.associatedInfluencers && user.accessLevel.associatedInfluencers[postedById]) {
                                    htmlElement.classList.remove('hidden');
                                }
                            }

                            // // edit group post
                            // if (groups && groups[postedById] && groups[postedById].associatedPosts && groups[postedById].associatedPosts[contentId]) {
                            //     // do same as above for influencers (users)
                            //     if (contentId && user.accessLevel.associatedGroups && user.accessLevel.associatedGroups[postedById]) {
                            //         htmlElement.classList.remove('hidden');
                            //     }
                            // }
                            // // edit influencer post
                            // else if (contentId && user.accessLevel.associatedPosts && user.accessLevel.associatedPosts[contentId]) {
                            //     htmlElement.classList.remove('hidden');
                            // }
                        }
                        else if (featureToShow === 'editGroups') {
                            if (contentId && user.accessLevel.associatedGroups && user.accessLevel.associatedGroups[contentId]) {
                                htmlElement.classList.remove('hidden');
                            }
                        }
                        

                        // check user is the admin of a group (or content is owned by them)
                    }
                }
            }
        }
    }

    createDataStructure () {
        // upload videos from JSON
        // for (let prop in Videos) {
        //     this.addEditVideo(Videos[prop]);
        // }


        // console.log("----database---");
        // console.log(this.database);
        // console.log("----database---");

        // TEST !!!!!!!!!!!!!!!!!!!


        // this.dataRef.once('value').then(function(snapshot) {

        //     // console.log("----snapshot---");
        //     // console.log(snapshot.val());
        //     // console.log("----snapshot---");

        //     // Create parent data if does not exist
        //     const parentItems = {};
        //     parentItems['/Videos/'] = {};
        //     parentItems['/Posts/'] = {};
        //     const itemMessage = 'data parent items';
        //     this.updateAdd(this.dataRef, updates, itemMessage);

        //     // this.dataRef.child('tests').once('value').then(function(snapshotChild) {
        //     //     let val = snapshotChild.val();
        //     //     if (!val) {
        //     //         val = 0;
        //     //     }
        //     //     // console.log("snapshotChild " + val);
        //     //     const newKey = 'testChild' + (val + 1);
        //     //     const newVal = val + 1;
        //     //     // dataRef.set({'testChild': newVal}).then().catch();
        //     //     that.database.ref('test1/tests').set(newVal);

        //     //     that.writeUserData(newKey, newVal);
        //     //     that.writeNewPost('sampleUid' + newVal, 'testUser' + newVal, 'New Post Title')
        //     //     that.readData('/posts');
        //     //     that.orderByKey('/posts');
        //     //     that.orderByChild('/posts', 'author');
        //     // });

        //     // this.deleteData(dataRef.child('testChild'));
        // });

        // const dataRef = this.database.ref('test1');
        // dataRef.once('value').then(function(snapshot) {

        //     // console.log("----snapshot---");
        //     // console.log(snapshot.val());
        //     // console.log("----snapshot---");

        //     dataRef.child('tests').once('value').then(function(snapshotChild) {
        //         let val = snapshotChild.val();
        //         if (!val) {
        //             val = 0;
        //         }
        //         // console.log("snapshotChild " + val);
        //         const newKey = 'testChild' + (val + 1);
        //         const newVal = val + 1;
        //         // dataRef.set({'testChild': newVal}).then().catch();
        //         that.database.ref('test1/tests').set(newVal);

        //         that.writeUserData(newKey, newVal);
        //         that.writeNewPost('sampleUid' + newVal, 'testUser' + newVal, 'New Post Title')
        //         that.readData('/posts');
        //         that.orderByKey('/posts');
        //         that.orderByChild('/posts', 'author');
        //     });

        //     // this.deleteData(dataRef.child('testChild'));
        // });

        //Delete ALL
        // this.deleteData(this.dataRoot);
    }
}
  
export { Firebase };