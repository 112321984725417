class Feed {

  constructor (global) {
    this.name = 'Feed';
    this.global = global;
    this.resources = this.global.getResources();
    // this.posts = this.global.getPosts();
    this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);
  } 

  init () {
    console.log('Feed component is loaded');
    this.buildFeed();
  }

  eventHandler (eventName) {
    switch (eventName) {
      case this.global.references.Events.postAddedUpdated:
      case this.global.references.Events.postDeleted:
      case this.global.references.Events.postRequested:
      case this.global.references.Events.userStateChanged:
      case this.global.references.Events.clearOnLogout:
        // console.log('eventHandler Feed - post added/deleted - buildFeed');
        if (this.global.retrievedData.groups && this.global.retrievedData.users) {
          const postsWrapper = document.querySelector('.postsPageWrapper');
          this.updatePosts(this.global.retrievedData.groups, this.global.retrievedData.users, postsWrapper);
          this.global.utils.scrollToTop();
          // todo check permissions
          // TODO - scroll to last edited post
        }
        break;
      default:
        break;
    }
  }

  buildFeed () {
    const rootEl = document.querySelector('#contentContainer');

    // Dumbie Posts HTML
    const numberOfPlacholderPosts = 6;
    const dumbiePosts = [];
    for(let i = 0; i < numberOfPlacholderPosts; i++) {
      dumbiePosts.push(`
        <div class="postContainer">
          <div class="postHeader">
            <div class="postHeaderLogo"></div>
            <div class="postHeaderInfo">
              <div class="postHeaderInfoTitle"></div>
              <div class="postHeaderInfoDate"></div>
            </div>
          </div>
          <div class="postTextContainer"><div class="postText"></div></div>
        </div>
      `);
    }
    
    const html = `
      <main class="contentPosts">
        <div class="postsPageHeader"></div>
        <div class="postsPageWrapper">
          <div class="postsPagePosts dumbiePlaceholder">
            ${dumbiePosts.join('')}
          </div>
        </div>
      </main>
    `;
    rootEl.insertAdjacentHTML('beforeend', html);
    const postsWrapper = rootEl.querySelector('.postsPageWrapper');

    this.global.utils.getData(this.global.references.DataStructure.users, (users) => {
      this.global.retrievedData.users = users;
      if (!(this.global.retrievedData.groups && Object.keys(this.global.retrievedData.groups).length)) {
        this.global.utils.getData(this.global.references.DataStructure.groups, (groups) => {
          this.global.retrievedData.groups = groups;
          this.updatePosts(groups, users, postsWrapper);
        });
      }
      else {
        this.updatePosts(this.global.retrievedData.groups, users, postsWrapper);
      }
    });

  }

  getRequestedPost () {
    const url = new URL(document.location);
    const params = new URLSearchParams(url.search);
    console.log('getRequestedPost - ' + params);
    // TODO - when navigating away from post, url params should be deleted

    const postParam = params.get('utm_post');
    const langParam = params.get("utm_lang");
    const postId = postParam ? postParam : null;
    const postlang = langParam ? langParam : null;
    
    if (postlang || postId) { // ??????
      params.delete('utm_lang');
    }
    
    if (postId || postlang) {
      if (postId) {
        params.delete('utm_post');
      }

      if (postlang) {
        params.delete('utm_lang');
      }

      window.history.pushState({}, postId, `${url.origin}?${params}`);
      console.log('getRequestedPost.delete - ' + params);
    }

    return { postId, postlang };
  }

  updatePosts (groups, users, postsWrapper, filterType, isEditor) {
    const rootEl = document.querySelector('#contentContainer');

    // TODO - if post does not have image or video or text in current lang, do not show post ??
    this.global.utils.getData(this.global.references.DataStructure.posts, (posts) => {
      this.global.retrievedData.posts = posts;
      
      let post = '';
      let title = '';
      let id = '';
      let mediaImg = '';
      let postMediaImg = '';
      let postMediaVid = '';
      let postedBy = '';
      let postTitle = '';
      let postText = '';
      let postsHtml = '';
      let socialLinks = '';
      let link = '';
      const socialIcons = this.global.utils.getSocialIcons('color');
      let profileImage = '';
      let user = '';
      let group = '';
      let editPostIcon = '';
      let removeDeleted = '';
      const editorClass = !isEditor ? ' hidden' : '';
      filterType = !filterType ? this.global.references.Filter.posts.all : filterType;
      const imageUrl = (postId, imageName) => { return this.global.config.firebase.storage.url.postImage.get.call(this.global.config.firebase.storage.url, postId, imageName); };
  
      // TODO - remove params of requested post if navigating away from requested post
      let requestedPost = this.getRequestedPost();
      let requestedPostLang = requestedPost.postlang ? `&utm_lang=${requestedPost.postlang}` : '';
      let requestedPostId = requestedPost.postId;
      if (requestedPostId) {
        filterType = this.global.references.Filter.posts.requestedPost;
      }

      let arrPosts = this.global.utils.objToArray(posts);
      arrPosts = this.global.utils.arrFilter('postsFilter', arrPosts, filterType, requestedPostId);
      arrPosts = this.global.utils.arrSort('postsSort', arrPosts, this.global.references.Sort.newestFirst);

      let data = {};
      if (requestedPostId && arrPosts.length == 1) {
        console.log('Requested Post');
        // console.log(arrPosts[0]);
        const objPost = arrPosts[0];

        const regex_br = /<br\s*[\/]?>/gi;
        const regex_returns = /(\r\n|\r|\n)/g;
        const regex_emojis = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
        let postTitle = objPost.lang[this.uiLang].title.replace(regex_br, ' ').replace(regex_returns, ' ').replace(regex_emojis, '');
        let postDesc = objPost.lang[this.uiLang].text.replace(regex_br, ' ').replace(regex_returns, ' ').replace(regex_emojis, '');
        let postImg = objPost.media.image;
        let postVideo = objPost?.media?.video?.videoLink ?? '';
        // TODO - set uilang based on params so that user can choose language of post share
        // and crawlers will find see metatags in the intended lang
        
        let id = '';
        let group = '';
        let user = '';

        if (!postTitle) {
          if (objPost.postedByGroup) {
            id = objPost.postedByGroup;
            group = groups[id];
            postTitle = group && group.lang && group.lang[this.uiLang].title ? group.lang[this.uiLang].title : '';
          }
          else if (objPost.postedByInfluencer) {
            id = objPost.postedByInfluencer;
            user = users[id];
            postTitle = user && user.providerData && user.providerData.displayName ? user.providerData.displayName : '';
          }
        }

        if (!postImg) {
          if (objPost.postedByGroup) {
            id = objPost.postedByGroup;
            group = groups[id];
            const getLogoImage = (id, image) => { return this.global.config.firebase.storage.url.groupLogo.get.call(this.global.config.firebase.storage.url, id, image) };
            postImg = group && group.logo ? getLogoImage(id, group.logo) : "";
          }
          else if (objPost.postedByInfluencer) {
            id = objPost.postedByInfluencer;
            user = users[id];
            const getProfilePic = (id, image) => { return this.global.config.firebase.storage.url.profilePic.get.call(this.global.config.firebase.storage.url, id, image) };
            postImg = user && user.providerData && user.providerData.photoURL ? getProfilePic(id, user.providerData.photoURL) : "";
          }
        }
        else {
          postImg = imageUrl(objPost.postId, objPost.media.image);
        }

        // TODO - if post has video then add video tag
        // og:video - A URL to a video file that complements this object.

        data = {
          title: postTitle || ' ',
          desc: postDesc || ' ',
          img: postImg,
          imgW: '', // TODO get width
          imgH: '', // TODO get heigth
          url: `?utm_page=postsFeed&utm_post=${objPost.postId}${requestedPostLang}`,
          type: 'website',
          video: !!postVideo ? this.global.utils.getNonEmptyEmbed(objPost.media.video.embed) || '' : ''
        };

        if (postImg) {
          var img = new Image();
          img.onload = () => {
            console.log('post image width: ' + img.width + ' height: ' + img.height);
            data.imgW = img.width;
            data.imgH = img.height;
            buildPost.call(this); 
          }
          img.onerror = () => { buildPost.call(this); }
          img.src = postImg;
          console.log(postImg);
          
        }
        else {
          buildPost.call(this);
        }
        
      }
      else {
        buildPost.call(this);
        // TODO - use await instead of if's to buildPost
      }

      function buildPost () {
        //?utm_page=postsFeed&utm_post=-MSY3WezJd5R-D3yWnv1
        //?utm_page=postsFeed&utm_post=-MSY2wAswkqma1d7mHvh
        // https://ifcisrael.org/%3Fdom%3Dshady%26wc-inject-shadydom%3Dtrue
        // https://ifcisrael.org/?dom=shady&wc-inject-shadydom=true&utm_page=postsFeed&utm_post=-MSY3WezJd5R-D3yWnv1
        // https://ifcisrael.org/%3Fdom%3Dshady%26wc-inject-shadydom%3Dtrue%26utm_page%3DpostsFeed%26utm_post%3D-MSY3WezJd5R-D3yWnv1
        const metaTagsData = this.global.config.metaTags.get.call(this.global.config, { page: 'postsFeed', data: data });
        this.global.utils.setPageMetaTags(metaTagsData);

        // Recently Deleted
        let isDeleted = false;
        let deletedClass = '';
        const deletedPosts = this.global.retrievedData.recentlyDeleted.posts;
        // let deletedPostItem = null;
        for (let prop in deletedPosts) {
          deletedPosts[prop].isDeleted = true;
          arrPosts.unshift(deletedPosts[prop]);
        }

        let isLiked = false;
        let likeType = '';
        let isHiddenPost = false;
        let isHiddenPostClass = '';
        const currentUser = this.global.getUser();
        for (let i = 0; i < arrPosts.length; i++) {
          post = arrPosts[i];
          isDeleted = !!post.isDeleted;

          isLiked = false;
          likeType = '';
          if (currentUser && post.reactions && post.reactions.total && post.reactions.total.count && post.reactions.total.count > 0) {
            if (post.reactions.type) { // post.reactions.userIds
              likeType = Object.keys(post.reactions.type).find(key => { return post.reactions.type[key].userIds != null && post.reactions.type[key].userIds.hasOwnProperty(currentUser.userId) });
              isLiked = !!likeType;
            }
          }

          if (post.postedByGroup) {
            id = post.postedByGroup;
            group = groups[id];
            title = group && group.lang && group.lang[this.uiLang].title ? group.lang[this.uiLang].title : '';
            profileImage = this.global.config.firebase.storage.url.groupLogo.get.call(this.global.config.firebase.storage.url, id, group.logo);
          }
          else if (post.postedByInfluencer) {
            id = post.postedByInfluencer;
            user = users[id];
            title = user && user.providerData && user.providerData.displayName ? user.providerData.displayName : user.providerData.email;
            profileImage = 'images/icons/profileImage-default.png';
            if (user.providerData.photoURL) {
              profileImage = this.global.config.firebase.storage.url.profilePic.get.call(this.global.config.firebase.storage.url, id, user.providerData.photoURL);
            }
          }
          
          postMediaImg = '';
          if (post.media.image) {
            mediaImg = `<div class="postMedia"><img src="${imageUrl(post.postId, post.media.image)}" /></div>`;
            if (post.media.imageLink) {
              postMediaImg =`<a href="${post.media.imageLink}" target="_blank">${mediaImg}</a>`
            }
            else {
              postMediaImg = mediaImg;
            }
          }
    
          postMediaVid = '';
          if (post?.media?.video?.video ?? false) {
            postMediaVid = `<div class="postMedia"><video src="posts/${post.uploadDate}/${post.media.video.video}"></video></div>`
          }
          else if (post?.media?.video?.videoLink ?? false) {
            const embedCode = this.global.utils.getNonEmptyEmbed(post.media.video.embed);
            if (embedCode) {
                postMediaVid = `<div class="postMedia">${embedCode}</div>`;
            } else {
                console.log('No valid embed code available');
            }
          }
    
          postedBy = '';
          if (post.postedByUser && users[post.postedByUser] && users[post.postedByUser].providerData) {
            user = users[post.postedByUser];
            postedBy = `
              <div class="postedBy checkPermissions edit_editPosts${editorClass}">
                <span class="postedByLabel">${this.resources.posts.postedBy}</span>
                <span>${user.providerData.displayName ? user.providerData.displayName : user.providerData.email}</span>
              </div>
            `;
          }
    
          postTitle = post.lang[this.uiLang].title ? `<div class="postTitle">${post.lang[this.uiLang].title}</div>` : '';
          postText = post.lang[this.uiLang].text ? `
            <div class="postTextContainer">
              <div class="postText">
                <span class="hiddenTitle" style="background-image:url(${profileImage})">${title}</span>
                ${postTitle}
                <div>${post.lang[this.uiLang].text.replace(/(\r\n|\r|\n)/g, '<br>')}</div>
              </div>
            </div>` : (postTitle ? `
              <div class="postTextContainer">
                <div class="postText">
                  <span class="hiddenTitle" style="background-image:url(${profileImage})">${title}</span>
                  ${postTitle}
                </div>
              </div>
            ` : '');

          socialLinks = this.global.utils.getSocialLinks_large(post, this.resources.posts.viewOn);
          deletedClass = isDeleted ? ' recentlyDeleted' : '';
          editPostIcon = isDeleted ? '' : `<div class="editPostBtn checkPermissions edit_editPosts hidden" data-content-id="${post.postId}"></div>`;
          removeDeleted = isDeleted ? '<div class="removeFromRecentlyDeleted"></div>' : '';
          isHiddenPost = post.isHidden;
          isHiddenPostClass = isHiddenPost ? ' isHiddenPost hide' : '';
          postsHtml += `<div class="postContainer ${isHiddenPostClass}${deletedClass}" data-content-id="${post.postId}" data-posted-by-id="${id}" data-created-date="${post.uploadDate}">
                          <div class="postHeader">
                            ${removeDeleted}
                            ${editPostIcon}
                            <div class="postHeaderLogo" style="background-image: url(${profileImage})"></div>
                            <div class="postHeaderInfo">
                              <div class="postHeaderInfoTitle">${title}</div>
                              <div class="postHeaderInfoDate">${this.dateTimeOfPost(post.uploadDate)}</div>
                              ${postedBy}
                            </div>
                          </div>
                          ${postText}
                          ${postMediaImg}
                          ${postMediaVid}
                          <div class="postFooter">
                            ${socialLinks}
                            <div class="shareLink">
                              <div class="shareLinkLang" data-post-lang="${this.uiLang}">${this.resources.addEditPopup.lang[this.uiLang][this.uiLang]}</div>
                              <div class="shareLinkUrl">${this.global.host}?utm_page=postsFeed&utm_post=${post.postId}&utm_lang=${this.uiLang}</div>
                              <div class="shareLinkClose"><span>X</span></div>
                            </div>
                            <div class="postFooterBottom">
                              <div class="postLike">
                                <span class="postLikeBtn${isLiked ? ' liked' : ''}" data-like-type="${this.resources.likes.type.like}">
                                  <span class="postLikeOption" data-like-type="${this.resources.likes.type.like}">${isLiked ? this.resources.likes.liked : this.resources.likes.like}</span>
                                </span>
                                <span class="totalLikes"></span>
                              </div>
                              <div class="postShare"><span>${this.resources.posts.share}</span></div>
                            </div>
                          </div>
                        </div>`;
        }
    
        let filter = '';
        let selectedClass = '';
        let filterButtons = '';
        for (let prop in this.global.references.Filter.posts) {
          filter = this.global.references.Filter.posts[prop];
          if (filter != this.global.references.Filter.posts.requestedPost) {
            selectedClass = filter == filterType ? ' selected' : '';
            filterButtons += `
              <div class="postsPageFilter${selectedClass}" data-filter-type="${filter}">
                <span>${this.resources.posts.filter[filter]}</span>
              </div>
            `;
          }
        }
    
        postsWrapper.innerHTML = `
          <div class="postsPagePosts">
            <div class="postsPageFilterContainer">
              <div class="postsPageFilterButtons">${filterButtons}</div>
            </div>
            <div class="postsPageAddNewContainer checkPermissions edit_editPosts edit_addPosts${editorClass}">
              <div class="postsPageAddNewIcon"></div>
              <div class="postsPageAddNewTextbox">${this.resources.posts.newPostText}</div>
            </div>
            ${postsHtml}
          </div>
        `;
    
        const addNewPost = postsWrapper.querySelector('.postsPageAddNewContainer');
    
        const filterElements = postsWrapper.querySelectorAll('.postsPageFilter');
        this.global.utils.attachEventListeners('click', (element) => {
          const filterType = element.getAttribute('data-filter-type');
          const isEditor = !addNewPost.classList.contains('hidden');
          this.updatePosts(groups, users, postsWrapper, filterType, isEditor);
        }, filterElements);
    
        const popup = this.global.modules[this.global.references.ModuleNames.AddEditPopup];
        this.global.utils.attachEventListeners('click', popup.onAddEditPost(), [addNewPost]);
    
        this.global.utils.readMoreTruncate(postsWrapper.querySelectorAll('.postText'), null, 12);
        setTimeout(() => { this.global.relayEvent(this.global.references.Events.updateUserItems); }, 0);
    
        var facebookSDK = document.createElement('script');
        facebookSDK.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2";
        facebookSDK.defer = true;
        rootEl.appendChild(facebookSDK);

        // Bind post clicks
        const onPostClick = (el, event) => {
          const editIcon = el.querySelector('.editPostBtn');
          const shareIcon = el.querySelector('.postShare span');
          const postLikeBtn = el.querySelector('.postLikeBtn');
          const shareLinkLang = el.querySelector('.shareLinkLang');
          const shareLink = el.querySelector('.shareLink');
          const shareLinkUrl = el.querySelector('.shareLinkUrl');
          const shareLinkClose = el.querySelector('.shareLinkClose span');
          const postId = el.getAttribute('data-content-id');
          const createdDate = parseInt(el.getAttribute('data-created-date'), 10);
          const postData = posts[postId];
          let copyTimeout = null;

          const copyLink = () => {
            shareLinkUrl.classList.add('copied');
            clearTimeout(copyTimeout);
            copyTimeout = setTimeout(() => { shareLinkUrl.classList.remove('copied'); }, 900);

            const text = `${this.global.host}?utm_page=postsFeed&utm_post=${postId}&utm_lang=${shareLinkLang.getAttribute('data-post-lang')}`

            const elem = document.createElement('input');
            elem.style.height = '1px';
            elem.style.opacity = '0';
            elem.value = text;
            document.body.appendChild(elem);
            elem.select();
            document.execCommand('copy');
            document.body.removeChild(elem);
          };

          const changeShareLang = (currentLang) => {
            let postLang = 'eng';
            let langTxt = this.resources.addEditPopup.lang.eng.eng;
            if (currentLang === 'eng') {
              postLang = 'heb';
              langTxt = this.resources.addEditPopup.lang.heb.heb;
            }
            shareLinkLang.setAttribute('data-post-lang', postLang);
            
            shareLinkUrl.innerHTML = `${this.global.host}?utm_page=postsFeed&utm_post=${postId}&utm_lang=${postLang}`;
            shareLinkUrl.classList.remove('copied');
            shareLinkUrl.classList.add('changed');
            setTimeout(() => { shareLinkUrl.classList.remove('changed'); }, 250);

            console.log('clicked share English post ' + `${this.global.host}?utm_page=postsFeed&utm_post=${postId}&utm_lang=eng`);
            console.log('clicked share Hebrew post ' + `${this.global.host}?utm_page=postsFeed&utm_post=${postId}&utm_lang=heb`);

            return langTxt;
          };

          if (el.classList.contains('recentlyDeleted')) {
            const removeDeleted = el.querySelector('.removeFromRecentlyDeleted');
            const deletedData = this.global.retrievedData.recentlyDeleted.posts[postId];
            delete this.global.retrievedData.recentlyDeleted.posts[postId];
            if (event.target !== removeDeleted && !removeDeleted.contains(event.target)) {
              // undo delete
              deletedData.isDeleted = null;
              this.global.modules.Firebase.addEditPost(deletedData, null, null, true, true, postId, createdDate, true);
            }
            else {
              // remove from carousel and deleted List
              this.updatePosts(groups, users, postsWrapper, filterType);
            }
          }
          else if (editIcon.classList.contains('hidden') || event.target !== editIcon && !editIcon.contains(event.target)) {
            // clicked outside of edit icon

            // TODO - fix, does not work if clicked in youTube embedded iframe
            this.global.utils.userEventLog(postData, this.global.references.Events.userEventLog.postClick);

            if (event.target == postLikeBtn || postLikeBtn.contains(event.target)) {
              const type = postLikeBtn.getAttribute('data-like-type') || this.resources.likes.type.like;

              postData.reaction = {};
              postData.reaction.type = type;
              postData.elPostContainer = el;
              this.global.utils.userEventLog(postData, this.global.references.Events.userEventLog.postReact);
            }
            else if (event.target === shareIcon || shareIcon.contains(event.target)) {
              shareLink.classList.toggle('open');
              copyLink();
            }
            else if (event.target === shareLinkUrl || shareLinkUrl.contains(event.target)) {
              console.log('clicked share url');
              // TODO - copy to clipbourd
              copyLink();
            }
            else if (event.target === shareLinkClose || shareLinkClose.contains(event.target)) {
              console.log('clicked share link CLOSE btn')
              shareLink.classList.remove('open');
            }
            else if (event.target === shareLinkLang || shareLinkLang.contains(event.target)) {
              console.log('clicked share link Language');
              
              shareLinkLang.innerHTML = changeShareLang(shareLinkLang.getAttribute('data-post-lang'));
            }
          }
          else {
            // open edit popup
            const popup = this.global.modules[this.global.references.ModuleNames.AddEditPopup];
            popup.onAddEditPost(true, postId, postData, createdDate)();
          }
        };

        const getTotalLikes = (elemPost) => {
          // Watch post likes
          const postId = elemPost.getAttribute('data-content-id');
          const totalLikes = elemPost.querySelector('.totalLikes');
          // const postData = posts[postId];
          const path = `${this.global.references.DataStructure.posts}/${postId}/reactions/total/count`;

          const onValueChange = (newValue) => {
            const value = parseInt(newValue, 10);
            const text = (newValue && value > 0) ? `${newValue}` : ''; // ${this.resources.likes[value > 1 ? 'likes' : 'like']}
            totalLikes.innerHTML = text;
          };
          
          this.global.modules.Firebase.watchValue(path, onValueChange);
        };

        const watchCurrentUserLikes = (elemPost) => {
          if (currentUser && currentUser.userId) {
            const postId = elemPost.getAttribute('data-content-id');
            const path = `${this.global.references.DataStructure.posts}/${postId}/reactions/total/userIds/${currentUser.userId}`;
  
            const onValueChange = (newValue) => {
              const likeBtn = elemPost.querySelector('.postLikeBtn');
  
              if (!newValue) {
                likeBtn.classList.remove('liked');
                likeBtn.innerHTML = this.global.resources.likes.like;
              }
              else {
                likeBtn.classList.add('liked');
                likeBtn.innerHTML = this.global.resources.likes.liked;
              }
            };
            
            this.global.modules.Firebase.watchValue(path, onValueChange);
          }
        };

        const elemPosts = postsWrapper.querySelectorAll('.postContainer');
        elemPosts.forEach((el) => {
          this.global.utils.attachEventListeners('click', onPostClick, [el]);
          getTotalLikes(el);
          watchCurrentUserLikes(el);
        });
      }

    });
    
  }

  dateTimeOfPost (timestamp) {
    timestamp = parseInt(timestamp, 10); 
    let formatedDate = '';
    const currentDate = new Date().getTime();
    const date = new Date(timestamp);
    const datePosted = date.getTime();

    //Diff in hours
    const diff =(currentDate - datePosted) / 1000;
    const diffMinutes = Math.round(diff / (60));
    const diffHours = Math.round(diff / (60 * 60));
    const diffDays = Math.round(diff / (60 * 60 * 24));
    const diffMonths = Math.round(diff / (60 * 60 * 24 * 30));

    if (diffMinutes < 60) {
      if (diffMinutes < 2) { 
        formatedDate = this.resources.dateTime.now;
      }
      else {
        formatedDate = this.resources.dateTime.minutesAgo.replace('@@@', diffMinutes)
      }
    } else if (diffHours < 24) {
      if (diffHours == 1) {
        formatedDate = this.resources.dateTime.hourAgo.replace('@@@', diffHours)
      }
      else {
        formatedDate = this.resources.dateTime.hoursAgo.replace('@@@', diffHours)
      }
    } else if (diffDays < 60) {
      if (diffDays == 1) {
        formatedDate = this.resources.dateTime.dayAgo.replace('@@@', diffDays)
      }
      else {
        formatedDate = this.resources.dateTime.daysAgo.replace('@@@', diffDays)
      }
    } else {
      if (diffMonths == 1) {
        formatedDate = this.resources.dateTime.monthAgo.replace('@@@', diffMonths)
      }
      else {
        formatedDate = this.resources.dateTime.monthsAgo.replace('@@@', diffMonths)
      }
    }

    // const datevalues = {
    //   year: date.getFullYear(),
    //   month: date.getMonth() + 1,
    //   day: date.getDate(),
    //   hour: date.getHours(),
    //   minute: date.getMinutes(),
    //   second: date.getSeconds(),
    // };
    // const hours = (datevalues.hour < 10 ? "0" : "") + datevalues.hour;
    // const minutes = (datevalues.minute < 10 ? "0" : "") + datevalues.minute;
    // const time = hours + ':' + minutes;
    // formatedDate = time;

    return formatedDate;

  }
}

export { Feed }