
class GroupsWidget {

  constructor (global) {
    this.name = 'GroupsWidget';
    this.global = global;
    this.utils = global.utils;
    this.resources = global.resources;
    this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);
  }

  init () {
    console.log('GroupsWidget component is loaded');
  }

  eventHandler (eventName) {
    switch (eventName) {
      case this.global.references.Events.groupAddedUpdated:
      case this.global.references.Events.groupDeleted:
        // console.log('eventHandler Groups - group added/deleted - updateGroups');
        const divGroups_GroupsPage = document.querySelector('#divGroups_GroupsPage');
        if (divGroups_GroupsPage) {
          this.updateGroups(divGroups_GroupsPage);
        }
        break;
      default:
        break;
    }
  }

  // TODO - send web socket to other app users when group admins added/removed

  updateGroups (divGroups_GroupsPage) {
    const that = this;

    // Dumbie Groups HTML
    const numberOfPlacholderGroups = 6;
    const dumbieGroups = [];
    let groupsHtml = '';
    for(let i = 0; i < numberOfPlacholderGroups; i++) {
      dumbieGroups.push(`
      <div class="interactiveBox">
        <header></header>
        <content>
          <div class="groupItem">
            <div class="groupItemLogo"></div>
            <div class="groupItemInfo">
              <div class="groupLinkedIcons"></div>
              <div class="groupItemDesc"></div>
              <div class="groupItemInfoFooter"></div>
            </div>
          </div>
        </content>
        <footer></footer>
      </div>`);
    }
    groupsHtml = `
      <div class="leadingGroups">
        <div class="interactiveBoxesWrapper">
          <div class="interactiveBoxes dumbiePlaceholder">${dumbieGroups.join('')}</div>
        </div>
      </div>
    `;
    divGroups_GroupsPage.innerHTML = groupsHtml;

    this.global.utils.getData(this.global.references.DataStructure.groups, (groups) => {

      // TODO - do sorting here
      
      this.global.retrievedData.groups = groups;

      // HTML Groups
      // TODO - add youtube links
      const groupItems = [];
      let interactiveBoxes = '';
      let groupItem = '';
      let isHiddenGroup = false;
      let isHiddenGroupClass = '';
      let addedFirstLogo = false;
      const imageUrl = (groupId, imageName) => { return that.global.config.firebase.storage.url.groupLogo.get.call(that.global.config.firebase.storage.url, groupId, imageName); };

      groupItems.push(`
        <div id="addGroupBtn" class="interactiveBox hidden checkPermissions edit_editGroups">
          <div class="editGroupBtn hidden"></div>
          <img src="images/icons/plusIcon-white.png">
        </div>
      `);

      // Recently Deleted
      const deletedGroups = this.global.retrievedData.recentlyDeleted.groups;
      for (let prop in deletedGroups) {
        groupItem = deletedGroups[prop];
        groupItems.push(`
          <div class="interactiveBox recentlyDeleted" data-group-id="${prop}">
          <div class="editGroupBtn hidden"></div>
          <div class="removeFromRecentlyDeleted"></div>
            <header>${groupItem.lang[this.uiLang].title}</header>
            <content>
              <div class="groupItem">
                <div class="groupItemLogo">
                  <img src="${imageUrl(prop, groupItem.logo)}" />
                </div>
                <div class="groupItemInfo">
                  <div class="groupContentTitle">${groupItem.lang[this.uiLang].title}</div>
                </div>
              </div>
            </content>
          </div>
        `);
      }

      for (let prop in groups) {
        addedFirstLogo = false;
        groupItem = groups[prop];
        isHiddenGroup = groupItem.isHidden;
        isHiddenGroupClass = isHiddenGroup ? ' isHiddenGroup hide' : '';

        groupItems.push(`<div class="interactiveBox${isHiddenGroupClass}" data-group-id="${prop}" data-created-date="${groupItem.createdDate}">

          <div class="editGroupBtn hidden checkPermissions edit_editGroups" data-content-id="${prop}"></div>

          <header>${groupItem.lang[this.uiLang].title}</header>
          <content>`);

            const socialLinks = this.utils.getSocialLinks_small(groupItem, 'groupLinkedIcons');
            groupItems.push(socialLinks);

            groupItems.push(`<div class="groupItem">
              <div class="groupItemLogo">
                <img src="${imageUrl(prop, groupItem.logo)}" />
              </div>
              <div class="groupItemInfo">`);
                groupItems.push(`<div class="groupContentTitle">${groupItem.lang[this.uiLang].title}</div>`);
                groupItems.push(`<div class="groupItemDesc">`);
                if (groupItem.lang[this.uiLang].info) {
                  groupItems.push(`<span class="groupItemDescText">`);
                  groupItems.push(` <span class="hiddenTitle" style="background-image:url(${imageUrl(prop, groupItem.logo)})">${groupItem.lang[this.uiLang].title}</span>`);
                  groupItems.push(` <span>${groupItem.lang[this.uiLang].info}</span>`);
                  groupItems.push(`</span>`);
                }
                groupItems.push(`</div><div class="groupItemInfoFooter"></div></div>`);
              groupItems.push(`</div>`);
            groupItems.push(`</content>
          <footer></footer>
        </div>`);
      }

      interactiveBoxes = groupItems.join('');
      const groupsContainer = divGroups_GroupsPage.querySelector('.interactiveBoxesWrapper');
      groupsContainer.classList.add('interactiveBoxes');
      groupsContainer.innerHTML = interactiveBoxes;

      const addGroupBtn = document.querySelector('#addGroupBtn');
      const popup = this.global.modules[this.global.references.ModuleNames.AddEditPopup];
      this.utils.attachEventListeners('click', popup.onAddEditGroup(), [addGroupBtn]);

      // Bind edit group clicks
      const onEditGroupSelect = (el, event) => {
        const editIcon = el.querySelector('.editGroupBtn');
        const groupId = el.getAttribute('data-group-id');
        const createdDate = parseInt(el.getAttribute('data-created-date'), 10);
        const groupData = groups[groupId];

        if(el.classList.contains('recentlyDeleted')) {
          const removeDeleted = el.querySelector('.removeFromRecentlyDeleted');
          const deletedData = this.global.retrievedData.recentlyDeleted.groups[groupId];
          delete this.global.retrievedData.recentlyDeleted.groups[groupId];
          if (event.target !== removeDeleted && !removeDeleted.contains(event.target)) {
            // undo delete
            this.global.modules.Firebase.addEditGroup(deletedData, null, null, true, true, groupId, createdDate, true);
          }
          else {
            // remove from carousel and deleted List
            this.updateGroups(divGroups_GroupsPage);
          }
        }
        else if (editIcon.classList.contains('hidden') || event.target !== editIcon && !editIcon.contains(event.target)) {
          // console.log('clicking outside of edit icon');
        }
        else {
          // open edit popup
          const popup = this.global.modules[that.global.references.ModuleNames.AddEditPopup];
          popup.onAddEditGroup(true, groupId, groupData, createdDate)();
        }
      };

      const groupBox = groupsContainer.querySelectorAll('.interactiveBox');
      groupBox.forEach((el) => {
        that.utils.attachEventListeners('click', onEditGroupSelect, [el]);
      });

      // console.log('update groups html')
      that.utils.readMoreTruncate(divGroups_GroupsPage.querySelectorAll('.groupItemDesc'), '.groupItemDescText', 5);
      setTimeout(() => { that.global.relayEvent(that.global.references.Events.updateUserItems); }, 0);
      // Check if should do truncate above in event relay

      const profilePage = this.global.modules[this.global.references.ModuleNames.Profile];
      profilePage.updateAssociatedGroups();
    });
   
  }

}

export { GroupsWidget } 