const Resources = {
    lang: {
        eng: {
            pwa: {
                InstallTitle: 'Install on Home Screen'
            },
            langName: 'English',
            nav: {
                home: {
                    text: 'Home',
                    name: 'home'
                },
                groups: {
                    text: 'Advocates',
                    name: 'groups'
                },
                postsFeed: {
                    text: 'Feed',
                    name: 'postsFeed'
                },
                about: {
                    text: 'About Us',
                    name: 'about',
                    subMenu: {
                        about: {
                            text: 'The Plan',
                            name: 'about'
                        },
                        team: {
                            text: 'Our Team',
                            name: 'team'
                        }
                    }
                },
                calendar: {
                    text: 'Events Calendar',
                    name: 'calendar'
                },
                profile: {
                    text: 'Profile',
                    name: 'profile'
                }
            },
            login: {
                login: 'Login',
                logout: 'Logout',
                username: 'Email',
                userpassword: 'Password',
                register: 'Register',
                registerUser: 'Register',
                LoginExistingUser: 'Already Registered?',
                resetPassword: 'reset password',
                resetSuccess: 'An Email to reset your password was successfully sent. Please follow the link in your email.',
                errorTxt: {
                    emptyEmail: 'Please enter an email address.',
                    emptyPassword: 'Please enter a password.',
                    invalidEmail: 'Please Enter a valid email.',
                    signInWithEmailAndPassword: {
                        'auth/invalid-email': 'Please Enter a valid email.',
                        'auth/user-disabled': 'Your account is disabled, please send an email to IFC to restore your account.',
                        'auth/user-not-found': 'The email you entered does not correspond with an existing account.',
                        'auth/wrong-password': 'The password you entered is incorrect.',
                        'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
                        default: 'There was an error signing in. Please try again or contact IFC by email to check your account.'
                    },
                    createUserWithEmailAndPassword: {
                        'auth/email-already-in-use': 'The email you entered is already associated with another account. Try logging in.',
                        'auth/invalid-email': 'Please Enter a valid email.',
                        'auth/weak-password': 'Password should be at least 6 characters',
                        default: 'There was an error in creating your new account. Please try again or contact IFC by email for assistance.'
                    },
                    sendPasswordResetEmail: {
                        'auth/invalid-email': 'Please Enter a valid email.',
                        'auth/user-not-found': 'The email you entered does not correspond with an existing account.',
                        default: 'There was an error in reseting your password. Please try again or contact IFC by email for assistance.'
                    }
                }
            },
            donate: {
                buttonCampaign: 'Help Fund our next<br>Steet Meet-Up Event'
            },
            profile: {
                title: "Profile",
                notLoggedIn: 'please login',
                btnEdit: 'edit',
                enterDisplayName: 'enter a display name',
                errorTxt: {
                    httpsCallable: {
                        addAppRole: {
                            'functions/error-owner-only': 'Only an owner can perform this action',
                            'functions/error-role-create': 'There was an error in creating a new admin role. Please try again or contact an IFC admin for assistance.',
                            default: 'There was an error in creating a new admin role. Please try again or contact an IFC admin for assistance.'
                        },
                        getOtherUserByEmail: {
                            'functions/error/getOtherUserByEmail': 'There was an error in attempting to find another user',
                            'functions/error-owner-only': "Only onwers have permission get other user's data",
                            default: 'There was an error in attempting to find another user'
                        }
                    },
                    updateProfile: {
                        email: {
                            emptyPassword: 'Please enter a password.',
                            'auth/invalid-email': 'Please enter a valid email.',
                            'auth/wrong-password': 'The password you entered is incorrect. Try again or you may also reset your password through the login form.',
                            'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
                            'auth/email-already-in-use': 'The email you entered is already associated with another account.',
                            default: 'There was an error in changing your email. Please try again or contact IFC by email to assist with your account.'
                        }
                    },
                    enterValue: 'please enter a value',
                    profileImageSize: 'please choose a jpg/jpeg or png with square dimensions and Max size - 200kb'
                },
                info: {
                    user: {
                        username: 'Email',
                        email: 'Email',
                        displayName: 'Name',
                        currentRole: 'Role',
                        editRights: 'Edit Rights' ,
                        changeRoles: 'Update Editing Rights',
                        currentRoles: 'Current Editing Rights',
                        associatedGroups: 'Groups'
                    }
                },
                roles: {
                    addRole: 'Add Role',
                    role: 'Role',
                    type: 'Type',
                    owner: 'Owner',
                    admin: 'Admin',
                    editor: 'Editor',
                    viewer: 'Viewer'
                },
                roleAccess: {
                    editRights: {
                        editAccociatedConent: 'accociated content',
                        featuredVideos: 'featured videos',
                        editGroups: 'groups',
                        editTeam: 'team',
                        editPosts: 'posts',
                        assignGroupAdmins: 'assign group admins',
                        assignRoles: 'assign roles'
                    }
                },
                influencer: {
                    influencerProfile: 'Influencer Profile'
                },
                hasRight: {
                    true: 'active',
                    false: 'none'
                },
                actions: {
                    add: 'Add',
                    findUser: 'find user',
                    updateRights: 'Update Editing Rights'
                },
                delete: {
                    deleteAccount: 'Delete Account',
                    confirm: 'Are you sure you would like to delete your account and profile data?',
                    adminOfGroups: 'You will be removed as an Admin for following groups:',
                    isInfluencer: 'Your profile is set up as an Influencer. By deleting your account, you will loose all of your content.',
                    influencerAdmins: 'The following users will be removed as an Admin of your Influencer profile:',
                    adminOfInfluencers: 'You will be removed as an Admin of the following Influencer profiles:',
                    needAdmin: 'If you are the only Admin of a group, please go back and add an Admin before deleting your profile so that the group will not be deleted.',
                    finalConfirm: 'If you register with the same email (@email@) within 30 days, your account will be restored. Otherwise all your data and content will be permenantly deleted',
                    deleteError: 'There was an error in deleting your account, please try again later or contact IFC for assistance.',
                    accountDeleted: `Your account has been deleted, please feel free to continue enjoying the website's content as a non registered user. Thank you for your interest in listening, promoting and/or moving foward the ideals of Liberty and Free-Market.`,
                    thankYou: 'Thank you'
                },
                privacyPolicy: 'Privacy Policy'
            },
            notifications: {
                push: {
                    title: 'Notifications',
                    message: `We'll keep you updated...<br>but not too much`,
                    question: 'Would you like to get notifications?'
                }
            },
            popupConfirm: {
                confirmBtn: 'Do it',
                cancelBtn: 'Cancel',
                confirm: {
                    deleteAcount: 'Delete Account',
                    continue: 'Continue'
                }
            },
            popupSubmitForm: {
                submitBtn: 'Submit',
                cancelBtn: 'Cancel',
                password: 'please enter your password',
                title: {
                    updateEmail: 'Update Email'
                }
            },
            addEditPopup: {
                buttonTextAddVideo: 'Add Video',
                buttonTextEditVideo: 'Edit Video',
                buttonTextDeleteVideo: 'Delete Video',
                confirmDeleteVideo: 'Are you sure you would like to delete this video?',
                buttonTextAddGroup: 'Add Group',
                buttonTextEditGroup: 'Edit Group',
                buttonTextDeleteGroup: 'Delete Group',
                confirmDeleteGroup: 'Are you sure you would like to delete this group?',
                buttonTextAddPost: 'Add Post',
                buttonTextEditPost: 'Edit Post',
                buttonTextDeletePost: 'Delete Post',
                confirmDeletePost: 'Are you sure you would like to delete this post?',
                postingAs: 'posting as',
                header: {
                    addFeaturedVideo: 'Add Featured Video',
                    editFeaturedVideo: 'Edit Featured Video',
                    addGroup: 'Add Group',
                    editGroup: 'Edit Group Info',
                    addPost: 'Create Post',
                    editPost: 'Edit Post'
                },
                item: {
                    group: {
                        title_inputsSocialLinks: 'External links to the Group'
                    },
                    post: {
                        title_inputsSocialLinks: 'Link to Post on Social Media',
                    },
                    video: {
                        title_inputsSocialLinks: 'Link to Video on Social Media Posts',
                    },
                    category: 'Category Name',
                    titleMain: 'Main Title',
                    titleFooter: 'Footer Title',
                    videoLink_youtube: 'YouTube Video Link',
                    videoLink_fb: 'Facebook Video Link',
                    videoLink_insta: 'Instagram Video Link',
                    videoLink_tiktok: 'TikTok Video Link',
                    videoLink_twitter: 'X Video Link',
                    videoLink_spotify: 'Spotify Video Link',
                    coverImage: 'Cover Image',
                    fullName: 'Full Name',
                    acronym: 'Acronym',
                    description: 'Description',
                    groupLogo: 'Logo',
                    postImage: 'Upload Image',
                    postImageLink: 'Link Image To',
                    iconUploadImage: 'Photo',
                    iconUploadVideo: 'Video',
                    groupAddAdmin: 'Add Group Admins',
                    isHiddenGroup: 'Hide group from others',
                    isHiddenPost: 'Hide post from others'
                },
                lang: {
                    heb: {
                        heb: '<span class="hebrewInEnglishText">עברית</span>',
                        postTitle: 'כותרת'
                    },
                    eng: {
                        eng: 'English',
                        postTitle: 'Title'
                    }
                },
                prefix: {
                    youTubeEmbed: {
                        text: 'youtube.com/embed/'
                    },
                    facebookEmbed: {
                        text: 'facebook.com/embed/'
                    },
                    instaTubeEmbed: {
                        text: 'instagram.com/embed/'
                    },
                    tiktokEmbed: {
                        text: 'tiktok.com/@user/video/'
                    },
                    twitterEmbed: {
                        text: 'x.com/embed/'
                    },
                    spotifyEmbed: {
                        text: 'spotify.com/embed/'
                    }
                },
                hint: {
                    hint: 'hint: ',
                    category: 'name of lead performer or production organization',
                    postImageSize: 'jpg/jpeg or png <br> Max size - 200kb',
                    imageSize: 'jpg or png only (200px X 100px)',
                    logoImageSize: 'jpg/jpeg or png <br> square dimensions <br> Max size - 100kb',
                    videoTitle: 'title of video',
                    videoTitle2: 'name of show/series or episode name/number',
                    embededLink_youtube: 'add the ID from the "YouTube share link"',
                    embededLink_fb: 'add the ID from the "Facebook share link"',
                    embededLink_insta: 'add the ID from the "Instagram share link"',
                    embededLink_tiktok: 'add the ID from the "Tiktok share link"',
                    embededLink_twitter: 'add the ID from the "X share link"',
                    embededLink_spotify: 'add the ID from the "Spotify share link"',
                    socialLinks: {
                        group: {
                            fb: 'https://www.facebook.com/tacharutorg',
                            insta: 'https://www.instagram.com/travelingisrael',
                            tiktok: 'https://www.tiktok.com/@travelingisrael1',
                            twitter: 'https://x.com/IsrCitizenSpox',
                            linkedin: 'https://linkedIn.com/in/rotem-meron-20090841/',
                            spotify: 'https://open.spotify.com/episode/1Kl2hjrd72OChJK06aMXfd',
                            youtube: 'https://www.youtube.com/channel/UCkeTA80xeSa9POCGRpWTIfQ',
                            web: 'https://our-interes.com/',
                            linktree: 'https://linktr.ee/travelingisrael',
                            email: 'hazitallvolunteerforce@gmail.com',
                            phone: '+97225559650',
                            donate: 'https://www.paypal.com/donate?hosted_button_id=DVD82XD64KRRQ'
                        },
                        post: {
                            fb: 'https://www.facebook.com/watch/?v=306407127053427',
                            insta: 'https://www.instagram.com/travelingisrael/',
                            tiktok: 'https://www.tiktok.com/@eylonalevy/video/7384363971576368401',
                            twitter: 'https://x.com/IsrCitizenSpox',
                            linkedin: 'https://www.linkedin.com/posts/barakhers_%D7%9C%D7%9E%D7%94-%D7%99%D7%A7%D7%A8-%D7%A4%D7%94-%D7%94%D7%A0%D7%94-%D7%A2%D7%95%D7%93-%D7%A1%D7%99%D7%91%D7%94-%D7%9E%D7%A8%D7%AA%D7%99%D7%97%D7%94-%D7%9C%D7%99%D7%95%D7%A7%D7%A8-%D7%94%D7%9E%D7%97%D7%99%D7%94-activity-6688007987010801664-1euc/',
                            spotify: 'https://open.spotify.com/episode/1Kl2hjrd72OChJK06aMXfd',
                            youtube: 'https://youtu.be/RKgad4eesPs',
                            web: 'https://www.travelingisrael.com/'
                        },
                        video: {
                            fb: 'https://www.facebook.com/watch/?v=306407127053427',
                            insta: 'https://www.instagram.com/travelingisrael/',
                            tiktok: 'https://www.tiktok.com/@eylonalevy/video/7384363971576368401',
                            twitter: 'https://x.com/IsrCitizenSpox',
                            linkedin: 'https://www.linkedin.com/posts/barakhers_%D7%9C%D7%9E%D7%94-%D7%99%D7%A7%D7%A8-%D7%A4%D7%94-%D7%94%D7%A0%D7%94-%D7%A2%D7%95%D7%93-%D7%A1%D7%99%D7%91%D7%94-%D7%9E%D7%A8%D7%AA%D7%99%D7%97%D7%94-%D7%9C%D7%99%D7%95%D7%A7%D7%A8-%D7%94%D7%9E%D7%97%D7%99%D7%94-activity-6688007987010801664-1euc/',
                            spotify: 'https://open.spotify.com/episode/1Kl2hjrd72OChJK06aMXfd',
                            youtube: 'https://youtu.be/RKgad4eesPs',
                            web: 'https://www.travelingisrael.com/'
                        }
                    },
                    groupAcronym: 'SFL / JIMS / ARCI',
                    groupFullName: 'Students For Liberty | סטודנטים למען חירות',
                    groupDesc: 'Tell users more detail about your organization',
                    groupAdminEmail: 'friend@gmail.com',
                    postTitle: 'If added, this line will be bold and placed at the head of your post',
                    postImageLink: 'Add a link to a social media post'
                },
                errorTxt: {
                    missingTitle: 'please enter a value for all title fields',
                    needAdmin: 'please declare at least 1 group admin',
                    invalidEmail: 'please Enter a valid email',
                    emptyEmail: 'please enter an email address',
                    userNotFound: 'this email does not appear to be associated with a registered user'
                }
            },
            footer: {
                youtube: 'IFC Media - Channel for Liberty'
            },
            home: {
                promoBox: {
                    category: 'Event',
                    date: '<span style="font-size: 16px;">Comming Soon</span><br>Spring 2021',
                    title: 'How can we influence and promote a free market in Israel'
                }
            },
            activistTraining: {
                title: `IFC's Activist Training Program`,
                title2: `In Coordination with:`,
                lecturersTitle: `Among the Lecturers in the Course:`,
                peopleTitle: `Relate to Free Market Ideas?`,
                peopleTitle2: `Want to Lead in Policy Change?`,
                groupsTitle: `Getting Involved`,
                groupsTitle2: `In Strengthening Free Market Organizations`,
                lecturers: {
                    FredK: {
                        name: `Fred Kofman`,
                        title: `Google’s Vice President and Leadership Development Advisor`
                    },
                    OmerM: {
                        name: `Professor Omer Moav`,
                        title: `Professor of Economics at University of Warwick and IDC Herzliya - International School`
                    },
                    NehemiaS: {
                        name: `Nehemia Shtrasler`,
                        title: `Journalist and Economic Commentator at ‎Haaretz - Israel News`
                    },
                    IritK: {
                        name: `Irit Kobo`,
                        title: `Initiator and Manager of the IFC Activist Training Program`
                    },
                    BoazA: {
                        name: `Boaz Arad`,
                        title: `Co-founded the Israeli Freedom Movement and Founder/Managing Director of Ayn Rand Center Israel`
                    },
                    MonicaF: {
                        name: `Monica Forman`,
                        title: `Professional Director of the Liberty Debate Society`
                    },
                    AdiA: {
                        name: `Adi Arbel`,
                        title: `CEO of The Civil Society Forum`
                    },
                    RoyK: {
                        name: `Roy Katz`,
                        title: `Host of the "Free Market" show on 102FM Radio and on Knesset TV`
                    },
                    MosheB: {
                        name: `Moshe Basos`,
                        title: `Spokesman and Communications Consultant`
                    }
                }
            },
            youTube: {
                playlists: {
                    army: {
                        poster: 'images/content/YouTube-army.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVY8w78iBS6y-h4Vp9cwwZci'
                    },
                    meetups: {
                        poster: 'images/content/YouTube-Meetups.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYRZ4nptOlPHkgiIFEmAUgd'
                    },
                    news: {
                        poster: 'images/content/YouTube-News.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYqsr1nCbrqp0EPUMqRFBt9'
                    },
                    pannels: {
                        poster: 'images/content/YouTube-Panels.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVaoJYml-mLfG64xlYPzFJQY'
                    },
                    podcasts: {
                        poster: 'images/content/YouTube-Podcasts.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYEFtECQmj_Gj127ajyKlhd'
                    },
                    radio: {
                        poster: 'images/content/YouTube-Radio.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYIymmM1qCUskWOpyJw-TyI'
                    },
                    shows: {
                        poster: 'images/content/YouTube-Shows.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVaO6vPL8aEQy1FbExgilbS-'
                    }
                }
            },
            about: {
                ourTeam: {
                    title: 'Our Team',
                    team: {
                        Jimmy: {
                            name: 'Jimmy Levy',
                            title: 'Founder, Director, Webmaster, Group Activist',
                            pic: 'images/team/JimmyLevy.jpg',
                            about: 'Jimmy is the Founder and Director of IFC. As the founder of the association, Jimmy brought his personal vision and belief in the individual, a belief in the organizations that promote free market and work to protect individual liberties and he is dedicated to helping them in innovative ways.<br><br>Jimmy comes from the high-tech field and specializes in building websites and digital solutions as a front-end web developer.',
                            socialLinks: {
                                fb: 'https://www.facebook.com/Jimmy.Levy.Israel/',
                                website: '',
                                twitter: '',
                                linkedIn: 'https://www.linkedin.com/in/jimmy-levy-0256b842/',
                                email: 'jimmyjlevy@hotmail.com',
                                phone: '+972502598400'
                            }
                        },
                        Boaz: {
                            name: 'Boaz Arad-Erder',
                            title: 'Co-Founder',
                            pic: 'images/team/BoazArad.jpg',
                            about: `Boaz Arad is the founder of ARCI and serves as the managing director of the center. Boaz also co-founded the Israeli Freedom Movement. Boaz was a Research Fellow and a spokesman in the Public Policy Center at the Jerusalem Institute for Market Studies. Previously a member of the editorial board at Business Week (hebrew version) and was a writer of a weekly column in the Israel daily Makor Rishon. Boaz Manages www.anochi.com, a website dedicated to Ayn Rand's philosophy and writings. His numerous writings promoting economic freedom and individual liberty are regularly published in the Israeli press.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/anochi',
                                website: '',
                                twitter: '',
                                linkedIn: '',
                                email: 'boaz@aynrand.org.il',
                                phone: ''
                            }
                        },
                        Irit: {
                            name: 'Irit Kobo Cohen',
                            title: 'Community Organizer, Group Activist',
                            pic: 'images/team/IritKobo.jpg',
                            about: `Initiator and manager of the IFC Activist Training Program and part of IFC’s activist team in projects such of the Street Meet-Up event, Round Table project, and Fundraising.<br>Classical Liberal (recovered socialist).<br><br>BA in Community Organization and Management (Hebrew University of Jerusalem)<br>MA in Political Communication (Tel Aviv University)`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/irit.kobo',
                                website: '',
                                twitter: '',
                                linkedIn: '',
                                email: 'maavak2012@gmail.com',
                                phone: ''
                            }
                        },
                        Maxim: {
                            name: 'Maxim Voro',
                            title: 'Round Table Coordinator, Group Activist',
                            pic: 'images/team/MaximVoro.jpg',
                            about: `Manages the IFC Round Table project as a group coordinator.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/maxim.voro.58',
                                website: '',
                                twitter: '',
                                linkedIn: '',
                                email: 'maximvoro@gmail.com',
                                phone: '+972524516089'
                            }
                        },
                        Eliram: {
                            name: 'Eliram Maoz',
                            title: 'Round Table Coordinator, Group Activist',
                            pic: 'images/team/EliramMaoz.jpg',
                            about: `An active team member in IFC. Manages the IFC Round Table project together with Maxim Voro. Works as a graphic designer, video editor, creator & producer.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/eliram.maoz',
                                website: 'https://www.facebook.com/BlinkStudioEM/',
                                twitter: '',
                                linkedIn: '',
                                email: 'eliramaoz@gmail.com',
                                phone: '+972543455856'
                            }
                        },
                        Neta: {
                            name: 'Netta Zahavy',
                            title: 'Group Activist',
                            pic: 'images/team/NetaZahavy.jpg',
                            about: `Head of Lavi Student Movement in the Hebrew University of Jerusalem.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/Netta.ZY',
                                website: '',
                                twitter: '',
                                linkedIn: 'http://linkedin.com/in/netta-zahavy-300a7a183',
                                email: 'nettazahavy@gmail.com',
                                phone: '+972542013003'
                            }
                        },
                        Maytav: {
                            name: 'Maytav Einhorn',
                            title: 'Project Manager, Fundraising Team Member, Group Activist',
                            pic: 'images/team/MaytavEinhorn.jpg',
                            about: `Aerospace Engineer and MBA graduate. Maytav also works as a simulations developer in a High-Tech company.`,
                            socialLinks: {
                                fb: '',
                                website: '',
                                twitter: '',
                                linkedIn: 'https://www.linkedin.com/mwlite/in/maytav',
                                email: 'maytav1@gmail.com',
                                phone: '+972526555726'
                            }
                        },
                        // Rotem: {
                        //     name: 'Rotem Meron',
                        //     title: 'Project Supervision, Group Activist',
                        //     pic: 'images/team/RotemMaron.jpg',
                        //     about: `Rotem oversees details in the initiation of various IFC projects and supervises thier implementation. Rotem has a MS in Statistics, he is Merkaz Likud member and also works as a computer programmer.`,
                        //     socialLinks: {
                        //         fb: 'https://www.facebook.com/rotemmeron',
                        //         website: '',
                        //         twitter: '',
                        //         linkedIn: 'https://linkedIn.com/in/rotem-meron-20090841/',
                        //         email: 'rm13rotem@gmail.com',
                        //         phone: '+972528829604'
                        //     }
                        // },
                        Dorit: {
                            name: 'Dorit Itzhak',
                            title: 'Group Activist',
                            pic: 'images/team/Dorit-Itzhak.jpg',
                            about: `Dorit has a B.Sc in Biology from Tel Aviv University. She is the owner of a Chinese medicine clinic and also is a social and political activist.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/dorit.golpur/',
                                website: 'https://dorititzhak2.wixsite.com/dorititzhak',
                                twitter: 'https://twitter.com/doritz',
                                linkedIn: 'https://www.linkedin.com/in/dorit-itzhak-golpur-3128bb1/',
                                email: '',
                                phone: ''
                            }
                        },
                        Gilad: {
                            name: 'Gilad Herzfeld',
                            title: 'Group Activist',
                            pic: 'images/team/GiladHerzfeld.jpg',
                            about: `Software Engineer with a BSc in Computer Science.<br><br>“For more than 25 years I’ve lived without real exposure to Liberal and Capitalist points of view - Capitalism is not a dirty word and I want to inspire more people to understand it and talk about it positively”.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/GiladH11',
                                website: '',
                                twitter: '',
                                linkedIn: 'https://www.linkedin.com/in/gilad-herzfeld-4baa10b9/',
                                email: 'giladh11@gmail.com',
                                phone: '+972526267626'
                            }
                        }
                    }
                },
                organizationalPlan: {
                    title: 'Organizational Plan',
                    info: {
                        vision: {
                            title: 'Vision',
                            content: 'Allow Israel to reach its maximum potential by strengthening individual liberties, providing an environment where citizens can create, thrive and prosper by implementing Free Market Principles.'
                        },
                        mission: {
                            title: 'Agenda / Mission',
                            content: 'To promote Liberty and Free Market in Israel by educating the public to gain their support and growing Liberty and Free Market groups that already exists for the purpose of reducing burdening regulations and taxes, strengthening small businesses, reducing the power of unions, promoting free trade, protecting personal liberties, advocating for conservative judges and pushing for reforms in the nation’s basic law along with promoting the need for a more elaborate constitution.'
                        },
                        role: {
                            title: 'Organization Role',
                            content: 'Israeli Free-Market Coalition is an umbrella organization that will unite and strengthen groups in Israel working to promote Liberty and Free Market. The organization will work in both the political and non-political realms but will not be tied to any one political party, although will also not exclude any party or politician from participating as a group.'
                        },
                        approach: {
                            title: 'Approach',
                            content: 'To unite the groups and use a top down approach in advancing the agenda and at the same time use a bottom up approach to promote Liberty and Free Market to the average citizen.'
                        }
                    },
                    planUpdateLink: 'https://docs.google.com/document/d/e/2PACX-1vSHBhDUCCTL8rzch_RnlKX7YI-ZEOisMA0CCNyGQHB1PaC5W7QH2cvTbsC_czwsckaw56924tJdUSt8/pub',
                    planUpdateText: 'Organizational Plan Update',
                    planUpdateDate: 'April 19, 2020'
                }
            },
            groupsGeneral: {
                Title1: 'Influencers and Organizations',
                Title2: 'That Advocate for Israel and Against Anti-Semitism'
            },
            likes: {
                like: 'Like',
                liked: 'Liked',
                likes: 'likes',
                type: {
                    like: 'like',
                    happy: 'happy',
                    love: 'love',
                    wow: 'wow',
                    amazing: 'amazing',
                    ImIn: 'ImIn',
                    celebrate: 'celebrate',
                    agree: 'agree',
                    support: 'support',
                    suprised: 'suprised',
                    interesting: 'interesting',
                    controversial: 'controversial',
                    what: 'what?',
                    sad: 'sad',
                    notRelevent: 'not relevent',
                    dontAgree: "don't agree",
                    terrible: 'terrible',
                    angry: 'angry'
                }
            },
            posts: {
                viewOn: 'view post on:',
                share: 'Share',
                postedBy: 'posted by',
                originallyPostedBy: 'originally posted by',
                filter: {
                    all: 'All',
                    groups: 'Groups',
                    influencers: 'Influencers'
                },
                newPostText: `What's new?`,
                lang: {
                    eng: {
                        newPostText: `What's new?`,
                    },
                    heb: {
                        newPostText: 'מה חדש?'
                    }
                }
            },
            video: {
                viewOn: `Featured Videos`,
                viewVideoOn: 'view video on:'
            },
            dateTime: {
                now: `just now`,
                minutesAgo: `@@@ minutes ago`,
                hourAgo: `@@@ hour ago`,
                hoursAgo: `@@@ hours ago`,
                dayAgo: `@@@ day ago`,
                daysAgo: `@@@ days ago`,
                monthAgo: `@@@ month ago`,
                monthsAgo: `@@@ months ago`
            }
        },
        heb: {
            pwa: {
                InstallTitle: 'התקן על מסך הבית'
            },
            langName: 'עברית',
            nav: {
                home: {
                    text: 'ראשי',
                    name: 'home'
                },
                groups: {
                    text: 'ארגונים',
                    name: 'groups'
                },
                postsFeed: {
                    text: 'פוסטים',
                    name: 'postsFeed'
                },
                about: {
                    text: 'אודות',
                    name: 'about',
                    subMenu: {
                        about: {
                            text: 'התוכנית',
                            name: 'about'
                        },
                        team: {
                            text: 'הצוות שלנו',
                            name: 'team'
                        }
                    }
                },
                calendar: {
                    text: 'יומן ארועים',
                    name: 'calendar'
                },
                profile: {
                    text: 'פרופיל',
                    name: 'profile'
                }
            },
            login: {
                login: 'כניסה',
                logout: 'יציאה',
                username: 'דוא"ל',
                userpassword: 'סיסמה',
                register: 'להירשם',
                registerUser: 'הרשמה',
                LoginExistingUser: 'כניסה לרשומים',
                resetPassword: 'אפס סיסמה',
                resetSuccess: 'דוא"ל לאיפוס הסיסמה נשלח בהצלחה. אנא עקוב אחר הקישור בדוא"ל שלך.',
                errorTxt: {
                    emptyEmail: 'אנא הזן דוא"ל.',
                    emptyPassword: 'אנא הזן סיסמה.',
                    invalidEmail: 'אנא הזן דוא"ל חוקי.',
                    signInWithEmailAndPassword: {
                        'auth/invalid-email': 'אנא הזן דוא"ל חוקי.',
                        'auth/user-disabled': 'חשבונך מושבת, אנא שלח דוא"ל ל- IFC כדי לשחזר את חשבונך.',
                        'auth/user-not-found': 'הדוא"ל שהזנת אינו תואם לחשבון קיים.',
                        'auth/wrong-password': 'הסיסמה שהזנת שגויה.',
                        'auth/too-many-requests': 'הגישה לחשבון זה הושבתה באופן זמני עקב ניסיונות כניסה רבים שנכשלו. באפשרותך לשחזר אותה מיד על ידי איפוס הסיסמה שלך או שתוכל לנסות שוב מאוחר יותר.',
                        default: 'אירעה שגיאה בכניסה. נסה שוב או צור קשר עם IFC בדוא"ל כדי לבדוק את חשבונך.'
                    },
                    createUserWithEmailAndPassword: {
                        'auth/email-already-in-use': 'הדוא"ל שהזנת כבר משויך לחשבון אחר. נסה כניסה לרשומים.',
                        'auth/invalid-email': 'אנא הזן דוא"ל חוקי.',
                        'auth/weak-password': 'הסיסמה צריכה להיות לפחות 6 תווים.',
                        default: 'אירעה שגיאה ביצירת החשבון החדש שלך. אנא נסה שוב או צור קשר עם IFC בדוא"ל לקבלת סיוע.'
                    },
                    sendPasswordResetEmail: {
                        'auth/invalid-email': 'אנא הזן דוא"ל חוקי.',
                        'auth/user-not-found': 'הדוא"ל שהזנת אינו תואם לחשבון קיים.',
                        default: 'אירעה שגיאה באיפוס הסיסמה שלך. אנא נסה שוב או צור קשר עם IFC בדוא"ל לקבלת סיוע.'
                    }
                }
            },
            donate: {
                buttonCampaign: 'תרמו לעזור לנו לממן<br>את האירוע הבא'
            },
            profile: {
                title: 'פרופיל',
                notLoggedIn: 'אנא התחבר',
                btnEdit: 'ערוך',
                enterDisplayName: 'הזן שם תצוגה',
                errorTxt: {
                    httpsCallable: {
                        addAppRole: {
                            'functions/error-owner-only': 'רק מנהלים יכולים לבצע פעולה זו',
                            'functions/error-role-create': 'אירעה שגיאה ביצירת תפקיד מנהל חדש. אנא נסה שוב או צור קשר עם מנהל IFC לקבלת סיוע.',
                            default: 'אירעה שגיאה ביצירת תפקיד מנהל חדש. אנא נסה שוב או צור קשר עם מנהל IFC לקבלת סיוע.'
                        },
                        getOtherUserByEmail: {
                            'functions/error/getOtherUserByEmail': 'אירעה שגיאה בניסיון למצוא משתמש אחר',
                            'functions/error-owner-only': "רק למעניינים יש הרשאות לקבל נתונים של משתמשים אחרים",
                            default: 'אירעה שגיאה בניסיון למצוא משתמש אחר'
                        }
                    },
                    updateProfile: {
                        email: {
                            emptyPassword: 'אנא הזן סיסמה.',
                            'auth/invalid-email': 'אנא הזן דוא"ל חוקי.',
                            'auth/wrong-password': 'הסיסמה שהזנת שגויה. נסה שוב או תאפס את הסיסמה באמצעות טופס הכניסה.',
                            'auth/too-many-requests': 'הגישה לחשבון זה הושבתה באופן זמני עקב ניסיונות כניסה רבים שנכשלו. באפשרותך לשחזר אותה מיד על ידי איפוס הסיסמה שלך או שתוכל לנסות שוב מאוחר יותר.',
                            'auth/email-already-in-use': 'הדוא"ל שהזנת כבר משויך לחשבון אחר.',
                            default: 'אירעה שגיאה בשינוי הדוא"ל שלך. אנא נסה שוב או צור קשר עם IFC בדוא"ל כדי לסייע לך בחשבונך.'
                        }
                    },
                    enterValue: 'אנא הזן ערך',
                    profileImageSize: 'אנא בחר jpg / jpeg או png עם מידות מרובעות וגודל מקסימלי - 200kb'
                },
                info: {
                    user: {
                        username: 'דוא"ל',
                        email: 'דוא"ל',
                        displayName: 'שם',
                        currentRole: 'תפקיד',
                        editRights: 'זכויות עריכה',
                        changeRoles: 'עדכן זכויות עריכה',
                        currentRoles: 'זכויות עריכה נוכחיות',
                        associatedGroups: 'ארגונים'
                    }
                },
                roles: {
                    addRole: 'הוסף תפקיד',
                    role: 'תפקיד',
                    type: 'סוג',
                    owner: 'בעלים',
                    admin: 'מנהל',
                    editor: 'עורך',
                    viewer: 'צורך'
                },
                roleAccess: {
                    editRights: {
                        editAccociatedConent: 'תוכן משויך',
                        featuredVideos: 'סרטונים מומלצים',
                        editGroups: 'ארגונים',
                        editTeam: 'צוות',
                        editPosts: 'פוסטים',
                        assignGroupAdmins: 'הקצה מנהלי קבוצות',
                        assignRoles: 'הקצה תפקידים'
                    }
                },
                influencer: {
                    influencerProfile: 'פרופיל משפיען'
                },
                hasRight: {
                    true: 'פעיל',
                    false: 'ללא'
                },
                actions: {
                    add: 'הוסף',
                    findUser: 'חפש משתמש',
                    updateRights: 'עדכן זכויות עריכה'
                },
                delete: {
                    deleteAccount: 'מחק חשבונך',
                    confirm: 'האם שברצונך למחוק את החשבון ונתוני הפרופיל שלך?',
                    adminOfGroups: 'תוסר כמנהל עבור הקבוצות הבאות:',
                    isInfluencer: 'הפרופיל שלך מוגדר כפרופיל משפיען. על ידי מחיקת חשבונך, תאבד את כל התוכן שלך.',
                    influencerAdmins: 'The following users will be removed as an Admin of your Influencer profile:',
                    adminOfInfluencers: 'You will be removed as an Admin of the following Influencer profiles:',
                    needAdmin: 'אם אתה המנהל היחיד בקבוצה, חזור אחורה והוסף מנהל אחר כדי שתמחק את הפרופיל שלך, הקבוצה לא תימחק.',
                    finalConfirm: 'אם תירשם עם האותו דוא"ל (@email@) תוך 30 יום, חשבונך ישוחזר. אחרת כל הנתונים והתוכן שלך יימחקו לצמיתות.',
                    deleteError: 'היה אירעה שגיאה במחיקת חשבונך, נסה שוב במועד מאוחר יותר או צור קשר עם IFC לקבלת סיוע.',
                    accountDeleted: 'חשבונך נמחק, אנא אל תהסס להמשיך ליהנות מתוכן האתר כמשתמש שאינו רשום. תודה על התעניינותך בקידום העברת האידיאלים של חירות ושוק חופשי.',
                    thankYou: 'תודה'
                },
                privacyPolicy: 'מדיניות פרטיות'
            },
            notifications: {
                push: {
                    title: 'התראות',
                    message: `אנו נעדכן אותך...<br>אבל לא יותר מדי`,
                    question: 'האם ברצונך לקבל התראות?'
                }
            },
            popupConfirm: {
                confirmBtn: 'לבצע',
                cancelBtn: 'בטל',
                confirm: {
                    deleteAcount: 'מחק חשבון',
                    continue: 'המשיך'
                }
            },
            popupSubmitForm: {
                submitBtn: 'שלח',
                cancelBtn: 'בטל',
                password: 'אנא הזן את סיסמתך',
                title: {
                    updateEmail: 'עדכן דוא"ל'
                }
            },
            addEditPopup: {
                buttonTextAddVideo: 'הוסף סרטון',
                buttonTextEditVideo: 'ערוך סרטון',
                buttonTextDeleteVideo: 'מחק סרטון',
                confirmDeleteVideo: 'האם ברצונך למחוק את הסרטון?',
                buttonTextAddGroup: 'הוסף ארגון',
                buttonTextEditGroup: 'ערוך מידע של הארגון',
                buttonTextDeleteGroup: 'מחק ארגון',
                confirmDeleteGroup: 'האם ברצונך למחוק את הארגון?',
                buttonTextAddPost: 'הוסף פוסט',
                buttonTextEditPost: 'ערוך מידע של הפוסט',
                buttonTextDeletePost: 'מחק פוסט',
                confirmDeletePost: 'האם ברצונך למחוק את הפוסט?',
                postingAs: 'מפרסם כ',
                header: {
                    addFeaturedVideo: 'הוסף סרטון למומלצים',
                    editFeaturedVideo: 'ערוך סרטון מומלץ',
                    addGroup: 'הוסף ארגון',
                    editGroup: 'ערוך מידע של הארגון',
                    addPost: 'צור פוסט',
                    editPost: 'ערוך פוסט'
                },
                item: {
                    group: {
                        title_inputsSocialLinks: 'קישורים חיצוניים לארגון'
                    },
                    post: {
                        title_inputsSocialLinks: 'קישור לפוסט ברשתות החברתיות'
                    },
                    video: {
                        title_inputsSocialLinks: 'קישור לסרטון ברשתות החברתיות'
                    },
                    category: 'שם קטגוריה',
                    titleMain: 'כותרת ראשית',
                    titleFooter: 'כותרת תחתונה',
                    videoLink_youtube: 'קישור וידאו ליוטיוב',
                    videoLink_fb: 'קישור וידאו לפייסבוק',
                    videoLink_insta: 'קישור וידאו לאינסטגרם',
                    videoLink_tiktok: 'קישור וידאו לטיק טוק',
                    videoLink_twitter: 'קישור וידאו ל-X',
                    videoLink_spotify: 'קישור וידאו לספוטיפיי',
                    coverImage: 'תמונת שער',
                    fullName: 'שם מלא',
                    acronym: 'ראשי תיבות',
                    description: 'תיאור',
                    groupLogo: 'לוגו',
                    postImage: 'העלאת תמונה',
                    postImageLink: 'קישור לתמונה אל',
                    iconUploadImage: 'תמונה',
                    iconUploadVideo: 'סרטון',
                    groupAddAdmin: 'הוסף מנהלי קבוצה',
                    isHiddenGroup: 'הסתר ארגון מאחרים',
                    isHiddenPost: 'הסתר פוסט מאחרים'
                },
                lang: {
                    heb: {
                        heb: 'עברית',
                        postTitle: 'כותרת'
                    },
                    eng: {
                        eng: '<span class="englishInHebrewText">English</span>',
                        postTitle: 'Title'
                    }
                },
                prefix: {
                    youTubeEmbed: {
                        text: 'youtube.com/embed/'
                    },
                    facebookEmbed: {
                        text: 'facebook.com/embed/'
                    },
                    instaTubeEmbed: {
                        text: 'instagram.com/embed/'
                    },
                    tiktokEmbed: {
                        text: 'tiktok.com/@user/video/'
                    },
                    twitterEmbed: {
                        text: 'x.com/embed/'
                    },
                    spotifyEmbed: {
                        text: 'spotify.com/embed/'
                    }
                },
                hint: {
                    hint: 'רמז: ',
                    category: 'שם המבצע הראשי או ארגון ההפקה',
                    postImageSize: 'jpg / jpeg או png <br> גודל מקסימלי - 200kb',
                    imageSize: 'jpg או png בלבד (200px X 100px)',
                    logoImageSize: 'jpg / jpeg או png <br> מידות מרובעות <br> גודל מקסימלי - 100kb',
                    videoTitle: 'כותרת הסרטון',
                    videoTitle2: 'שם התוכנית/הסדרה או שם/מספר הפרק',
                    embededLink_youtube: 'הוסף את המזהה ID מקישור השיתוף של YouTube',
                    embededLink_fb: 'add the ID from the "Facebook share link"',
                    embededLink_insta: 'add the ID from the "Instagram share link"',
                    embededLink_tiktok: 'add the ID from the "Tiktok share link"',
                    embededLink_twitter: 'add the ID from the "X share link"',
                    embededLink_spotify: 'add the ID from the "Spotify share link"',
                    socialLinks: {
                        group: {
                            fb: 'https://www.facebook.com/tacharutorg',
                            insta: 'https://www.instagram.com/travelingisrael',
                            tiktok: 'https://www.tiktok.com/@travelingisrael1',
                            twitter: 'https://x.com/IsrCitizenSpox',
                            linkedin: 'https://linkedIn.com/in/rotem-meron-20090841/',
                            spotify: 'https://open.spotify.com/episode/1Kl2hjrd72OChJK06aMXfd',
                            youtube: 'https://www.youtube.com/channel/UCkeTA80xeSa9POCGRpWTIfQ',
                            web: 'https://our-interes.com/',
                            linktree: 'https://linktr.ee/travelingisrael',
                            email: 'hazitallvolunteerforce@gmail.com',
                            phone: '+97225559650',
                            donate: 'https://www.paypal.com/donate?hosted_button_id=DVD82XD64KRRQ'
                        },
                        post: {
                            fb: 'https://www.facebook.com/watch/?v=306407127053427',
                            insta: 'https://www.instagram.com/travelingisrael/',
                            tiktok: 'https://www.tiktok.com/@eylonalevy/video/7384363971576368401',
                            twitter: 'https://x.com/IsrCitizenSpox',
                            linkedin: 'https://www.linkedin.com/posts/barakhers_%D7%9C%D7%9E%D7%94-%D7%99%D7%A7%D7%A8-%D7%A4%D7%94-%D7%94%D7%A0%D7%94-%D7%A2%D7%95%D7%93-%D7%A1%D7%99%D7%91%D7%94-%D7%9E%D7%A8%D7%AA%D7%99%D7%97%D7%94-%D7%9C%D7%99%D7%95%D7%A7%D7%A8-%D7%94%D7%9E%D7%97%D7%99%D7%94-activity-6688007987010801664-1euc/',
                            spotify: 'https://open.spotify.com/episode/1Kl2hjrd72OChJK06aMXfd',
                            youtube: 'https://youtu.be/RKgad4eesPs',
                            web: 'https://www.travelingisrael.com/'
                        },
                        video: {
                            fb: 'https://www.facebook.com/watch/?v=306407127053427',
                            insta: 'https://www.instagram.com/travelingisrael/',
                            tiktok: 'https://www.tiktok.com/@eylonalevy/video/7384363971576368401',
                            twitter: 'https://x.com/IsrCitizenSpox',
                            linkedin: 'https://www.linkedin.com/posts/barakhers_%D7%9C%D7%9E%D7%94-%D7%99%D7%A7%D7%A8-%D7%A4%D7%94-%D7%94%D7%A0%D7%94-%D7%A2%D7%95%D7%93-%D7%A1%D7%99%D7%91%D7%94-%D7%9E%D7%A8%D7%AA%D7%99%D7%97%D7%94-%D7%9C%D7%99%D7%95%D7%A7%D7%A8-%D7%94%D7%9E%D7%97%D7%99%D7%94-activity-6688007987010801664-1euc/',
                            spotify: 'https://open.spotify.com/episode/1Kl2hjrd72OChJK06aMXfd',
                            youtube: 'https://youtu.be/RKgad4eesPs',
                            web: 'https://www.travelingisrael.com/'
                        }
                    },
                    groupAcronym: 'SFL / JIMS / ARCI',
                    groupFullName: 'Students For Liberty | סטודנטים למען חירות',
                    groupDesc: 'ספר למשתמשים פרטים נוספים על הארגון',
                    groupAdminEmail: 'friend@gmail.com',
                    postTitle: 'אם יתווסף, שורה זו תהיה מודגשת ותוצב בראש הפוסט',
                    postImageLink: 'הוסף קישור לפוסט ברשתות החברתיות'
                },
                errorTxt: {
                    missingTitle: 'אנא הזן ערך עבור כל השדות הכותרות.',
                    needAdmin: 'אנא הכריז על מנהל קבוצה אחד לפחות',
                    invalidEmail: 'אנא הזן דוא"ל חוקי.',
                    emptyEmail: 'אנא הזן דוא"ל.',
                    userNotFound: 'נראה כי דוא"ל זו אינה משויכת למשתמש רשום'
                }
            },
            footer: {
                youtube: 'IFC Media ערוץ לחירות'
            },
            home: {
                promoBox: {
                    category: 'ארוע',
                    date: '<span style="font-size: 16px;">בקרוב</span><br> אביב 2021',
                    title: 'איך אפשר להשפיע ולקדם שוק חופשי בארץ'
                }
            },
            activistTraining: {
                title: `החממה הליברלית לאקטיביסטים <span class="reduceText">של <span class="englishInHebrewText">IFC</span></span>`,
                title2: `בשיתוף פעולה עם:`,
                lecturersTitle: `בין המרצים בקורס:`,
                lecturers: {
                    FredK: {
                        name: `פרופ' פרד קופמן`,
                        title: 'סגן נשיא פיתוח בגוגל'
                    },
                    OmerM: {
                        name: `פרופ' עומר מואב`,
                        title: `מרצה לכלכלה בבינתחומי ובאונ' ווריק`
                    },
                    NehemiaS: {
                        name: `נחמיה שטרסלר`,
                        title: `הפרשן הכלכלי של עיתון הארץ`
                    },
                    IritK: {
                        name: `עירית קובו`,
                        title: `מנהלת החממה`
                    },
                    BoazA: {
                        name: `בועז ארד`,
                        title: `ממיסדי התנועה הליברלית ומנהל מרכז ראנד`
                    },
                    MonicaF: {
                        name: `מוניקה פורמן`,
                        title: `מנהלת מקצועית מועדון הדיבייט הליברלי`
                    },
                    AdiA: {
                        name: `אדי ארבל`,
                        title: `מנכ"ל הפורום לחברה אזרחית`
                    },
                    RoyK: {
                        name: `רועי כ"ץ`,
                        title: `מנחה התכנית "שוק חופשי" ברדיו וערוץ הכנסת`
                    },
                    MosheB: {
                        name: `משה בסוס`,
                        title: `דובר ויועץ תקשורת`
                    }
                }
            },
            youTube: {
                playlists: {
                    army: {
                        poster: 'images/content/YouTube-army.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVY8w78iBS6y-h4Vp9cwwZci'
                    },
                    meetups: {
                        poster: 'images/content/YouTube-Meetups.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYRZ4nptOlPHkgiIFEmAUgd'
                    },
                    news: {
                        poster: 'images/content/YouTube-News.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYqsr1nCbrqp0EPUMqRFBt9'
                    },
                    pannels: {
                        poster: 'images/content/YouTube-Panels.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVaoJYml-mLfG64xlYPzFJQY'
                    },
                    podcasts: {
                        poster: 'images/content/YouTube-Podcasts.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYEFtECQmj_Gj127ajyKlhd'
                    },
                    radio: {
                        poster: 'images/content/YouTube-Radio.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVYIymmM1qCUskWOpyJw-TyI'
                    },
                    shows: {
                        poster: 'images/content/YouTube-Shows.jpg',
                        link: 'https://www.youtube.com/playlist?list=PLU_vZ1L5WwVaO6vPL8aEQy1FbExgilbS-'
                    }
                }
            },
            about: {
                ourTeam: {
                    title: 'הצוות שלנו',
                    team: {
                        Jimmy: {
                            name: `ג'ימי לוי`,
                            title: 'מייסד, מנהל ארגון, וובמאסטר, פעיל <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/JimmyLevy.jpg',
                            about: `ג'ימי הוא יזם ומייסד עמותת <span class="englishInHebrewText">IFC</span> הקואליציה הישראלית למען שוק חופשי. כמייסד העמותה הביא ג'ימי את חזונו האישי ואמונתו בפרט, אמונה בארגונים שמקדמים שוק חופשי וחירות הפרט, כשחלק מהערכים שעומדים למולם, הגנה על זכויות האדם, חופש הפרט וחופש הביטוי. ג'ימי מגיע מתחום ההייטק ומתמחה בפיתוח אתרי אינטרנט ופתרונות דיגיטלים.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/Jimmy.Levy.Israel/',
                                website: '',
                                twitter: '',
                                linkedIn: 'https://www.linkedin.com/in/jimmy-levy-0256b842/',
                                email: 'jimmyjlevy@hotmail.com',
                                phone: '+972502598400'
                            }
                        },
                        Boaz: {
                            name: 'בועז ארד',
                            title: 'מייסד משותף',
                            pic: 'images/team/BoazArad.jpg',
                            about: 'מנהל מרכז איין ראנד ישראל, מייסד עמותת הידידים ועורך מגזין "אנכי". ממייסדי התנועה הליברלית החדשה.בעל ניסיון עשיר בכתיבה פובליציסטית וניירות עמדה סקים בנושאים מגוונים ומכוונים לקידום חירות הפרט וכלכלה חופשית בישראל.',
                            socialLinks: {
                                fb: 'https://www.facebook.com/anochi',
                                website: '',
                                twitter: '',
                                linkedIn: '',
                                email: 'boaz@aynrand.org.il ',
                                phone: ''
                            }
                        },
                        Irit: {
                            name: 'עירית קובו כהן',
                            title: 'פעילה <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/IritKobo.jpg',
                            about: `<span class="englishInHebrewText">MA</span> תקשורת פוליטית, <span class="englishInHebrewText">BA</span> ארגון ומנהל קהילתי. מנוסה בהובלת תנועות, ניהול והקמת פרויקטים ליברלית (סוציאליסטית מפוכחת)`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/irit.kobo',
                                website: '',
                                twitter: '',
                                linkedIn: '',
                                email: 'maavak2012@gmail.com',
                                phone: ''
                            }
                        },
                        Maxim: {
                            name: 'מקסים וורו',
                            title: 'מתאם שולחן העגול, פעיל <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/MaximVoro.jpg',
                            about: `מוביל את פרוייקט השולחן העגול ואחראי על תיאום בין הקבוצות.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/maxim.voro.58',
                                website: '',
                                twitter: '',
                                linkedIn: '',
                                email: 'maximvoro@gmail.com',
                                phone: '+972524516089'
                            }
                        },
                        Eliram: {
                            name: 'אלירם מעוז',
                            title: 'מתאם שולחן העגול. פעיל <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/EliramMaoz.jpg',
                            about: `גרפיקאי ועורך וידאו עצמאי בעל עסק. סטודנט לתואר בקולנוע. מנהל יחד עם מקסים וורו את פרוייקט השולחן העגול של IFC.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/eliram.maoz',
                                website: 'https://www.facebook.com/BlinkStudioEM/',
                                twitter: '',
                                linkedIn: '',
                                email: 'eliramaoz@gmail.com',
                                phone: '+972543455856'
                            }
                        },
                        Neta: {
                            name: 'נטע זהבי',
                            title: 'פעילה <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/NetaZahavy.jpg',
                            about: `בת 25, ליברלית ומאמינה באדם ובחופש. סטודנטית לתואר שני במדיניות ציבורית באוניברסיטה העברית ויושבת ראש תא הליכוד בקמפוס. גרה בירושלים ופעילה בזירה הפוליטית הארצית.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/Netta.ZY',
                                website: '',
                                twitter: '',
                                linkedIn: 'http://linkedin.com/in/netta-zahavy-300a7a183',
                                email: 'nettazahavy@gmail.com',
                                phone: '+972542013003'
                            }
                        },
                        Maytav: {
                            name: 'מייטב אינהורן',
                            title: 'ניהול פרוייקטים וגיוס כספים, פעילה <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/MaytavEinhorn.jpg',
                            about: `מהנדסת אווירונאוטיקה ובוגרת תואר שני <span class="englishInHebrewText">MBA</span>. מפתחת סימולציות בחברת הייטק`,
                            socialLinks: {
                                fb: '',
                                website: '',
                                twitter: '',
                                linkedIn: 'https://www.linkedin.com/mwlite/in/maytav',
                                email: 'maytav1@gmail.com',
                                phone: '+972526555726'
                            }
                        },
                        // Rotem: {
                        //     name: 'רותם מירון',
                        //     title: ' פיקוח ובקרה, פעיל <span class="englishInHebrewText">IFC</span>',
                        //     pic: 'images/team/RotemMaron.jpg',
                        //     about: `תואר שני בסטטיסטיקה, חבר מרכז הליכוד, מתכנת`,
                        //     socialLinks: {
                        //         fb: 'https://www.facebook.com/rotemmeron',
                        //         website: '',
                        //         twitter: '',
                        //         linkedIn: 'https://linkedIn.com/in/rotem-meron-20090841/',
                        //         email: 'rm13rotem@gmail.com',
                        //         phone: '+972528829604'
                        //     }
                        // },
                        Dorit: {
                            name: 'דורית יצחק',
                            title: 'פעילה <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/Dorit-Itzhak.jpg',
                            about: `בוגרת תואר ראשון במדעי החיים באונ' תל אביב, עצמאית, בעלת קליניקה לרפואה סינית ופעילה חברתית ופוליטית.`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/dorit.golpur/',
                                website: 'https://dorititzhak2.wixsite.com/dorititzhak',
                                twitter: 'https://twitter.com/doritz',
                                linkedIn: 'https://www.linkedin.com/in/dorit-itzhak-golpur-3128bb1/',
                                email: '',
                                phone: ''
                            }
                        },
                        Gilad: {
                            name: 'גלעד הרצפלד',
                            title: 'פעיל <span class="englishInHebrewText">IFC</span>',
                            pic: 'images/team/GiladHerzfeld.jpg',
                            about: `בוגר תואר ראשון במדעי המחשב ומהנדס תוכנה במקצועו.<br><br>“את רוב חיי העברתי מבלי להיחשף לרעיונות ליברלים וקפיטליסטים, רק לאחר גיל 25 התלבלרתי ע”י פוסטים בפייסבוק. אני רוצה לקחת חלק בהמשך הפצת הרעיונות - קפיטליזם היא לא מילה גסה ואני רוצה שאנשים ידברו יותר קפיטליזם.”`,
                            socialLinks: {
                                fb: 'https://www.facebook.com/GiladH11',
                                website: '',
                                twitter: '',
                                linkedIn: 'https://www.linkedin.com/in/gilad-herzfeld-4baa10b9/',
                                email: 'giladh11@gmail.com',
                                phone: '+972526267626'
                            }
                        }
                    }
                },
                organizationalPlan: {
                    title: 'תכנית ארגונית',
                    info: {
                        vision: {
                            title: 'חזון',
                            content: 'לאפשר לישראל להגשים את הפוטנציאל המקסימלי על ידי חיזוק חירויות הפרט, סיפוק סביבה בה אזרחים יוכלו ליצור, לשגשג ולהצליח על ידי יישום עקרונות של שוק חופשי.'
                        },
                        mission: {
                            title: 'מטרה / משימה',
                            content: 'קידום חירות ושוק-חופשי בישראל דרך הפצת הרעיונות והשגת התמיכה הציבורית בקבוצות החירות והשוק-החופשי הקיימות במטרה להפחתת רגולציות ומיסים, תמיכה בעסקים קטנים, הפחתת כוחם של הוועדים, קידום סחר חופשי, הגנת חירויות הפרט, תמיכה בשופטים שמרניים ודחיפה לרפורמות בחוקי האומה הבסיסיים יחד עם קידום הצורך בחוקה מורכבת יותר.'
                        },
                        role: {
                            title: 'תפקיד הארגון',
                            content: 'הקואליציה הישראלית למען שוק חופשי היא ארגון גג שיאחד ויחזק קבוצות שונות הפועלות בישראל במטרה לקידום חירות, ליברליזם ושוק-חופשי. הארגון יעבוד גם בתחום הפוליטי וגם בתחום הא פוליטי, אבל לא יקשור את עצמו לאף מפלגה פוליטית ספציפית, תוך שהוא לא ימנע מאף מפלגה או פוליטיקאי להצטרף כקבוצה.'
                        },
                        approach: {
                            title: 'גישה',
                            content: 'נאחד את הקבוצות השונות, תוך שימוש בגישה של מלמעלה למטה כדי לקדם את המטרה, כאשר במקביל נשתמש בגישה של מלמטה למעלה על מנת לקדם תפיסות חירות ושוק חופשי גם אצל האזרח הממוצע.'
                        }
                    },
                    planUpdateLink: 'https://docs.google.com/document/d/e/2PACX-1vSHBhDUCCTL8rzch_RnlKX7YI-ZEOisMA0CCNyGQHB1PaC5W7QH2cvTbsC_czwsckaw56924tJdUSt8/pub',
                    planUpdateText: 'עדכון תכנית ארגונית (באנגלית)',
                    planUpdateDate: '20 אפריל, 2020'
                }
            },
            groupsGeneral: {
                Title1: 'קבוצות ומשפיענים',
                Title2: 'הפועלים בעד ישראל ונגד אנטישמיות'
            },
            likes: {
                like: 'Like',
                liked: 'Liked',
                likes: 'likes',
                type: {
                    like: 'like',
                    happy: 'happy',
                    love: 'love',
                    wow: 'wow',
                    amazing: 'amazing',
                    ImIn: 'ImIn',
                    celebrate: 'celebrate',
                    agree: 'agree',
                    support: 'support',
                    suprised: 'suprised',
                    interesting: 'interesting',
                    controversial: 'controversial',
                    what: 'what?',
                    sad: 'sad',
                    notRelevent: 'not relevent',
                    dontAgree: "don't agree",
                    terrible: 'terrible',
                    angry: 'angry'
                }
            },
            posts: {
                viewOn: 'צפו בפוסט ב -',
                share: 'שתף',
                postedBy: 'פורסם על ידי',
                originallyPostedBy: 'במקור פורסם על ידי',
                filter: {
                    all: 'הכל',
                    groups: 'ארגונים',
                    influencers: 'משפיענים'
                },
                newPostText: 'מה חדש?',
                lang: {
                    eng: {
                        newPostText: `What's new?`,
                    },
                    heb: {
                        newPostText: 'מה חדש?'
                    }
                }
            },
            video: {
                viewOn: `צפו בסרטונים המומלצים`,
                viewVideoOn: 'צפו בסרטון ב -'
            },
            dateTime: {
                now: `עכשיו`,
                minutesAgo: `לפני <span class="englishInHebrewText">@@@</span> דקות`,
                hourAgo: `לפני  שעה <span class="englishInHebrewText">@@@</span>`,
                hoursAgo: `לפני <span class="englishInHebrewText">@@@</span> שעות`,
                dayAgo:  `לפני יום <span class="englishInHebrewText">@@@</span>`,
                daysAgo: `לפני <span class="englishInHebrewText">@@@</span> ימים`,
                monthAgo: `לפני חודש <span class="englishInHebrewText">@@@</span>`,
                monthsAgo: `לפני <span class="englishInHebrewText">@@@</span> חודשים`
            }
        }
    }
};

export { Resources };