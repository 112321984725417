
class Notifications {
    constructor (global) {
        this.name = 'Notifications';
        this.global = global;
    }

    init () {
        // TODO - init pushes on first interactin with posts
        // try to check that interaction is not link out
        // this.initPush();
    }

    initPush () {
        if (window.swRegistration != null && 'PushManager' in window) {
            const that = this;
            // TODO - first show nice UI popup explaining why they should allow Push Notifs
            // TODO - add turn on/off notifications btn in profile page
            // if turn off on profile page, then they should be turned off for all devices
            // need a way for anonymous users to turn off notifs

            // await navigator.serviceWorker.ready; // wait for sw to finish installing

            const attempted = sessionStorage.getItem('askedNotifsPermission');
            const permissionStatus = Notification.permission;

            if (permissionStatus !== 'granted' && (permissionStatus !== 'denied' || permissionStatus === 'default') && (!attempted || parseInt(attempted, 10) < 2)) {
                // wait to ask permission
                setTimeout(() => {
                    // askPermission();
                    this.openConfirmPushPopup();
                }, 5000);
            }
        }
    }

    urlBase64ToUint8Array (base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
          .replace(/\-/g, '+')
          .replace(/_/g, '/');
      
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
      
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    promiseAskPermission () {
        return new Promise((resolve, reject) => {
            const permissionResult = Notification.requestPermission(result => {
                resolve(result);
            });
    
            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        })
        .then(permissionResult => {
            // 'granted', 'default' or 'denied'
            if (permissionResult !== 'granted') {
                console.log(`Push - permision usr action result - ${permissionResult}`);
                throw new Error('We weren\'t granted permission by user for Push notifications.');
            }
        });
    }

    subscribeUserToPush () {
        const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(
                'BEaRpGCIY0XssOA3JGHrSN1QHzs02uy9BX7zR1Bk0XLhhik3vgFJvZp6-_krVVP7c7TzIIVAqpNZufkxTVFv2M8'
            )
        };

        return window.swRegistration.pushManager.subscribe(subscribeOptions)
            .then(pushSubscription => {
                console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
                return pushSubscription;
            });
    }

    askPermission (that) {
        const overlay = this.global.utils.showdarkenOverlay;
        overlay(true);
        this.promiseAskPermission()
        .then(() => {
            overlay(false);
            console.log('Push - user granted permission for push notifications');
            that.subscribeUserToPush().then((pushSubscription) => {
                window.pushSubscription = pushSubscription;
                console.log(`Push - subscribe successful`);
                that.global.modules.Firebase.savePushSubscriptionToDatabase(pushSubscription);
                // TODO - check if can save seperately for each user device
            });
        })
        .catch((error) => {
            overlay(false);
            console.log(`Push - user did Not grant permission for push notifications - ${error}`);
        })
    }

    openConfirmPushPopup () {
        const onConfirm = {
            func: () => { this.askPermission(this); },
            btnConfirm: '',
            btnCancel: ''
        }

        const onClose = () => {
            const attempted = sessionStorage.getItem('askedNotifsPermission');
            const count = attempted ? parseInt(attempted, 10) + 1 : 1;
            sessionStorage.setItem('askedNotifsPermission', count);
        };

        const ifcIcon = 'images/Theme/ifcLogo-144x144-Transparent.png';
        const contentHTML = `
            <div class='pushNotifConfirm'>
                <span class="hiddenTitle" style="background-image:url(${ifcIcon})">${this.global.resources.notifications.push.title}</span>
                <div class="pushNotifConfirmText">
                    <div class="pushNotifConfirmMessage">
                        <span>${this.global.resources.notifications.push.message}</span>
                        <span><img src="images/icons/likeEmoji-blue.png" width="20" height="20"></span>
                    </div>
                    <div class="pushNotifConfirmQuestion">${this.global.resources.notifications.push.question}</div>
                </div>
            </div>
        `;
        
        this.global.utils.openLightBox(contentHTML, onClose, onConfirm, this.global.resources, null, 'confirmPushPopup');
    }

    sendPushNotification () {
        // make POST request to each endpoint
        // The keys object contains the values used to encrypt message data sent with a push message
        // create an Authorization header which will contain information signed with your application server's private ke
    }

    // initPushNotifications () {
    //     this.initPush();
    //     return;

    //     const that = this;
    //     try {
    //       // TODO - wait for sw finish installing
    
    //       console.log('Push - initPushNotifications from init-firebase');
    //       if (window.swRegistration != null && 'PushManager' in window) {
    //         console.log('Push - window supports push notifications');
    
    //         const urlB64ToUint8Array = (base64String) => {
    //           const padding = '='.repeat((4 - base64String.length % 4) % 4);
    //           const base64 = (base64String + padding)
    //             .replace(/\-/g, '+')
    //             .replace(/_/g, '/');
            
    //           const rawData = window.atob(base64);
    //           const outputArray = new Uint8Array(rawData.length);
            
    //           for (let i = 0; i < rawData.length; ++i) {
    //             outputArray[i] = rawData.charCodeAt(i);
    //           }
    //           return outputArray;
    //         };
    
    //         let isSubscribed = false;
    //         const applicationServerPublicKey = 'BEaRpGCIY0XssOA3JGHrSN1QHzs02uy9BX7zR1Bk0XLhhik3vgFJvZp6-_krVVP7c7TzIIVAqpNZufkxTVFv2M8';
    
    //         const subscribeUser = async () => {
    
    //           await navigator.serviceWorker.ready; // wait for sw to finish installing
    
    //           const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
    //           window.swRegistration.pushManager.subscribe({
    //             userVisibleOnly: true,
    //             applicationServerKey: applicationServerKey
    //           })
    //           // window.swRegistration.pushManager.subscribe({userVisibleOnly: true})
    //           .then(function(pushSubscription) {
    //             console.log('Push - User is now subscribed.');
    //             console.log('push endpoint: ' + pushSubscription.endpoint);
    //             console.log('push keys: ' + pushSubscription.keys);
            
    //             // updateSubscriptionOnServer(pushSubscription);
            
    //             isSubscribed = true;

    //             // that.initPushMessaging();
            
    //             // updateBtn();
    //           })
    //           .catch(function(error) {
    //             console.error('Push - Failed to subscribe the user: ', error);
    //             // updateBtn();
    //           });
    //         };
    
    
    //         const checkSubcribed = () => {
    //           window.swRegistration.pushManager.getSubscription()
    //             .then(function(subscription) {
    //               isSubscribed = !(subscription === null);
    
    //               if (isSubscribed) {
    //                 console.log('Push - ush - User IS subscribed.');
    //                 console.log('push endpoint: ' + subscription.endpoint);
    //                 console.log('push keys: ' + subscription.keys);
    //               } else {
    //                 console.log('Push - ush - User is NOT subscribed.');
    //                 subscribeUser();
    //               }
    //               // updateBtn();
    //             }).catch(function (e) {
    //               console.log('Push - subscribe error ' + e);
    //             });
    //         };
    //         checkSubcribed();
    
    //         // registration.pushManager.subscribe({userVisibleOnly: true});
    //         // registration.pushManager.subscribe().then(
    //         //     function(pushSubscription) {
    //         //         console.log('push id: ' + pushSubscription.subscriptionId);
    //         //         console.log('push endpoint: ' + pushSubscription.endpoint);
    //         //     }, function(error) {
    //         //         console.log('push error: ' + error);
    //         //     }
    //         // );
    //       }
    //       else {
    //           console.log('Push - window.swRegistration ');
    //           console.log('window.swRegistration ' + window.swRegistration);
    //           console.log('PushManager in window ' + ('PushManager' in window));
    //       }
    //     } catch (e) {
    //       console.error('Push - pushManager error ' + e);
    //     }
    // }

    // initPushMessaging () {
    //     const messaging = firebase.messaging();
    //     // messaging.getToken({vapidKey: "BEaRpGCIY0XssOA3JGHrSN1QHzs02uy9BX7zR1Bk0XLhhik3vgFJvZp6-_krVVP7c7TzIIVAqpNZufkxTVFv2M8"});

    //     // Get registration token. Initially this makes a network call, once retrieved
    //     // subsequent calls to getToken will return from cache.
    //     messaging.getToken({ vapidKey: 'BEaRpGCIY0XssOA3JGHrSN1QHzs02uy9BX7zR1Bk0XLhhik3vgFJvZp6-_krVVP7c7TzIIVAqpNZufkxTVFv2M8' }).then((currentToken) => {
    //         if (currentToken) {
    //         // Send the token to your server and update the UI if necessary
    //         // ...
    //         } else {
    //         // Show permission request UI
    //         console.log('No registration token available. Request permission to generate one.');
    //         // ...
    //         }
    //     }).catch((err) => {
    //         console.log('An error occurred while retrieving token. ', err);
    //         // ...
    //     });
    // }
    
    
}
  
export { Notifications };