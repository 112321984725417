const References = {
    Events: {
        userStateChanged: 'userStateChanged',
        updateUserItems: 'updateUserItems',
        newVideoAdded: 'newVideoAdded',
        videoDeleted: 'videoDeleted',
        influencerAdded: 'influencerAdded',
        influencerDeleted: 'influencerDeleted',
        groupAddedUpdated: 'groupAddedUpdated',
        postAddedUpdated: 'postAddedUpdated',
        postRequested: 'postRequested',
        groupDeleted: 'groupDeleted',
        postDeleted: 'postDeleted',
        userDeleted: 'userDeleted',
        clearOnLogout: 'clearOnLogout',
        userEventLog: {
            postClick: 'postClick',
            postReact: 'postReact'
        }
    },
    Host: {
        prod: 'prod',
        test: 'test',
        dev: 'dev',
        prodUrl: 'https://tools.website-core.com/',
        testUrl: 'https://tools-test.website-core.com/',
        devUrl: 'http://localhost:8080/webpack-dev-server/'
    },
    DataStructure: {
        videos: '/Videos',
        groups: '/groups',
        posts: '/Posts',
        users: '/Users',
        influencers: '/Influencers',
        Deleted: {
            groups: '/Deleted/groups',
            post: '/Deleted/posts',
            videos: '/Deleted/videos',
            users: '/Deleted/users',
            influencers: '/Deleted/influencers'
        },
        groupAdmins: '/groups/@groupId@/groupAdmins/@userId@'
    },
    ModuleNames: {
        Groups: 'GroupsWidget',
        VideoCarousel: 'VideoCarousel',
        Player: 'Player',
        AddEditPopup: 'AddEditPopup',
        Profile: 'Profile'
    },
    Sort: {
        oldestFirst: 'oldestFirst',
        newestFirst: 'newestFirst'
    },
    Filter: {
      posts: {
          all: 'all',
          groups: 'groups',
          influencers: 'influencers',
          requestedPost: 'requestedPost'
      }  
    },
    Roles: {
        owner: 'owner',
        admin: 'admin',
        editor: 'editor',
        viewer: 'viewer'
    }
};

export { References };