const getEmbedCode = (dataObj, id, type) => {

    let videoLinkId = '';
    const videoLinks = {
        youtube: '',
        fb: '',
        insta: '',
        tiktok: '',
        twitter: '',
        spotify: ''
    }

    // gets the value (inputs) and change it to that .value of each input
    const embeds = Object.entries(dataObj.embedInputs).reduce((acc, [key, input]) => {
        acc[key] = input.value;
        return acc;
      }, {});

    const hasVideo = Object.values(dataObj.embedInputs).some(input => input.value !== '');
    if (hasVideo){
        const isAutoplay = type === 'video' ? '1' : '0';
        let paramSeperator;
        for (let type in dataObj.embedInputs) {
            if (dataObj.embedInputs[type].value) {
                if (type === 'youtube') {
                    videoLinkId = dataObj.embedInputs[type].value ? dataObj.embedInputs[type].value.replace('https://www.youtube.com/embed/', '').replace('https://youtu.be/', '').replace('https://www.youtube.com/watch?v=', '') : '';
                    
                    paramSeperator = videoLinkId.includes('?') ? '&' : '?';
                    embeds[type] = dataObj.embedInputs[type].value ? `<iframe id="video${id}" style="width:100%;max-height:600px;aspect-ratio:16/9;" src="https://www.youtube.com/embed/${videoLinkId}${paramSeperator}enablejsapi=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>` : '';
                    videoLinks[type] = videoLinkId;
                }
                else if (type === 'tiktok') {
                    const tiktokUrlPattern = /(?:https:\/\/www\.tiktok\.com\/@[A-Za-z0-9_.]+\/video\/([0-9]+))|(?:data-video-id="([0-9]+)")|([0-9]+)/;
                    const match = dataObj.embedInputs[type].value.match(tiktokUrlPattern);
                    videoLinkId = match ? match[1] || match[2] || match[3] : '';
    
                    // example
                    // https://www.tiktok.com/@comedybartv/video/7392856824329899271?is_from_webapp=1&sender_device=pc

                    paramSeperator = videoLinkId.includes('?') ? '&' : '?';
                    embeds[type] = dataObj.embedInputs[type].value ? `<iframe id="video${id}" style="width:100%;max-height:600px;aspect-ratio:16/9;" src="https://www.tiktok.com/player/v1/${videoLinkId}${paramSeperator}controls=1&progress_bar=1&play_button=1&volume_control=1&fullscreen_button=1&timestamp=1&loop=1&autoplay=${isAutoplay}&music_info=0&description=0&rel=0&native_context_menu=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>` : '';
                    videoLinks[type] = videoLinkId;
                }
            }
        }
    }

    

    return { videoLinkId, videoLinks, embeds };
};

const DataModel = {
    Video: (videoId, uploadDate, dataObj) => {
        const { videoLinkId, videoLinks, embeds } = getEmbedCode(dataObj, videoId, 'video');

        return {
            videoId: videoId,
            uploadDate: uploadDate,
            poster: dataObj.posterName,
            eng: {
                category: dataObj.categoryEng.value,
                title: dataObj.titleEng.value,
                title2: dataObj.titleFooterEng.value
            },
            heb: {
                category: dataObj.categoryHeb.value,
                title: dataObj.titleHeb.value,
                title2: dataObj.titleFooterHeb.value
            },
            media:{
                video: {
                    video: '',
                    videoLink: videoLinkId,
                    videoLinks: {
                        youtube: videoLinks.youtube,
                        fb: videoLinks.fb,
                        insta: videoLinks.insta,
                        tiktok: videoLinks.tiktok,
                        twitter: videoLinks.twitter,
                        spotify: videoLinks.spotify
                    },
                    embed: {
                        youtube: embeds.youtube,
                        fb: embeds.fb,
                        insta: embeds.insta,
                        tiktok: embeds.tiktok,
                        twitter: embeds.twitter,
                        spotify: embeds.spotify
                    }
                }
            },
            socialLinks: {
                fb: dataObj.inputSocialLinks_fb.value,
                insta: dataObj.inputSocialLinks_insta.value,
                tiktok: dataObj.inputSocialLinks_tiktok.value,
                twitter: dataObj.inputSocialLinks_twitter.value,
                linkedIn: dataObj.inputSocialLinks_linkedin.value,
                youTube: dataObj.inputSocialLinks_youtube.value,
                spotify: dataObj.inputSocialLinks_spotify.value,
                website: dataObj.inputSocialLinks_web.value
            }
        };
    },
    Group: (groupId, createdDate, dataObj) => {
        return {
            groupId: groupId,
            isHidden: dataObj.checkbox_isHidden.checked,
            createdDate: createdDate,
            groupAdmins: dataObj.groupAdmins,
            associatedPosts: {},
            associatedPrivatePojects: {},
            acronym: dataObj.inputAcronym.value,
            logo: dataObj.logoName,
            icon: '',
            banner: '',
            lang:{
                heb:{
                    title: dataObj.inputGroupNameHeb.value,
                    info: dataObj.inputGroupDescHeb.value
                },
                eng:{
                    title: dataObj.inputGroupNameEng.value,
                    info: dataObj.inputGroupDescEng.value
                }
            },
            socialLinks:{
                fb: dataObj.inputSocialLinks_fb.value,
                insta: dataObj.inputSocialLinks_insta.value,
                tiktok: dataObj.inputSocialLinks_tiktok.value,
                twitter: dataObj.inputSocialLinks_twitter.value,
                linkedIn: dataObj.inputSocialLinks_linkedin.value,
                spotify: dataObj.inputSocialLinks_spotify.value,
                youtube: dataObj.inputSocialLinks_youtube.value,
                website: dataObj.inputSocialLinks_web.value,
                linktree: dataObj.inputSocialLinks_linktree.value,
                donate: dataObj.inputSocialLinks_donate.value,
                email: dataObj.inputSocialLinks_email.value,
                phone: dataObj.inputSocialLinks_phone.value
            }
        };
    },
    Post: (postId, createdDate, dataObj) => {
        const { videoLinkId, videoLinks, embeds } = getEmbedCode(dataObj, postId, 'post');

        return {
            postId: postId,
            postedByGroup: dataObj.objPostedBy.postedByGroup,
            postedByInfluencer: dataObj.objPostedBy.postedByInfluencer,
            postedByUser: dataObj.objPostedBy.postedByUser,
            isHidden: dataObj.checkbox_isHidden.checked,
            media:{
                image: dataObj.photoName,
                imageLink: dataObj.inputImageLink.value,
                video: {
                    video: '',
                    videoLink: videoLinkId,
                    videoLinks: {
                        youtube: videoLinks.youtube,
                        fb: videoLinks.fb,
                        insta: videoLinks.insta,
                        tiktok: videoLinks.tiktok,
                        twitter: videoLinks.twitter,
                        spotify: videoLinks.spotify
                    },
                    embed: {
                        youtube: embeds.youtube,
                        fb: embeds.fb,
                        insta: embeds.insta,
                        tiktok: embeds.tiktok,
                        twitter: embeds.twitter,
                        spotify: embeds.spotify
                    }
                }
            },
            lang: {
                eng: {
                    title: dataObj.inputPostTitleEng.value,
                    text: dataObj.inputPostTextEng.value
                },
                heb: {
                    title: dataObj.inputPostTitleHeb.value,
                    text: dataObj.inputPostTextHeb.value
                },
            },
            uploadDate: parseInt(createdDate, 10),
            socialLinks: {
                fb: dataObj.inputSocialLinks_fb.value,
                insta: dataObj.inputSocialLinks_insta.value,
                tiktok: dataObj.inputSocialLinks_tiktok.value,
                twitter: dataObj.inputSocialLinks_twitter.value,
                linkedIn: dataObj.inputSocialLinks_linkedin.value,
                spotify: dataObj.inputSocialLinks_spotify.value,
                youTube: dataObj.inputSocialLinks_youtube.value,
                website: dataObj.inputSocialLinks_web.value
            },
            engagements: 0,
            reactions: {
                total: { count: 0 },
                type: {
                    like: { userIds: {}, count: 0 },
                    happy: { userIds: {}, count: 0 },
                    love: { userIds: {}, count: 0 },
                    wow: { userIds: {}, count: 0 },
                    amazing: { userIds: {}, count: 0 },
                    ImIn: { userIds: {}, count: 0 },
                    celebrate: { userIds: {}, count: 0 },
                    agree: { userIds: {}, count: 0 },
                    support: { userIds: {}, count: 0 },
                    interesting: { userIds: {}, count: 0 },
                    controversial: { userIds: {}, count: 0 },
                    what: { userIds: {}, count: 0 },
                    sad: { userIds: {}, count: 0 },
                    notRelevent: { userIds: {}, count: 0 },
                    dontAgree: { userIds: {}, count: 0 },
                    terrible: { userIds: {}, count: 0 },
                    angry: { userIds: {}, count: 0 }
                }
            }
        }
    },
    Influencer: (userId, dataObj) => {
        return {
            userId: userId,
            influencerAdmins: dataObj.groupinfluencerAdmins,
            associatedPosts: {}
        };
    },
    RetrievedData: () => {
        return {
            videos: {
                featuredVideos: null
            },
            recentlyDeleted: {
                videos: {},
                groups: {},
                posts: {},
                users: {}
            },
            groups: null,
            users: null
        };
    },
    UserData: (userId, firebaseUserData) => {
        return {
            userId: userId,
            // ifcTeamMeber: false,
            // isInfluencer: false,
            // associatedGroups: {},
            // associatedInfluencers: {},
            // associatedPosts: {},
            // associatedPrivatePojects: {},
            providerData: firebaseUserData.providerData,
            accessLevel: firebaseUserData.claims
        };
    },
    DefualtUserRights: () => {
        return {
            owner: {
                editAccociatedConent: false,
                featuredVideos: false,
                editGroups: false,
                editTeam: false,
                editPosts: false,
                assignGroupAdmins: false,
                assignRoles: false
            },
            admin: {
                editAccociatedConent: false,
                featuredVideos: false,
                editGroups: false,
                editTeam: false,
                editPosts: false,
                assignGroupAdmins: false
            },
            editor: {
                editAccociatedConent: false,
                featuredVideos: false,
                editGroups: false,
                editTeam: false,
                editPosts: false
            },
            viewer: {
                editAccociatedConent: false
            }
        };
    }
}

export { DataModel }