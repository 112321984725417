
class Login {

  constructor (global) {
    this.name = 'Login';
    this.global = global;
    this.utils = global.utils;
    this.resources = this.global.getResources();
    this.data = null;
  }

  init () {
    const that = this;
    this.buildHtml();

    // this.global.setUser(firebase.auth().currentUser);
    this.data = this.global.modules.Firebase.firebase;

    this.bindEvents();
    console.log('Login component is loaded');
  }

  buildHtml () {
    const rootEl = document.querySelector('#appMain');
    const loggedIn = this.global.getUser() ? 'logout' : 'login';
    const html = `
      <div id="loginMain">
        <div id="loginContainer">
          <div id="logInOutBtn" class="hide"><span class="logInOutStatus">${this.resources.login[loggedIn]}</span><span class="loggedInUser">${this.resources.profile.title}</span></div>
          <div id="loginForm" class="hide">
            <form>
              <div id="loginForm_User">
                <label class="loginFormLabel" for="uname">${this.resources.login.username}</label>
                <input id="inputLoginEmail" class="loginFormInput" type="email" placholder="${this.resources.login.username}" name="uname" required>
              </div>
              <div id="loginForm_Password">
                <label class="loginFormLabel" for="upass">${this.resources.login.userpassword}</label>
                <input id="inputLoginPassword" class="loginFormInput" type="password" placholder="${this.resources.login.userpassword}" name="upass" required>
              </div>
              <div id="loginForm_ErrorTxt"></div>
              <div id="loginForm_LoginExistingUser">
                <div id="loginForm_loginBtn"><span id="resetPassBtn">${this.resources.login.resetPassword}</span><button type="submit">${this.resources.login.login}</button></div>
                <div id="loginForm_gotoRegister">${this.resources.login.register}</div>
              </div>
              <div id="loginForm_RegisterNewUser" class="hide">
                <div id="loginForm_registerBtn"><button type="submit">${this.resources.login.registerUser}</button></div>
                <div id="loginForm_gotoLogin">${this.resources.login.LoginExistingUser}</div>
              </div>
              <div id="loginForm_ResetPassword" class="hide">
                <div id="loginForm_resetPassBtn"><button type="submit">${this.resources.login.resetPassword}</button></div>
                <div id="loginForm_resetSuccessTxt" class="hide">${this.resources.login.resetSuccess}</div>
                <div id="loginForm_gotoLoginAfterReset">${this.resources.login.LoginExistingUser}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    `;
    rootEl.insertAdjacentHTML('beforeend', html);
    // this.updateUserLoginOutBtn();
  }

  htmlInitialized () {
    const loginMain = document.querySelector('#loginMain');
    return !!loginMain;
  }

  refreshLoginForm (loginForm, errorTxt) {
    errorTxt.innerHTML = '';
    loginForm.classList.add('hide');
    setTimeout (() => {
      loginForm.classList.remove('hide');
    }, 150);
  }

  bindEvents () {
    const that = this;
    const loginForm = document.querySelector('#loginForm');
    const errorTxt = document.querySelector('#loginForm_ErrorTxt');

    const loginBtn = document.querySelector('#loginForm_loginBtn').querySelector('button');
    const registerBtn = document.querySelector('#loginForm_registerBtn').querySelector('button');
    const resetPassBtn = document.querySelector('#loginForm_resetPassBtn').querySelector('button');
    
    // Log in/out Btn
    const logInOutBtn = document.querySelector('#logInOutBtn');
    const logInOutStatus = logInOutBtn.querySelector('.logInOutStatus');
    logInOutStatus.addEventListener('click', () => {

      if (that.global.getUser()) {
        // perform sign out
        that.data.auth().signOut().then(function() {
          console.log('success logged OUT');
          that.global.relayEvent(that.global.references.Events.clearOnLogout);
        }).catch(function(error) {
          console.log('ERROR logged OUT ' + error);
        });
      } else {
        // open/close sign in form for login
        if (loginForm.classList.contains('hide')) {
          loginForm.classList.remove('hide');
        }
        else {
          that.utils.closeLoginForm();
        }
      }
    });

    const loggedInUser = logInOutBtn.querySelector('.loggedInUser');
    loggedInUser.addEventListener('click', () => {
      // TODO - setPage should worry about selected instead of in multiple places
      document.querySelector('#headerNav').querySelectorAll('li').forEach( function (el) {
        el.classList.remove('selected');
        el.classList.remove('showSubNav');
      });
      this.utils.setPage('profile');
    });

    // TODO - add "email IFC" link (facebook email)
    // TODO - Check not hebrew letters

    // Switch Login/Register/Reset
    const gotoRegister = document.querySelector('#loginForm_gotoRegister');
    const registerNewUser = document.querySelector('#loginForm_RegisterNewUser');
    const gotoLogin = document.querySelector('#loginForm_gotoLogin');
    const loginExistingUser = document.querySelector('#loginForm_LoginExistingUser');
    const gotoReset = document.querySelector('#resetPassBtn');
    const resetPassword = document.querySelector('#loginForm_ResetPassword');
    const gotoLoginAfterReset = document.querySelector('#loginForm_gotoLoginAfterReset');
    const loginForm_Password = document.querySelector('#loginForm_Password');
    const resetSuccessTxt = document.querySelector('#loginForm_resetSuccessTxt');
    gotoRegister.addEventListener('click', () => {
      loginBtn.disabled = true;
      registerBtn.disabled = false;
      resetPassBtn.disabled = true;
      loginExistingUser.classList.add('hide');
      registerNewUser.classList.remove('hide');
      loginForm_Password.classList.remove('hide');
      that.refreshLoginForm(loginForm, errorTxt);
    });
    gotoLogin.addEventListener('click', () => {
      loginBtn.disabled = false;
      registerBtn.disabled = true;
      resetPassBtn.disabled = true;
      registerNewUser.classList.add('hide');
      loginExistingUser.classList.remove('hide');
      loginForm_Password.classList.remove('hide');
      that.refreshLoginForm(loginForm, errorTxt);
    });
    gotoReset.addEventListener('click', () => {
      loginBtn.disabled = true;
      registerBtn.disabled = true;
      resetPassBtn.disabled = false;
      loginExistingUser.classList.add('hide');
      loginForm_Password.classList.add('hide');
      resetPassword.classList.remove('hide');
      that.refreshLoginForm(loginForm, errorTxt);
    });
    gotoLoginAfterReset.addEventListener('click', () => {
      loginBtn.disabled = false;
      registerBtn.disabled = true;
      resetPassBtn.disabled = true;
      resetPassword.classList.add('hide');
      resetSuccessTxt.classList.add('hide');
      resetPassBtn.classList.remove('hide');
      loginExistingUser.classList.remove('hide');
      loginForm_Password.classList.remove('hide');
      that.refreshLoginForm(loginForm, errorTxt);
    });

    // Login
    const inputEmail = document.querySelector('#inputLoginEmail');
    const inputPassword = document.querySelector('#inputLoginPassword');
    loginBtn.addEventListener('click', (e) => {
      e.preventDefault();
      errorTxt.innerHTML = '';
      
      if (inputEmail.value == '') {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.emptyEmail;
        return;
      } else if (inputPassword.value == '') {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.emptyPassword;
        return;
      }
      const regExValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!inputEmail.value.match(regExValidEmail)) {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.invalidEmail;
        return;
      }

      // perform login
      that.data.auth().signInWithEmailAndPassword(inputEmail.value, inputPassword.value)
        .then((user) => {
          // Signed in 
          // ...
          console.log('success logged in existing user - ');
          loginForm.classList.add('hide');
          errorTxt.innerHTML = '';
          inputEmail.value = '';
          inputPassword.value = '';
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('error logging in existing user - ' + errorCode + ' ' + errorMessage);
          
          let code = '';
          switch (errorCode) {
            case 'auth/invalid-email':
            case 'auth/user-disabled':
            case 'auth/user-not-found':
            case 'auth/wrong-password':
            case 'auth/too-many-requests':
              code = errorCode;
              break;
            default:
              code = 'default';
          }

          errorTxt.innerHTML = that.global.resources.login.errorTxt['signInWithEmailAndPassword'][code];
        });
    });

    // Register
    // TODO - send confirmation regitered email
    registerBtn.addEventListener('click', (e) => {
      e.preventDefault();
      errorTxt.innerHTML = '';

      if (inputEmail.value == '') {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.emptyEmail;
        return;
      } else if (inputPassword.value == '') {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.emptyPassword;
        return;
      }

      const regExValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!inputEmail.value.match(regExValidEmail)) {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.invalidEmail;
        return;
      }
      
      // perform add user
      that.data.auth().createUserWithEmailAndPassword(inputEmail.value, inputPassword.value)
        .then((objNewUser) => {
            // Signed in 
            console.log('success created new user - ' + objNewUser.user.email);

            // TODO if user already exist on datbase then delete previous record
            // for emails that deleted user and registered again
            // on success register delete previous record from database
            // (or update that record instead of adding a new one)

            const reAuthenticateUser = () => {
              that.global.modules.Firebase.reauthenticateUserToken(inputPassword.value, (reauthenticateUser) => {

                // TODO - show loading and disable inputs until this point

                that.global.modules.Firebase.refreshUserData(reauthenticateUser);
                errorTxt.innerHTML = '';
                inputEmail.value = '';
                inputPassword.value = '';
              });
            };

            const type = that.global.references.Roles.viewer;

            that.global.firebaseFunctions.addAppRole({
                email: objNewUser.user.email,
                type: type,
                isNewRegister: true
              },
              (addRoleResult) => {
                console.log("Success in adding role after registering")
                // TODO show success message

                // check firebase if currently user is in /Deleted/users and restore info if exists
                that.global.utils.getData(that.global.references.DataStructure.Deleted.users, (deltedUsers) => {
                  if (deltedUsers) {
                      const deletedUserId = Object.keys(deltedUsers).find(key => { return deltedUsers[key].providerData.email.normalize() === objNewUser.user.email.normalize() });
                      // Restore (undo delete)
                      // deletedData.isDeleted = null;
                      
                      if (deletedUserId) {
                        // check if already added to database (to not add twice with different id's)
                        this.global.utils.getData(this.global.references.DataStructure.users, (users) => {
                          this.global.retrievedData.users = users;
                          const existingId = Object.keys(users).find(key => { return users[key].providerData.email.normalize() === objNewUser.user.email.normalize(); });
                          const correctId = existingId ? existingId : deletedUserId;
                          that.global.modules.Firebase.addUserToDatabase(objNewUser.user.email, correctId, deltedUsers[deletedUserId], () => {
                            reAuthenticateUser();
                            // remove from firebase deleted
                            const path = that.global.references.DataStructure.Deleted.users + '/' + deletedUserId;
                            this.global.modules.Firebase.deleteDatabaseItem(path);
                          });
                        });
                      }
                      else {
                        reAuthenticateUser();
                      }
                  }
                  else {
                    reAuthenticateUser();
                  }
                });
              },
              (errorMessage) => {
                console.log("Error in adding role after registering");
                // errorTxt.innerHTML = errorMessage;
              });

            // errorTxt.innerHTML = '';
            // inputEmail.value = '';
            // inputPassword.value = '';
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('error creating new user - ' + errorCode + ' ' + errorMessage);

          let code = '';
          switch (errorCode) {
            case 'auth/email-already-in-use':
            case 'auth/invalid-email':
            case 'auth/weak-password':
              code = errorCode;
              break;
            default:
              code = 'default';
          }

          errorTxt.innerHTML = that.global.resources.login.errorTxt['createUserWithEmailAndPassword'][code];
      });
    });

    // Reset Password
    resetPassBtn.addEventListener('click', (e) => {
      e.preventDefault();
      errorTxt.innerHTML = '';

      if (inputEmail.value == '') {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.emptyEmail;
        return;
      }

      const regExValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!inputEmail.value.match(regExValidEmail)) {
        errorTxt.innerHTML = this.global.resources.login.errorTxt.invalidEmail;
        return;
      }

      // perform reset
      const actionCodeSettings = {
        url: 'https://tools.website-core.com/',
        handleCodeInApp: true
      };
      that.data.auth().sendPasswordResetEmail(inputEmail.value, actionCodeSettings)
        .then(function() {
          // Password reset email sent.
          console.log('Password reset email sent.');
          resetPassBtn.classList.add('hide');
          resetSuccessTxt.classList.remove('hide');
        })
        .catch(function(error) {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('Password reset email send error ' + errorCode + ' ' + errorMessage);

          let code = '';
          switch (errorCode) {
            case 'auth/invalid-email':
            case 'auth/user-not-found':
              code = errorCode;
              break;
            default:
              code = 'default';
          }

          errorTxt.innerHTML = that.global.resources.login.errorTxt['signInWithEmailAndPassword'][code];
        });

    });

    // Clear Error text on input focus
    inputEmail.addEventListener('focus', () => {
      errorTxt.innerHTML = '';
    });
    inputPassword.addEventListener('focus', () => {
      errorTxt.innerHTML = '';
    });
  }

  eventHandler (eventName) {
    switch (eventName) {
      case this.global.references.Events.userStateChanged:
        // console.log('eventHandler Login - user state changed - updateUserLoginOutBtn');
        this.updateUserLoginOutBtn();
        break;
      default:
        break;
    }
  }

  updateUserLoginOutBtn () {
    const that = this;

    if (this.htmlInitialized()) {
      const logInOutBtn = document.querySelector('#logInOutBtn');
      const logInOutStatus = logInOutBtn.querySelector('.logInOutStatus');
      const loggedInUser = logInOutBtn.querySelector('.loggedInUser');
      const user = that.global.getUser();
      if (user) {
        logInOutStatus.innerHTML = that.resources.login['logout'];
        loggedInUser.innerHTML = user.providerData && user.providerData.displayName ? user.providerData.displayName : that.resources.profile.title;
      }
      else {
        logInOutStatus.innerHTML = that.resources.login['login'];
        loggedInUser.innerHTML = that.resources.profile.title;
      }
      logInOutBtn.classList.remove('hide');
      that.utils.closeLoginForm();
    }
  }
}

export { Login } 