class Utils {

    constructor (global) {
      this.global = global;
      this.popupContainer = document.querySelector('#lightBoxContainer');
      this.rootEl = document.querySelector('#appMain');
      this.menuIcon = null;
      this.playerComponent = null;
    }

    // TODO - Lazy Load images
    // TODO - set app version
    // TODO -
    // Blank site comes up on Chrome mobile and FB browser (service worker problem)
    // Check where can use imports to reduce Entrypoint index.js size

    getData (itemToGet, callback) {
      // TODO - check if is first load (does not need updated data) then see if already in RetrievedData
      this.global.modules.Firebase.readData(itemToGet, (data) => {
        callback(data);
      });
    }

    updateProfile (updates, callback) {
      this.global.modules.Firebase.updateProfile(updates, (data) => {
        callback(data);
      });
    }

    updateEmail (newEmail, callback, showError) {
      const that = this;

      // For an email/password user. Prompt the user for the password again.
      const onSubmitForm = {
        func: (formInputs) => { 
          let errorMessage = '';
          let password = '';
          if (formInputs && formInputs.length) {
            formInputs.forEach(element => {
              if (element.getAttribute('type') == 'password' && element.classList.contains('password')) {
                if (element.value == '') {
                  errorMessage = this.global.resources.profile.errorTxt.updateProfile.email.emptyPassword;
                  if (showError && typeof showError === 'function') {
                    showError(errorMessage);
                    return;
                  }
                }
                else {
                  password = element.value;
                }
              }
            });
          }

          const getErrorMessage = (error, typeText) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`${typeText} - ${errorCode} ${errorMessage}`);
            
            let code = '';
            switch (errorCode) {
              case 'auth/invalid-email':
              case 'auth/wrong-password':
              case 'auth/too-many-requests':
              case 'auth/email-already-in-use':
                code = errorCode;
                break;
              default:
                code = 'default';
            }

            showError(that.global.resources.profile.errorTxt.updateProfile.email[code]);
          };

          if (password) {
            that.global.modules.Firebase.reauthenticateUserToken(password, (reauthenticateUser) => {
              // TODO - where to send a verification email (before allowing update) ????????????????
              this.global.modules.Firebase.updateEmail(newEmail, () => {
                if (callback && typeof callback === 'function') {
                  callback(newEmail);
                }
              },
              (error) => {
                const typeText = 'error updating email';
                getErrorMessage(error, typeText);
              });
            },
            (error) => {
              const typeText = 'error authenticating user with password';
              getErrorMessage(error, typeText);
            });
          }

        },
        btnConfirm: '',
        btnCancel: ''
      }

      const onClosePopup = null; // TODO - send on close function
      const contentHTML = `
        <span class="hiddenTitle" style="background-image:url(../images/icons/icon-password.png);background-size:auto 80%;">${this.global.resources.popupSubmitForm.title.updateEmail}</span>
        <div class="popupUpdateEmail">
          <label class="onSubmitFormLabel" for="inputPostTitleEng">${this.global.resources.popupSubmitForm.password}</label>
          <input type="password" class="onSubmitFormInput password" value="" placholder="${this.global.resources.login.userpassword}" maxlength="20">  
        </div>
      `;
      this.openLightBox(contentHTML, onClosePopup, null, this.global.resources, onSubmitForm);
    }

    showLoading (isShow) {
      const loadingOverlay = document.querySelector('#loadingOverlay');
      if (isShow) {
        loadingOverlay.classList.add('show');
      }
      else {
        loadingOverlay.classList.remove('show');
      }
    }

    showdarkenOverlay (isShow) {
      const darkenOverlay = document.querySelector('#darkenOverlay');
      if (isShow) {
        darkenOverlay.classList.add('show');
      }
      else {
        darkenOverlay.classList.remove('show');
      }
    }

    cloneObj (obj) {
      return JSON.parse(JSON.stringify(obj));
    }

    objToArray (obj) {
      const arr = [];
      for (let prop in obj) {
        arr.push(obj[prop]);
      }
      return arr;
    }

    arrSort (type, arr, sortType) {
      this.global.config.sort[type].set.call(this.global.config.sort, sortType);
      sortType = this.global.config.sort[type].get.call(this.global.config.sort);
      switch (sortType) {
        case this.global.references.Sort.oldestFirst:
          arr.sort(function(a, b){
            return a.uploadDate - b.uploadDate
          });
          break;
        case this.global.references.Sort.newestFirst:
          arr.sort(function(a, b){ return b.uploadDate - a.uploadDate });
          break;
      }
      return arr;
    }

    arrFilter (type, arr, filterType, contentId) {
      this.global.config.filter[type].set.call(this.global.config.filter, filterType);
      filterType = this.global.config.filter[type].get.call(this.global.config.filter);
      switch (filterType) {
        case this.global.references.Filter.posts.influencers:
          arr = arr.filter((obj) => { return obj.postedByInfluencer; });
          break;
        case this.global.references.Filter.posts.groups:
          arr = arr.filter((obj) => { return obj.postedByGroup; });
          break;
        case this.global.references.Filter.posts.requestedPost:
          const post = arr.find((obj) => { return obj.postId == contentId; });
          arr = post ? [post] : arr;
          break;
        case this.global.references.Filter.posts.all:
          break;
      }
      return arr;
    }

    menuIconBindClick (event) {
      this.menuIcon = document.querySelector('#menuIcon');
      if (event.target !== menuIcon && !menuIcon.contains(event.target)) {
        menuIcon.parentNode.parentNode.classList.remove('showMenu');
      }
      else {
        menuIcon.parentNode.parentNode.classList.toggle('showMenu');
        this.closeLoginForm();
      }
    }

    openLoginForm () {
      const loginForm = document.querySelector('#loginForm');
      loginForm.classList.toggle('hide');
    }

    closeLoginForm () {
      const loginForm = document.querySelector('#loginForm');
      loginForm.classList.add('hide');
      
      const loginErrorTxt = document.querySelector('#loginForm_ErrorTxt');
      const registerNewUser = document.querySelector('#loginForm_RegisterNewUser');
      const resetPassword = document.querySelector('#loginForm_ResetPassword');
      const resetSuccessTxt = document.querySelector('#loginForm_resetSuccessTxt');
      const loginExistingUser = document.querySelector('#loginForm_LoginExistingUser');
      const loginForm_Password = document.querySelector('#loginForm_Password');
      const loginBtn = document.querySelector('#loginForm_loginBtn').querySelector('button');
      const registerBtn = document.querySelector('#loginForm_registerBtn').querySelector('button');
      const resetPassBtn = document.querySelector('#loginForm_resetPassBtn').querySelector('button');
      loginErrorTxt.innerHTML = '';
      loginBtn.disabled = false;
      registerBtn.disabled = true;
      resetPassBtn.disabled = true;
      resetPassBtn.classList.remove('hide');
      loginExistingUser.classList.remove('hide');
      loginForm_Password.classList.remove('hide');
      registerNewUser.classList.add('hide');
      resetPassword.classList.add('hide');
      resetSuccessTxt.classList.add('hide');
    }

    closeMenu () {
      if (this.menuIcon) { this.menuIcon.parentNode.parentNode.classList.remove('showMenu'); }
    }

    closePopup () {
      const popupText = this.popupContainer.querySelector('#lightBoxPopupText');
      this.popupContainer.classList.remove('show'); popupText.innerHTML = '';
    }

    closeFloatingItems () {
      this.closeMenu();
      this.closePopup();
      this.closeLoginForm();
      this.playerComponent?.close();
    }

    setStickyHeader () {
      window.onscroll = function(utils) { 
        return function () { setSticky(utils); }
      }(this);

      const header = document.querySelector('#headerMain');
      const headerPlaceholder = document.querySelector('#headerStickyPlaceholder');
      // const headerHieght = header.offsetHeight;
      const headerPos = header.offsetTop;
      const screenHeight = window.screen.height;

      let scrollPos = 0;
      let lastScrolledTime = 0;
      let currentTime = 0;
      let scrollingUp = false;
      let passedMinThreshold = false;
      function setSticky (utils) {
        currentTime = (new Date().getTime());
        scrollingUp = window.pageYOffset < scrollPos;
        passedMinThreshold = window.pageYOffset > (headerPos + (screenHeight / 2));

        utils.closeMenu();
        utils.closeLoginForm();


        if ((!scrollingUp) || scrollingUp && !passedMinThreshold) {
          header.classList.remove('sticky');
          headerPlaceholder.classList.remove('show');
        }

        // Only check 900ms after last scroll
        if (currentTime - 900 > (lastScrolledTime)) {
          if(scrollingUp) {
            if (passedMinThreshold && !utils.global.modules[utils.global.references.ModuleNames.AddEditPopup].isActive) {
              header.classList.add('sticky');
              headerPlaceholder.classList.add('show');
            }
          }
          lastScrolledTime = currentTime;
        }

        scrollPos = window.pageYOffset;
      }
    }

    setCanonicalTag (page) {
      const canonicalTag = document.querySelector("link[rel='canonical']")
      if (canonicalTag != null) {
        if (page == 'home') {
          canonicalTag.setAttribute('href', this.global.config.host.get.call(this.global.config));
        }
        else {
          canonicalTag.remove();
        }
      }
      else {
        if (page == 'home') {
          const newTag = document.createElement('link');
          newTag.setAttribute('rel', 'canonical');
          newTag.setAttribute('href', this.global.config.host.get.call(this.global.config));
          document.head.appendChild(newTag);
        }
      }
    }

    setPage (page, isBackBtn, then) {
      const url = new URL(document.location);
      let params = new URLSearchParams(url.search);
      params.set('utm_page', page);

      if (!isBackBtn) {
        // Should pushState be pushed after a callback to wait for full page render (for SEO)?
        window.history.pushState({}, page, `${url.origin}?${params}`);
      }
      else {
        this.closeFloatingItems();
      }

      this.setCanonicalTag(page);

      // Todo - check case, ex. postsfeed or postsFeed
      this.rootEl.className = page;
      this.actionPerPage(page);
      this.scrollToTop();

      // console.log('history.pushState: ' + `${url.origin}?${params}`);
      // console.log('setPage -- params: ' + params);

      if (then && typeof then === 'function') { then(); }
    }
    
    setPageMetaTags (data) {
      const head  = document.getElementsByTagName('head')[0];
      const docDesc = head.querySelector('meta[name="description"]');

      let metaTag = null;
      document.title = '';
      docDesc.setAttribute('content', '');
      let content = '';
      const fbMetaTag = document.querySelector('meta[property="fb:app_id"]');
      for (let prop in data) {
        if (data[prop]) {
          metaTag  = head.querySelector(`meta[property="${data[prop].property}"]`);
          if (data[prop].content) {
            content = data[prop].content.toString().replace(/(<([^>]+)>)/gi, '');
            if (data[prop].property === 'og:title') {
              document.title = content;
            }
            else if (data[prop].property === 'og:description') {
              docDesc.setAttribute('content', content);
            }

            if (metaTag) {
              metaTag.setAttribute('content', content);
            }
            else {
              metaTag = document.createElement('meta');
              metaTag.setAttribute('property', data[prop].property);
              metaTag.content = content;
              // head.appendChild(metaTag);
              head.insertBefore(metaTag, fbMetaTag);
            }
          }
          else if (metaTag) {
            metaTag.remove();
          }
        }

      }
    }

    scrollToTop() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.closeStickyHeader();
    }

    closeStickyHeader () {
      const header = document.querySelector('#headerMain');
      if (header) {
        header.classList.remove('sticky');
      }
    }

    actionPerPage (page, retries) {
      const contentContainer = this.rootEl.querySelector('#contentContainer');

      const actions = () => {
        let metaTagsData = {};
        switch (page) {
          case 'team':
            this.readMoreTruncate(contentContainer.querySelectorAll('.teamMemberAbout'), null, 12);
            metaTagsData = this.global.config.metaTags.get.call(this.global.config, { page: 'home', data: {} });
            this.setPageMetaTags(metaTagsData);
            break;
          case 'postsFeed':
            console.log('go to posts feed page');
            this.readMoreTruncate(contentContainer.querySelectorAll('.postText'), null, 12);
            break;
          case 'home':
            console.log('go to home page');
            metaTagsData = this.global.config.metaTags.get.call(this.global.config, { page: 'home', data: {} });
            this.setPageMetaTags(metaTagsData);
            break;
          case 'groups':
            console.log('go to groups page');
            const divGroups_GroupsPage = contentContainer.querySelector('#divGroups_GroupsPage');
            this.readMoreTruncate(divGroups_GroupsPage.querySelectorAll('.groupItemDesc'), '.groupItemDescText', 5);
            metaTagsData = this.global.config.metaTags.get.call(this.global.config, { page: 'home', data: {} });
            this.setPageMetaTags(metaTagsData);
            break;
          default:
            metaTagsData = this.global.config.metaTags.get.call(this.global.config, { page: 'home', data: {} });
            this.setPageMetaTags(metaTagsData);
            break;
        }
      };

      if (contentContainer) {
        actions();
      }
      else {
        retries = !retries ? 1 : retries + 1;
        if (retries < 5) {
          setTimeout(() => { this.actionPerPage(page, retries); }, 200);
        }
      }
    }

    readMoreTruncate (items, textNodeSelector, maxLines) {
      let parentItem = null;
      let innerItem = null;
      if (items) {
        if (items.length) {
          for (let i = 0; i < items.length; i++) {
            parentItem = items[i];
            innerItem = textNodeSelector != null ? parentItem.querySelector(textNodeSelector) : parentItem;
            this.truncate(innerItem, maxLines);
          }
        }
        else {
          parentItem = items;
          if (parentItem && items.length !== 0) {
            innerItem = parentItem.querySelector(textNodeSelector);
            innerItem = textNodeSelector != null ? parentItem.querySelector(textNodeSelector) : parentItem;
            this.truncate(innerItem, maxLines);
          }
        }
      }
    }

    truncate (item, maxLines) {
      if (item) {
        // set css for item - line-height: ??em; font-size: ??px; AND make parent position:relative;overflow:hidden;
        const style = window.getComputedStyle(item, null);
        const lineHeight = parseInt(style.getPropertyValue('line-height'), 10);
        const height = item.clientHeight;
        const lines = height / lineHeight;
        if (lines > maxLines) {
          item.style.height = (lineHeight * maxLines) + 'px';
          item.classList.add('truncate');
          item.addEventListener('click', function openLightBox (txt, that) { return function () {  that.openLightBox(txt); } }(item.innerHTML, this));
        }
      }
    }

    openLightBox (contentHTML, onClose, onConfirm, resources, onSubmitForm, popupClass) {
      popupClass = popupClass ? popupClass : '';
      if (popupClass) { this.popupContainer.classList.add(popupClass); }
      const popupText = this.popupContainer.querySelector('#lightBoxPopupText');
      if (onConfirm && onConfirm.func && typeof onConfirm.func === 'function') {
        contentHTML += `
          <div id="popupConfirmButtons">
            <div class="confirmBtn">${onConfirm.btnConfirm || resources.popupConfirm.confirmBtn}</div>
            <div class="cancelBtn">${onConfirm.btnCancel || resources.popupConfirm.cancelBtn}</div>
          </div>
        `;
      }
      else if (onSubmitForm && onSubmitForm.func && typeof onSubmitForm.func === 'function') {
        contentHTML += `
          <div id="popupConfirmButtons">
            <div class="confirmBtn">${onSubmitForm.btnConfirm || resources.popupSubmitForm.submitBtn}</div>
            <div class="cancelBtn">${onSubmitForm.btnCancel || resources.popupSubmitForm.cancelBtn}</div>
          </div>
        `;
      }
      popupText.innerHTML = contentHTML;
      this.popupContainer.classList.add('show');
      const onCloseCallback = onClose ? () => {
        onClose();
        this.popupContainer.classList.remove('show'); popupText.innerHTML = '';
        if (popupClass) { this.popupContainer.classList.remove(popupClass); }
      } : () => {
        this.popupContainer.classList.remove('show'); popupText.innerHTML = '';
        if (popupClass) { this.popupContainer.classList.remove(popupClass); }
      };
      
      const closeBtn = this.popupContainer.querySelector('.closeBtn');
      closeBtn.innerHTML = '';
      const hiddenTitle = popupText.querySelector('.hiddenTitle');
      if (hiddenTitle) {
        closeBtn.innerHTML = hiddenTitle.outerHTML;
      }
      
      this.attachEventListeners('click', onCloseCallback, [closeBtn]);
      if (onConfirm && onConfirm.func && typeof onConfirm.func === 'function') {
        const confirmBtn = this.popupContainer.querySelector('.confirmBtn');
        const cancelBtn = this.popupContainer.querySelector('.cancelBtn');
        this.attachEventListeners('click', () => { onCloseCallback(); onConfirm.func(); } , [confirmBtn]);
        this.attachEventListeners('click', onCloseCallback, [cancelBtn]);
      }
      else if (onSubmitForm && onSubmitForm.func && typeof onSubmitForm.func === 'function') {
        const confirmBtn = this.popupContainer.querySelector('.confirmBtn');
        const cancelBtn = this.popupContainer.querySelector('.cancelBtn');
        const formInputs = this.popupContainer.querySelectorAll('input');
        this.attachEventListeners('click', () => { onCloseCallback(); onSubmitForm.func(formInputs); } , [confirmBtn]);
        this.attachEventListeners('click', onCloseCallback, [cancelBtn]);
      }
    }

    attachEventListeners (eventName, callback, arrElements) {
      arrElements = arrElements.length ? arrElements : [arrElements];
      arrElements.forEach(element => {
        const removeEvent = () => { element.removeEventListener(eventName, onEvent, false); };
        const addEvent = () => { element.addEventListener(eventName, onEvent, false); };
        
        const onEvent = (event) => { callback(element, event, removeEvent); };
        removeEvent();
        addEvent();
      });
    }

    getSocialIcons (type) {
      let objIcons = null;
      switch (type) {
        case 'color':
          objIcons = {
            website: `images/icons/icon-website-blue.png`,
            fb: `images/icons/icon-facebook-blue.png`,
            insta: `images/icons/icon-insta-color.png`,
            tiktok: `images/icons/icon-tiktok-black.png`,
            youTube: `images/icons/icon-youtube.png`,
            linktree: `images/icons/icon-linktree-green.png`,
            spotify: `images/icons/icon-spotify-green.png`,
            twitter: `images/icons/icon-twitter-black.png`,
            linkedIn: `images/icons/icon-linkedin-blue.png`
          }
          break;
        default:
          objIcons = {
            website: `images/icons/icon-website-blue.png`,
            fb: `images/icons/icon-facebook-blue.png`,
            insta: `images/icons/icon-insta-color.png`,
            tiktok: `images/icons/icon-tiktok-black.png`,
            youTube: `images/icons/icon-youtube.png`,
            linktree: `images/icons/icon-linktree-green.png`,
            spotify: `images/icons/icon-spotify-green.png`,
            twitter: `images/icons/icon-twitter-black.png`,
            linkedIn: `images/icons/icon-linkedin-blue.png`
          }
      }
      return objIcons;
    }

    openVideoPlayer (videoId, videoData) {
      this.playerComponent = this.global.modules[this.global.references.ModuleNames.Player];
      this.playerComponent.startPlayer(videoId, videoData);
    }

    // initVideoPlayer (videoData, resources, onClose) {
    //   this.closeLoginForm();
    //   const ifcPlayer = document.querySelector('#ifcPlayer');
    //   const ifcPlayerVideo = ifcPlayer.querySelector('#ifcPlayerVideo');
    //   const ifcPlayerFooter = ifcPlayer.querySelector('#ifcPlayerFooter');
    //   const closeBtn = ifcPlayer.querySelector('.closeBtn');
    //   const appMain = document.querySelector('#appMain');
    //   const embededVideo = videoData?.media?.video?.embed?.youtube ?? '';

    //   const socialLinks = this.getSocialLinks_large(videoData, resources.video.viewVideoOn);

    //   ifcPlayerVideo.innerHTML = embededVideo;
    //   ifcPlayerFooter.innerHTML = socialLinks;
    //   ifcPlayer.classList.add('show');
    //   appMain.style.display = 'none';

    //   const doEachTime = () => {
    //     ifcPlayer.classList.remove('show');
    //     appMain.style.display = 'block';
    //     closeBtn.removeEventListener('click', onCloseCallback);
    //     this.closeVideoPlayer = null;
    //   };
    //   const onCloseCallback = onClose ? () => { onClose(); doEachTime(); } : doEachTime;
      
    //   closeBtn.addEventListener('click', onCloseCallback);
    //   this.closeVideoPlayer = onCloseCallback;
    // }

    // openVideoPlayer (videoId, videoData, resources) {
    //   const onClose = () => { 
    //     clearTimeout(this.playTimoutOut);
    //     const videoEl = document.querySelector('#video' + videoId);
    //     videoEl?.contentWindow?.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
    //   };

    //   // this.initVideoPlayer();
    //   this.initVideoPlayer(videoData, resources, onClose);

    //   clearTimeout(this.playTimoutOut);
    //   this.playTimoutOut = setTimeout(() => {
    //     const vid1 = document.querySelector('#video' + videoId);
    //     vid1?.contentWindow?.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    //   }, 2000);
    // }

    getNonEmptyEmbed (embedObj) {
      for (const [key, value] of Object.entries(embedObj)) {
          if (value) {
              return value;
          }
      }
      return '';
    }

    videoScroll (scrollEl, direction, isRTL, numVideos, arrowBack, arrowFoward) {
      let previousLeft = scrollEl.scrollLeft;
      const offsetWidth  = scrollEl.scrollWidth;
      const scrollAmount = offsetWidth / numVideos;
      let animateSpeed = 1;

      // this.updateScrollArrows(scrollEl, isRTL, arrowBack, arrowFoward);
      
      const animate = function (newLeft) {
        animateSpeed += 1;
        setTimeout(function () {
          scrollEl.scrollLeft = newLeft; 
        }, animateSpeed);
      };

      previousLeft -= 1;
      for (let i = 0; i < scrollAmount; i++) {
        previousLeft = (direction == 'back') ? previousLeft - 1 : previousLeft + 1;
        animate(previousLeft);
      }

      // console.log("-------------------- ");
      // console.log("scrollEl.scrollLeft -- " + scrollEl.scrollLeft);
      // console.log("scrollEl.clientWidth -- " + scrollEl.clientWidth);
      // console.log("numVideos --- " + numVideos);
      // console.log("offsetWidth - " + offsetWidth );
      // console.log("scrollAmount - " + scrollAmount );

    }

    updateScrollArrows (scrollEl, isRTL, arrowBack, arrowFoward) {
      const scrollLeft = isRTL ? -scrollEl.scrollLeft : scrollEl.scrollLeft;
      if (scrollLeft == 0) {
        arrowBack.classList.add('hide');
      }
      else {
        arrowBack.classList.remove('hide');
      }

      if ((scrollLeft + scrollEl.clientWidth) >= (scrollEl.scrollWidth - 2)) {
        arrowFoward.classList.add('hide');
      }
      else {
        arrowFoward.classList.remove('hide');
      }

      if (!(scrollEl.clientWidth < scrollEl.scrollWidth)) {
        arrowFoward.classList.add('hide');
        arrowBack.classList.add('hide');
      }
    }

    userEventLog (data, eventType) {
      switch (eventType) {
        case this.global.references.Events.userEventLog.postClick:
          if (data && data.postId && !this.global.customSessionData[data.postId]) {
            this.global.customSessionData[data.postId] = true;
            // console.log(`Post Engagement - clicked on post ${data.postId}`);
            // console.log(this.global.customSessionData);
            this.global.modules.Firebase.incrementValue(`${this.global.references.DataStructure.posts}/${data.postId}/engagements`);
          }
          break;
        case this.global.references.Events.userEventLog.postReact:
          const user = this.global.getUser();
          const loggedIn = !!user;
          if (!loggedIn) {
            document.querySelector('#headerNav').querySelectorAll('li').forEach( function (el) {
              el.classList.remove('selected');
              el.classList.remove('showSubNav');
            });
            this.setPage('profile', false, () => { setTimeout(() => { this.openLoginForm(); }, 150); });
          }
          else if (data && data.postId && data.reaction && data.reaction.type) {
            // console.log(`clicked Post like/unlike - post ${data.postId}`);
            
            if (data.elPostContainer) {
              const likeBtn = data.elPostContainer.querySelector('.postLikeBtn');
              const isLiked = likeBtn.classList.contains('liked');
              const addSubtact = isLiked ? 'decreaseValue' : 'incrementValue';


              this.global.modules.Firebase.postReact(addSubtact, data.reaction.type, user.userId, data.postId);
            }
          }
          break;
      }
    }

    getEmbeds(objInputsEmbedMedia, resources, isHide) {
      const objEmbedMenuItems = ['youtube', 'tiktok']; // ['youtube', 'fb', 'insta', 'tiktok', 'twitter', 'spotify']
      return `
          <div id="videoUploadContainer" class="${isHide ? 'hidden' : ''}">
            <div id="videoUploadEmbedMenu">
              ${this.getEmbedMenuItems(objEmbedMenuItems)}
            </div>
            ${this.getEmbedMediaInputs(objInputsEmbedMedia, resources)}
          </div>
        `
    }

    getEmbedMenuItems (objEmbedMenuItems) {
      const menuItemsHtml = [];
      for (let i = 0; i < objEmbedMenuItems.length; i++) {
        menuItemsHtml.push(`
          <div class="videoUploadEmbedItem ${objEmbedMenuItems[i]}" data-type="${objEmbedMenuItems[i]}"></div>
        `);
      }

      return menuItemsHtml.join('');
    }

    getEmbedMediaInputs (obj, resources) {
      return `
        <div class="addEditItem videoLink addEditVideoLink_YouTube hidden" data-type="${'youtube'}">
          <span class="addEditLabel">${resources.addEditPopup.item.videoLink_youtube}</span>
          <div class="addEditVideoLink">
            <div class="prefix_inputLink youtube">${resources.addEditPopup.prefix.youTubeEmbed.text}</div>
            <input type="text" id="inputYouTubeEmbedLink" class="formInput" name="inputYouTubeEmbedLink" value="${obj.value_inputYouTubeEmbedLink}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${resources.addEditPopup.hint.embededLink_youtube}</span>
          </div>
        </div>
        <div class="addEditItem videoLink addEditVideoLink_Facebook hidden" data-type="${'fb'}">
          <span class="addEditLabel">${resources.addEditPopup.item.videoLink_fb}</span>
          <div class="addEditVideoLink">
            <div class="prefix_inputLink facebook">${resources.addEditPopup.prefix.facebookEmbed.text}</div>
            <input type="text" id="inputFacebookEmbedLink" class="formInput" name="inputFacebookEmbedLink" value="${obj.value_inputFacebookEmbedLink}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${resources.addEditPopup.hint.embededLink_fb}</span>
          </div>
        </div>
        <div class="addEditItem videoLink addEditVideoLink_Insta hidden" data-type="${'insta'}">
          <span class="addEditLabel">${resources.addEditPopup.item.videoLink_insta}</span>
          <div class="addEditVideoLink">
            <div class="prefix_inputLink insta">${resources.addEditPopup.prefix.instaTubeEmbed.text}</div>
            <input type="text" id="inputInstaEmbedLink" class="formInput" name="inputInstaEmbedLink" value="${obj.value_inputInstaEmbedLink}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${resources.addEditPopup.hint.embededLink_insta}</span>
          </div>
        </div>
        <div class="addEditItem videoLink addEditVideoLink_TikTok hidden" data-type="${'tiktok'}">
          <span class="addEditLabel">${resources.addEditPopup.item.videoLink_tiktok}</span>
          <div class="addEditVideoLink">
            <div class="prefix_inputLink tiktok">${resources.addEditPopup.prefix.tiktokEmbed.text}</div>
            <input type="text" id="inputTiktokEmbedLink" class="formInput" name="inputTiktokEmbedLink" value="${obj.value_inputTiktokEmbedLink}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${resources.addEditPopup.hint.embededLink_tiktok}</span>
          </div>
        </div>
        <div class="addEditItem videoLink addEditVideoLink_Twitter hidden" data-type="${'twitter'}">
          <span class="addEditLabel">${resources.addEditPopup.item.videoLink_twitter}</span>
          <div class="addEditVideoLink">
            <div class="prefix_inputLink twitter">${resources.addEditPopup.prefix.twitterEmbed.text}</div>
            <input type="text" id="inputTwitterEmbedLink" class="formInput" name="inputTwitterEmbedLink" value="${obj.value_inputTwitterEmbedLink}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${resources.addEditPopup.hint.embededLink_twitter}</span>
          </div>
        </div>
        <div class="addEditItem videoLink addEditVideoLink_Spotify hidden" data-type="${'spotify'}">
          <span class="addEditLabel">${resources.addEditPopup.item.videoLink_spotify}</span>
          <div class="addEditVideoLink">
            <div class="prefix_inputLink spotify">${resources.addEditPopup.prefix.spotifyEmbed.text}</div>
            <input type="text" id="inputSpotifyEmbedLink" class="formInput" name="inputSpotifyEmbedLink" value="${obj.value_inputSpotifyEmbedLink}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${resources.addEditPopup.hint.embededLink_spotify}</span>
          </div>
        </div>
      `;
    }

    initShowHideEmbeds (embedInputs, addEditEmbedMenuItems) {
      const inputContainers = document.querySelectorAll('.addEditItem.videoLink');
      const showHideEmbed = (embedToShow) => {
        addEditEmbedMenuItems.forEach(el => {
          el.classList.remove('selected');
        });
        embedToShow.menuItem.classList.add('selected');

        inputContainers.forEach(el => {
          el.classList.add('hidden');
        });
        const inputContainer = Array.from(inputContainers).find(item => item.getAttribute('data-type') === embedToShow.type);
        inputContainer.classList.remove('hidden');
      }
      this.attachEventListeners('click', (element) => {
        const type = element.getAttribute('data-type');
        const embedToShow = { type: type, value: embedInputs[type].value, input: embedInputs[type], menuItem: element };
        showHideEmbed(embedToShow);
      }, addEditEmbedMenuItems);

      this.clearOtherInputs(embedInputs);

      // Select Embed that has value or default to youtube
      const menuItem_youtube = Array.from(addEditEmbedMenuItems).find(item => item.getAttribute('data-type') === 'youtube');
      const embedInputDefault = { type: 'youtube', value: '', input: inputYouTubeEmbedLink, menuItem: menuItem_youtube };
      let embedToShow = null;
      for (let prop in embedInputs) {
        if (embedInputs[prop].value) {
          const menuItem = Array.from(addEditEmbedMenuItems).find(item => item.getAttribute('data-type') === prop);
          embedToShow = {};
          embedToShow.type = prop;
          embedToShow.value = embedInputs[prop].value;
          embedToShow.input = embedInputs[prop];
          embedToShow.menuItem = menuItem;
          break;
        }
      }
      embedToShow = embedToShow ?? embedInputDefault;
      showHideEmbed(embedToShow);
    }

    clearOtherInputs (objInputsEmbedMedia) {
      this.attachEventListeners('change', (element) => {
        Object.values(objInputsEmbedMedia).forEach(input => {
          if (input !== element) input.value = '';
        });
      }, Object.values(objInputsEmbedMedia));
    }

    getSocialLinks_large (data, text) {
      const socialIcons = this.getSocialIcons('color');
      const socialLinks = [];
      let link = null;
      for (let prop in data.socialLinks) {
        link = data.socialLinks[prop];
        if (link) {
          // TODO - on link click, pause video
          socialLinks.push(`
            <div class="postSocialIcon">
              <a href="${link}" target="_blank">
                <img src="${socialIcons[prop]}" />
              </a>
            </div>
          `);
        }
      }
      const linksHTML = socialLinks.length ? ('<div class="postSocialLinks"><div class="socialLinksTxt">' + text + '</div> ' + socialLinks.join('') + '</div>') : '';

      return linksHTML
    }

    getSocialLinks_small (data, cssClass) {
      const linksHtml = []
      linksHtml.push(`<div class="socialIcons ${cssClass}">`);
            
      const socialLinks = [];
      let link = null;
      let addedFirstLogo = false;
      for(let prop in data.socialLinks) {
        link = data.socialLinks[prop];
        if (link) {
          if (addedFirstLogo) socialLinks.push(`<span class="iconDivider"></span>`);
          socialLinks.push(`<a href="${link}" target="_blank"><span class="socialIcon icon-${prop}"></span></a>`);
          addedFirstLogo = true;
        }
      }
      linksHtml.push(socialLinks.join(''));
      linksHtml.push(`</div>`);

      
      return linksHtml.join('');
    }

    getInputsSocialLinks (type, objInputsData, resources) {
      const inputsHtml = [];
      for (let socialType in objInputsData) {
        inputsHtml.push(`
          <div id="inputSocialLinks_${socialType}" class="input_${socialType}">
            <input type="text" value="${objInputsData[socialType]}" required>
            <div class="addEditItemHint">
              <span class="hintLabel">${resources.addEditPopup.hint.hint}</span>
              <span class="hintExample">${resources.addEditPopup.hint.socialLinks[type][socialType]}</span>
            </div>
          </div>
        `);
      }

      return `
        <div class="addEditItem inputs_socialLinks ${type}">
          <span class="addEditLabel">${resources.addEditPopup.item[type].title_inputsSocialLinks}</span>
          <div class="addEditExternalLinks">
            ${inputsHtml.join('')}
          </div>
        </div>
      `;
    }
}
  
export { Utils };