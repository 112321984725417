import { DataModel } from '../data/data-model.js';

class Profile {
  constructor (global) {
    this.name = 'Profile';
    this.global = global;
    this.dataModel = DataModel;

    this.utils = global.utils;
    this.resources = this.global.getResources();
    this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);

    this.timeoutHandler1 = null;
    this.timeoutHandler2 = null;
  } 

  init () {
    console.log('Profile component is loaded');
    this.buildHtml();
    this.bindEvents();
  }

  // TODO - add banner for user that have "Influencer Profile"

  buildHtml () {
    const rootEl = document.querySelector('#contentContainer');
    const html = `
      <main class="contentProfile">
        <div id="profileHeader"></div>
        <div id="profileContent">
          <div id="profile_ProfileImageErrorTxt"></div>
          <div id="profilePageTitle">
            <div id="profilePageProfileImage"><img src="images/icons/profileImage-default.png"></div>
            <h3 id="profilePageDisplayName">${this.resources.profile.title}</h3>
            <div id="profileUserRole" class="profileUserRole hidden loggedinOnly"></div>
          </div>
          <div id="profileNotloggedIn" class="hidden">${this.resources.profile.notLoggedIn}</div>
          <div id="profileInfo" class="hidden">

            <div class="profile_Item" id="profile_email">
              <span class="profileLabel">${this.resources.profile.info.user.username}: </span>
              <span class="profileValue"></span>
            </div>

            <div class="profile_Item" id="profile_displayName">
              <span class="profileLabel">${this.resources.profile.info.user.displayName}: </span>
              <span class="profileValue"></span>
            </div>

            <div class="profile_Item hidden checkPermissions edit_assignRoles">
              <span class="profileLabel">${this.resources.profile.roles.addRole}:&nbsp;</span>
              <span class="profileValue">
                <div class="profileValueRow">
                  <span class="profileValue">
                    <div class="profileValueRow" id="profile_findUser">
                      <label class="profileValueRowLabel" for="inputGetUserByEmail">${this.resources.profile.info.user.email}</label>
                      <input id="inputGetUserByEmail" class="profileValueRowValue" type="email" placholder="${this.resources.profile.info.user.email}" name="inputGetUserByEmail" required>
                      <div><button type="submit">${this.resources.profile.actions.findUser}</button></div>
                    </div>
                    <div id="profile_findUserErrorTxt"></div>
                  </span>
                </div>
              </span>
            </div>

            <div class="profileValueRow hidden" id="profile_addRoleContainer"></div>
            <div id="profile_addRoleErrorTxt"></div>

            <div class="profile_Item hidden" id="profile_groups">
              <span class="profileLabel">${this.resources.profile.info.user.associatedGroups}: </span>
              <span class="profileValue">
                <span id="profile_associatedGroups">
                  ${this.resources.profile.hasRight.false}
                </span>
              </span>
            </div>

          </div>
          <div class="profileFooter">
            <div class="privacyPolicy"><a href="https://www.websitepolicies.com/policies/view/zlfdaokK" target="_blank">${this.resources.profile.privacyPolicy}</a></div>
            
            <div class="profileFooterDivider deleteAccountDivider">|</div>
            <div class="deleteAccount">${this.resources.profile.delete.deleteAccount}</div>
          </div>
        </div>
      </main>
    `;
    rootEl.insertAdjacentHTML('beforeend', html);
  }

  htmlInitialized () {
    const contentProfile = document.querySelector('.contentProfile');
    return contentProfile;
  }

  eventHandler (eventName) {
    switch (eventName) {
      case this.global.references.Events.userStateChanged:
      case this.global.references.Events.updateUserItems:
        clearTimeout(this.timeoutHandler1);
        this.timeoutHandler1 = setTimeout(() => {
          // console.log('eventHandler Profile - user state changed / update user items - updateUserData');
          this.updateUserData();
          this.updateAssociatedGroups();
        }, 400);
        break;
      case this.global.references.Events.clearOnLogout:
        clearTimeout(this.timeoutHandler2);
        this.timeoutHandler2 = setTimeout(() => {
          this.showDeleteProfileBtn(false);
          this.clearProfileValues();
          const logInOutBtn = document.querySelector('#logInOutBtn');
          const logInOutStatus = logInOutBtn.querySelector('.logInOutStatus');
          logInOutStatus.style.backgroundImage = 'url(images/icons/profileImgSmall-default.png)';
        }, 400);
      default:
        break;
    }
  }

  clearProfileValues () {
    const username = document.querySelector('#profile_email').querySelector('.profileValue');
    const displayName = document.querySelector('#profile_displayName').querySelector('.profileValue');
    const displayNameLabel = document.querySelector('#profilePageDisplayName');
    displayNameLabel.innerHTML = this.resources.profile.title;
    username.innerHTML = '';
    displayName.innerHTML = '';

    const profileImageError = document.querySelector('#profile_ProfileImageErrorTxt');
    profileImageError.innerHTML = '';

    this.clearRoleEditing();
  }

  clearRoleEditing () {
    const addRoleErrorTxt = document.querySelector('#profile_addRoleErrorTxt');
    const findUserErrorTxt = document.querySelector('#profile_findUserErrorTxt');
    const addRoleContainer = document.querySelector('#profile_addRoleContainer')
    addRoleErrorTxt.innerHTML = '';
    findUserErrorTxt.innerHTML = '';
    addRoleContainer.innerHTML = '';
    addRoleContainer.classList.add('hidden');
  }

  showDeleteProfileBtn (isShow) {
    const deleteAccount = document.querySelector('.deleteAccount');
    const profileFooterDivider = document.querySelector('.profileFooterDivider');
    if (isShow) {
      deleteAccount.classList.remove('hidden');
      profileFooterDivider.classList.remove('hidden');
    }
    else {
      deleteAccount.classList.add('hidden');
      profileFooterDivider.classList.add('hidden');
    }
  }

  clearErrorMessages () {
    // clear error messages
      const addRoleErrorTxt = document.querySelector('#profile_addRoleErrorTxt');
      addRoleErrorTxt.innerHTML = '';
      const profileImageError = document.querySelector('#profile_ProfileImageErrorTxt');
      profileImageError.innerHTML = '';
  }

  updateAssociatedGroups () {
    const user = this.global.getUser();
    const contentHtml = this.htmlInitialized();

    if (contentHtml) {
      const listGroups = (groups) => {
        const profile_groups = contentHtml.querySelector('#profile_groups');
        const associatedGroups = profile_groups.querySelector('#profile_associatedGroups');
        profile_groups.classList.add('hidden');
        if (user && user.accessLevel && user.accessLevel.associatedGroups) {
          const groupsHtml = [];
          let groupId = '';
          let hasgroups = false;
          for (let prop in user.accessLevel.associatedGroups) {
            groupId = Object.keys(groups).find(key => {
              return key === prop
            });
            if (groupId) {
              groupsHtml.push(`<div class="profile_groupAdmin"><span>${groups[groupId].lang[this.uiLang].title}</span></div>`);
              hasgroups = true;
            }
          }
          associatedGroups.innerHTML = groupsHtml.join('');
          if (hasgroups) { profile_groups.classList.remove('hidden'); }
        }
      };

      if (!(this.global.retrievedData.groups && Object.keys(this.global.retrievedData.groups).length)) {
        this.global.utils.getData(this.global.references.DataStructure.groups, (groups) => {
          this.global.retrievedData.groups = groups;
          listGroups(groups);
        });
      }
      else {
        listGroups(this.global.retrievedData.groups);
      }
    }
  }

  updateUserData () {
    const that = this;
    const user = that.global.getUser();
    const contentHtml = this.htmlInitialized();

    profileNotloggedIn.classList.add('hidden');
    if (contentHtml) {
      const profileInfo = contentHtml.querySelector('#profileInfo');
      const profileNotloggedIn = contentHtml.querySelector('#profileNotloggedIn');
      const username = contentHtml.querySelector('#profile_email').querySelector('.profileValue');
      const displayName = contentHtml.querySelector('#profile_displayName').querySelector('.profileValue');
      const displayNameLabel = document.querySelector('#profilePageDisplayName');
      const profileImage = contentHtml.querySelector('#profilePageProfileImage').querySelector('img');
      const logInOutBtn = document.querySelector('#logInOutBtn');
      const logInOutStatus = logInOutBtn.querySelector('.logInOutStatus');

      // Update Values
      profileImage.src = 'images/icons/profileImage-default.png';
      logInOutStatus.style.backgroundImage = 'url(images/icons/profileImgSmall-default.png)';
      if (user && user.providerData) {
        profileInfo.classList.remove('hidden');
        this.showDeleteProfileBtn(true);

        const profileImageUrl = this.global.config.firebase.storage.url.profilePic.get.call(this.global.config.firebase.storage.url, user.userId, user.providerData.photoURL);
        this.utils.attachEventListeners('error', (element) => {
          element.src = 'images/icons/profileImage-default.png';
          logInOutStatus.style.backgroundImage = 'url(images/icons/profileImgSmall-default.png)';
        }, [profileImage]);
        logInOutStatus.style.backgroundImage = `url(${profileImageUrl})`;
        profileImage.src = profileImageUrl;

        console.log(user.providerData);
        
        username.innerHTML = `
          <div class="editField">${user.providerData.email}</div>
        `;
        const displayName_defautlText = this.resources.profile.enterDisplayName;
        displayName.innerHTML = `
          <div class="editField">${(user.providerData.displayName || displayName_defautlText)}</div>
        `;
        displayNameLabel.innerHTML = user.providerData.displayName || this.resources.profile.title;

        const userRole = contentHtml.querySelector('#profileUserRole');
        let text = '';
        if (user.accessLevel) {
          let role = user.accessLevel.role;
          if (role) {
            role = Object.getOwnPropertyNames(role)[0];
            if (role && role !== that.global.references.Roles.viewer) {
              text = this.resources.profile.roles[role];
            }
          }
        }
        userRole.innerHTML = text;

        const fields = [
            { element: username, actionName: 'email', defautlText: null },
            { element: displayName, actionName: 'displayName', defautlText: displayName_defautlText }
        ];
        this.bindEditFields(fields);
        
      }
      else {
        profileInfo.classList.add('hidden');
        profileNotloggedIn.classList.remove('hidden');
        this.showDeleteProfileBtn(false);
      }

      this.clearErrorMessages();
      this.clearRoleEditing();
    }
  }

  bindEditFields (fields) {
    let updates = null;
    for (let i = 0; i < fields.length; i++) {
      let objField = fields[i];
      let elemField = objField.element;
      let elemEditField = elemField.querySelector('.editField');
      let submitClicked = false;
      let editHtml = '';
      let btnEditField = null;
      let inputEditField = null;
      let defautlText = '';
      let initialValue = '';

      this.utils.attachEventListeners('click', (element, event) => {
        element.classList.add('hidden');
        
        initialValue = element.innerText;
        editHtml = `
          <div class="containerEditField">
            <div class="inputsrEditField">
              <input type text class="inputEditField" value="${initialValue}">
              <button type="submit" class="btnEditField" data-action="displayName">${this.resources.profile.btnEdit}</button>
            </div>
            <div class="errorEditField hidden"></div>
          </div>
        `;
        element.insertAdjacentHTML('afterend', editHtml);

        btnEditField = element.parentNode.querySelector('.btnEditField');
        this.utils.attachEventListeners('mousedown', (el, evt) => {
          submitClicked = true;
          const input = el.parentNode.querySelector('.inputEditField');
          const errorDiv = el.parentNode.parentNode.querySelector('.errorEditField');
          const value = input.value;

          errorDiv.classList.add('hidden');
          errorDiv.innerHTML = '';
          console.log(value);
          if (value == '') {
            errorDiv.innerHTML = this.resources.profile.errorTxt.enterValue;
            errorDiv.classList.remove('hidden');
            return;
          }
          else if (value === initialValue) { //  && value !== defautlText
            el.parentNode.parentNode.remove();
            element.innerHTML = initialValue;
            element.classList.remove('hidden');
          }
          else {
            if (objField.actionName === 'email') {
              // validate email
              const regExValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
              if (!input.value.match(regExValidEmail)) {
                errorDiv.innerHTML = this.resources.login.errorTxt.invalidEmail;
                errorDiv.classList.remove('hidden');
                return;
              }

              this.utils.updateEmail(value, () => {
                console.log(`successcully updated ${objField.actionName}`);
                el.parentNode.parentNode.remove();
                element.innerHTML = value;
                element.classList.remove('hidden');

                // check when to send verification email
  
                const user = this.global.modules.Firebase.firebase.auth().currentUser;
                if (user) {
                  this.global.utils.getData(this.global.references.DataStructure.users, (users) => {
                    this.global.retrievedData.users = users;

                    const localUserData = this.global.getUser();
                    const userId = Object.keys(users).find(key => { return key === localUserData.userId });
                    const userData = users[userId];

                    // update new email to associate groups (for admin email)
                    if (userData && userData.userId && userData.accessLevel && userData.accessLevel.associatedGroups) {
                      this.global.utils.getData(this.global.references.DataStructure.groups, (groups) => {
                        let dataRef = '';
                        const newValue = value;
                        for (let prop in userData.accessLevel.associatedGroups) {
                          // update on firebase
                          if(groups[prop].groupAdmins[userData.userId].email) {
                            dataRef = `${this.global.references.DataStructure.groups}/${prop}/groupAdmins/${userData.userId}/email`;
                            this.global.modules.Firebase.updateDataItem(dataRef, newValue);
                          }
                        }
                        this.global.modules.Firebase.refreshUserData(user);
                        // refresh groups (to update correct admin email)
                        setTimeout(() => { this.global.relayEvent(this.global.references.Events.groupAddedUpdated); }, 0);
                      });
                    }
                  });
                }
              }, (errorMessage) => {
                errorDiv.innerHTML = errorMessage;
                errorDiv.classList.remove('hidden');
              });
            }
            else {
              updates = {};
              updates[objField.actionName] = value;
              
              this.utils.updateProfile(updates, () => {
                console.log(`successcully updated ${objField.actionName}`);
                el.parentNode.parentNode.remove();
                element.innerHTML = value;
                element.classList.remove('hidden');
  
                const user = this.global.modules.Firebase.firebase.auth().currentUser;
                if (user) {
                  // TODO there was an error The update failed TypeError: Cannot assign to read only property 'displayName' of object '#<Object>'
                  // user.providerData['displayName'] = value; // why does this cause error above, why is it already update before this point?
                  this.global.modules.Firebase.refreshUserData(user);
                  // this.global.relayEvent(this.global.references.Events.userStateChanged);
                }
              });
            }
          }
        }, [btnEditField]);

        inputEditField = element.parentNode.querySelector('.inputEditField');
        this.utils.attachEventListeners('focus', (el, evt) => {
          submitClicked = false;
          const errorDiv = el.parentNode.parentNode.querySelector('.errorEditField');
          const value = el.value;

          defautlText = objField.defautlText;
          errorDiv.classList.add('hidden');
          errorDiv.innerHTML = '';
          if (defautlText && value === defautlText) {
            el.value = '';
          }
          console.log(value);
        }, [inputEditField]);

        this.utils.attachEventListeners('blur', (el, evt) => { // focusout
          if (!submitClicked) {
            const errorDiv = el.parentNode.parentNode.querySelector('.errorEditField');
            errorDiv.classList.add('hidden');
            errorDiv.innerHTML = '';
            el.parentNode.parentNode.remove();
            element.innerHTML = initialValue;
            element.classList.remove('hidden');
          }
          submitClicked = false;
        }, [inputEditField]);

        inputEditField.focus();
        
      }, [elemEditField]);
    }
  }

  bindEvents () {
    const that = this;
    const inputGetUserByEmail = document.querySelector('#inputGetUserByEmail');
    const findUserBtn = document.querySelector('#profile_findUser').querySelector('button');
    const findUserErrorTxt = document.querySelector('#profile_findUserErrorTxt');
    const addRoleContainer = document.querySelector('#profile_addRoleContainer')
    const addRoleErrorTxt = document.querySelector('#profile_addRoleErrorTxt');
    const profileNotloggedIn = document.querySelector('#profileNotloggedIn');
    const profileImage = document.querySelector('#profilePageProfileImage');
    const profileImageError = document.querySelector('#profile_ProfileImageErrorTxt');
    const deleteAccount = document.querySelector('.deleteAccount');

    this.utils.attachEventListeners('click', (element, event) => {
      this.utils.openLoginForm();
    }, [profileNotloggedIn]);

    this.utils.attachEventListeners('click', (element, event) => {
      this.updateUserData();

      const user = that.global.getUser();
      if (!user) {
        this.utils.openLoginForm();
      }
      else {
        // Edit Profile Image
        // TODO - open popup for advanced image editing
        // for now open file to upload
        var input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => { 
          // var file = e.target.files[0]; 
          const hundredKb = (1024 * 1024) / 10;
          const maxSize = hundredKb * 2;
          if (!e.target.files.length || e.target.files[0].size > maxSize || !e.target.files[0].name.match(/.(jpe?g|png)$/i)) {
            profileImageError.innerHTML = this.resources.profile.errorTxt.profileImageSize;
          }
          else {
            profileImageError.innerHTML = '';
            console.log(user);
            
            const imageFile = e.target.files[0];
            const originalName = imageFile.name;
            const extension = originalName.substring(originalName.lastIndexOf('.')); 
            const timestamp = new Date().getTime();
            const photoName = 'photo' + timestamp + extension;

            this.global.modules.Firebase.storageUploadProfileImage(imageFile, photoName, user);
            
          }
        }

        input.click();

      }
    }, [profileImage]);

    findUserBtn.addEventListener('click', (e) => {
      e.preventDefault();
      
      this.updateUserData();
      findUserErrorTxt.innerHTML = '';
      addRoleErrorTxt.innerHTML = '';
      addRoleContainer.innerHTML = '';
      addRoleContainer.classList.add('hidden');

      // TODO - disable button until response
      // currently it is possible to click and run funtion many times

      if (inputGetUserByEmail.value == '') {
        findUserErrorTxt.innerHTML = this.global.resources.login.errorTxt.emptyEmail;
        return;
      }
      
      const regExValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!inputGetUserByEmail.value.match(regExValidEmail)) {
        findUserErrorTxt.innerHTML = this.global.resources.login.errorTxt.invalidEmail;
        return;
      }

      this.findUserByEmail(inputGetUserByEmail.value, findUserErrorTxt, addRoleContainer, addRoleErrorTxt);
      inputGetUserByEmail.value = '';
      findUserErrorTxt.innerHTML = '';
      this.utils.showLoading(true);
    });

    // Clear Error text on input focus
    this.utils.attachEventListeners('focus', (element, event) => {
      switch (element) {
        case inputGetUserByEmail:
          findUserErrorTxt.innerHTML = '';
          break;
      }
      // addRoleErrorTxt.innerHTML = '';
    }, [inputGetUserByEmail]);

    this.utils.attachEventListeners('click', (element, event) => {
      // Open Delete account steps popup
      // show in popup all associated content
      // check if there are other admin on that content
      // infor user that if there are others that content
      // will continue to exist if not then it will be deleted
      // give option to make another and admin in order to not loose data
      console.log('delete acount');

      const currentUser = this.global.getUser();
      console.log(currentUser);

      // Step 1
      if (currentUser && currentUser.providerData) {
        const imageUrl = (id, image) => { return this.global.config.firebase.storage.url.profilePic.get.call(this.global.config.firebase.storage.url, id, image); };
        const profileImage = currentUser.providerData.photoURL ? ` style="background-image:url(${imageUrl(currentUser.userId, currentUser.providerData.photoURL)})"` : '';
        const displayName = currentUser.providerData.displayName ? currentUser.providerData.displayName : currentUser.providerData.email;
        
        this.deleteAccountSteps(currentUser, profileImage, displayName)
      }
      else {
        // show error (request to logout and refresh and clear cache then login and try again)
      }

    }, [deleteAccount]);
  }

  deleteAccountSteps (currentUser, profileImage, displayName) {
    let nextStep = null;
    const profileInfo = `<span class="hiddenTitle"${profileImage}>${displayName}</span>`;
    const associatedGroups = this.getAssocatedGroups(currentUser);
    const associatedInfluencers = this.getAssocatedInfluencers(currentUser);
    const associatedPosts = this.getAssociatedPosts(currentUser);

    const deleteAccount = {
      func: () => {
        console.log('Your account is now being deleted');

        const deleteSuccess = () => {
          const noprofile = `<span class="hiddenTitle" style="background-image:url(images/icons/profileImage-default.png)">${this.resources.profile.delete.thankYou}</span>`;
          const accountDeletedHtml = noprofile + this.resources.profile.delete.accountDeleted;
          this.global.logout.call(this.global);
          this.global.utils.openLightBox(accountDeletedHtml, null, null, this.global.resources);
        };
        const deleteError = () => {
          const accountDeleteErrorHtml = profileInfo + this.resources.profile.delete.deleteError;
          this.global.utils.openLightBox(accountDeleteErrorHtml, null, null, this.global.resources);
        };

        this.removeAsGroupAdmins(currentUser, associatedGroups.obj);
        this.global.modules.Firebase.deleteUser(currentUser.userId, deleteSuccess, deleteError);
        // deleteSuccess(); // test
        // deleteError(); // test
      },
      btnConfirm: this.resources.popupConfirm.confirm.deleteAcount,
      btnCancel: ''
    };

    const step5 = {
      func: () => {
        console.log('onConfirm delete account - step 5');

        const step5_html = profileInfo + this.resources.profile.delete.finalConfirm.replace('@email@', currentUser.providerData.email);
        nextStep = deleteAccount;
        this.global.utils.openLightBox(step5_html, null, nextStep, this.global.resources);
      },
      btnConfirm: this.resources.popupConfirm.confirm.continue,
      btnCancel: ''
    };

    const step4 = {
      func: () => {
        console.log('onConfirm delete account - step 4');

        const step4_html = profileInfo + `<div class="deleteAccountStep4">${associatedPosts.html}</div>`;
        nextStep = step5;
        this.global.utils.openLightBox(step4_html, null, nextStep, this.global.resources);
      },
      btnConfirm: this.resources.popupConfirm.confirm.continue,
      btnCancel: ''
    };
    
    const step3 = {
      func: () => {
        console.log('onConfirm delete account - step 3');

        const step3_html = profileInfo + `<div class="deleteAccountStep3">${associatedInfluencers.html}</div>`;
        nextStep = (associatedPosts.obj ? step4 : step5);
        this.global.utils.openLightBox(step3_html, null, nextStep, this.global.resources);
      },
      btnConfirm: this.resources.popupConfirm.confirm.continue,
      btnCancel: ''
    };

    const step2 = {
      func: () => {
        console.log('onConfirm delete account - step 2');
  
        const step2_html = profileInfo + `<div class="deleteAccountStep2">${associatedGroups.html}</div>`;
        nextStep = associatedInfluencers.obj ? step3 : (associatedPosts.obj ? step4 : step5);
        this.global.utils.openLightBox(step2_html, null, nextStep, this.global.resources);
      },
      btnConfirm: this.resources.popupConfirm.confirm.deleteAcount,
      btnCancel: ''
    };

    console.log('onConfirm delete account - step 1');
    
    const step1_html = profileInfo + `<div class="deleteAccountStep1">${this.resources.profile.delete.confirm}</div>`;
    nextStep = associatedGroups.obj ? step2 : (associatedInfluencers.obj ? step3 : (associatedPosts.obj ? step4 : step5));
    this.global.utils.openLightBox(step1_html, null, nextStep, this.global.resources);
  }

  removeAsGroupAdmins (user, associatedGroups) {
    const removeAsAdmin = (groups) => {
      for (let prop in associatedGroups) {
        if (groups[prop] && groups[prop].groupAdmins && groups[prop].groupAdmins[user.userId]) {
          const path = this.global.references.DataStructure.groupAdmins.replace('@groupId@', prop).replace('@userId@', user.userId);
          this.global.modules.Firebase.deleteDatabaseItem(path);
        }
      }
    };
    if (!(this.global.retrievedData.groups && Object.keys(this.global.retrievedData.groups).length)) {
      this.global.utils.getData(this.global.references.DataStructure.groups, (groups) => {
        this.global.retrievedData.groups = groups;
        removeAsAdmin(groups);
      });
    }
    else {
      removeAsAdmin(this.global.retrievedData.groups);
    }
  }

  getAssocatedGroups (user) {
    const users = this.global.retrievedData.users;
    let groupsHtml = '';
    let groups = null;
    
    if (user.accessLevel && user.accessLevel.associatedGroups) {
      groups = user.accessLevel.associatedGroups;
      let objGroup = null;
      let groupName = '';
      let objAdmin = null;
      let adminName = '';
      let profilePic = '';
      let classMe = '';
      const getLogo = (id, imgName) => { return this.global.config.firebase.storage.url.groupLogo.get.call(this.global.config.firebase.storage.url, id, imgName) };
      const getProfilePic = (id, imgName) => { return this.global.config.firebase.storage.url.profilePic.get.call(this.global.config.firebase.storage.url, id, imgName) };
      groupsHtml += `<div>${this.resources.profile.delete.adminOfGroups}</div>`;
      groupsHtml += `<div class="deleteAccount_needAdmin">${this.resources.profile.delete.needAdmin}</div>`;
      for (let propGroup in groups) {
        objGroup = this.global.retrievedData.groups[propGroup];
        groupName = objGroup.lang[this.uiLang].title;
        groupsHtml += `
          <div class="deleteAcount_group" data-id="${propGroup}">
            <img src="${getLogo(objGroup.groupId, objGroup.logo)}">
            <span>${groupName}</span>
          </div>
        `;
        for (let propAdmin in objGroup.groupAdmins) {
          objAdmin = users[propAdmin];
          adminName = objAdmin.providerData.displayName ? objAdmin.providerData.displayName : objAdmin.providerData.email;
          profilePic = !objAdmin.providerData.photoURL ? 'images/icons/profileImgSmall-default.png' : getProfilePic(objAdmin.userId, objAdmin.providerData.photoURL);
          classMe = propAdmin == user.userId ? ' class="me"' : '';
          groupsHtml += `
            <div class="deleteAcount_groupAdmin" data-id="${propAdmin}">
              <img src="${profilePic}">
              <span${classMe}>${adminName}</span>
            </div>
          `;
        }
      }
    }

    const associatedGroups = {
      obj: groups,
      html: groupsHtml
    };

    return associatedGroups;
  }

  getAssocatedInfluencers (user) {
    let influencersHtml = '';
    let influencers = null;

    if (user.accessLevel && user.accessLevel.isInfluencer) {
      // influencers = user.accessLevel.associatedInfluencers; // todo - add self if isInfluencer
      influencers = user;
      influencersHtml += `<div>${this.resources.profile.delete.isInfluencer}</div>`;
    }

    const associatedInfluencers = {
      obj: influencers,
      html: influencersHtml
    };

    return associatedInfluencers;
  }

  getAssociatedPosts (user) {
    let postsHtml = '';
    let posts = null;

    if (user.accessLevel && user.accessLevel.associatedPosts) {
      postsHtml += `<div>These are posts that are associated with your account</div>`;
    }

    const associatedPosts = {
      obj: posts,
      html: postsHtml
    };

    return associatedPosts;
  }

  findUserByEmail (email, errorElement, addRoleContainer, addRoleErrorTxt, userData) {
    this.global.firebaseFunctions.getOtherUserByEmail({ email: email }, (result) => {
      
      // TODO - now do add role after showing role rights toggle btn's
      console.log(`success get user for ${email}`);
      console.log(result.customClaims);

      // TODO - show loading until this point

      const isAfterUpdate = !!userData;
      if (isAfterUpdate) {
        if (result.userProviderData && result.userProviderData.length) {
          userData.providerData = result.userProviderData[0];
        }
        userData.accessLevel = result.customClaims.accessLevel || null;
        const currentUser = this.global.getUser();
        const isOtherUser = currentUser && currentUser.userId != userData.userId;
        this.global.modules.Firebase.updateUserInfoOnDatabase(userData.providerData.email, null, true, userData.userId, userData, isOtherUser);
      }

      // if result.data.customClaims is null then need to update the user
      let role = result.customClaims.accessLevel.role;
      role = Object.getOwnPropertyNames(role)[0];
      const rights = result.customClaims.accessLevel.editRights;
      const isInfluencer = result.customClaims.accessLevel.isInfluencer;

      this.openRoleEditing(role, rights, isInfluencer, email, addRoleContainer, addRoleErrorTxt);

    }, (errorMessage) => {
      this.utils.showLoading(false);
      if (errorElement) errorElement.innerHTML = errorMessage;
    });
  };

  openRoleEditing (role, rights, isInfluencer, userEmail, addRoleContainer, addRoleErrorTxt) {
    addRoleContainer.classList.add('hidden');
    const influencerValue = isInfluencer ? ' checked' : '';

    const currentRightsHtml = [];
    for (let prop in rights) {
      currentRightsHtml.push(`
        <div class="profileValueRow currentRightRow">
          <label class="profileValueRowLabel">${this.resources.profile.roleAccess.editRights[prop]}</label>
          <span class="profileValueRowValue">${this.resources.profile.hasRight[rights[prop]]}</span>
        </div>
      `);
    }

    const html = `
      <div class="closeBtn"></div>

      <div class="profile_Item">
        <div class="profileValue userFoundEmail">
          ${userEmail}
        </div>
      </div>

      <div class="roleInfoLabel"><span>${this.resources.profile.info.user.currentRoles}</span></div>

      <div class="currentRights">
        <div class="profile_Item">
          <span class="profileLabel">${this.resources.profile.info.user.currentRole}:&nbsp;</span>
          <div class="profileValue">
            ${this.resources.profile.roles[role]}
          </div>
        </div>
        <div class="profile_Item">
          <span class="profileLabel">${this.resources.profile.info.user.editRights}:&nbsp;</span>
          <div class="profileValue">
            ${currentRightsHtml.join('')}
          </div>
        </div>
      </div>

      <div class="updateRights">
        <div class="roleInfoLabel"><span>${this.resources.profile.info.user.changeRoles}</span></div>
        
        <label class="profileLabel" for="selectAddRoleType">${this.resources.profile.roles.role}:&nbsp;</label>
        <select id="selectAddRoleType" class="profileValue">
          <option id="viewer" value="${this.global.references.Roles.viewer}">${this.resources.profile.roles.viewer}</option>
          <option id="admin" value="${this.global.references.Roles.admin}">${this.resources.profile.roles.admin}</option>
          <option id="editor" value="${this.global.references.Roles.editor}">${this.resources.profile.roles.editor}</option>
        </select>
        
        <div id="defaultRights"></div>

        <div class="profile_Item checkboxSwitchRow">
          <label class="profileLabel" for="checkboxMakeInfulencer">${this.resources.profile.influencer.influencerProfile}:&nbsp;</label>
          <div class="profileValue checkboxSliders">
            <label class="switch">
              <input class="checkboxMakeInfulencer" name="checkboxMakeInfulencer" type="checkbox"${influencerValue}>
              <span class="slider round"></span>
            </label>
          </div>
        </div>

      </div>
      
      <button class="largeButton" type="submit">${this.resources.profile.actions.updateRights}</button>
    `;
    addRoleContainer.innerHTML = html;

    const closeBtn = addRoleContainer.querySelector('.closeBtn');
    this.utils.attachEventListeners('click', this.clearRoleEditing, [closeBtn]);

    const addRoleBtn = addRoleContainer.querySelector('button');
    const selectAddRoleType = document.querySelector('#selectAddRoleType');

    const defaultOption = !!selectAddRoleType.options.namedItem(role) ? role : this.global.references.Roles.viewer;
    selectAddRoleType.options.namedItem(defaultOption).selected = true;
    let selectedRole = defaultOption;

    const defualtRights = this.dataModel.DefualtUserRights();

    let updatedRights = null;

    const getEditRightsHtml = (role) => {
      const relevantRights = defualtRights[role];
      updatedRights = this.utils.cloneObj(relevantRights);

      const arrRights = [];
      for (let prop in relevantRights) {
        arrRights.push(`
          <div class="profileValueRow defaultRightRow">
            <label class="profileValueRowLabel" for="checkboxRoleEditRights">${this.resources.profile.roleAccess.editRights[prop]}</label>
            <div class="profileValueRowValue checkboxSliders">
              <label class="switch">
                <input class="checkboxRoleEditRights" name="${prop}" type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        `);
      }

      const defaultRightsHtml = `
        <div class="profile_Item">
          <span class="profileLabel">${this.resources.profile.info.user.editRights}:&nbsp;</span>
          <div class="profileValue">
            ${arrRights.join('')}
          </div>
        </div>
      `;

      const defaultRights = document.querySelector('#defaultRights');
      defaultRights.innerHTML = defaultRightsHtml;
      addRoleContainer.classList.remove('hidden');

      const rightsCheckboxes = addRoleContainer.querySelectorAll('.checkboxRoleEditRights');
      this.utils.attachEventListeners('change', (element) => {
        const checkboxValue = element.checked;
        console.log(`new value for ${element.getAttribute('name')} is ${checkboxValue}`);
        updatedRights[element.getAttribute('name')] = checkboxValue;
      }, rightsCheckboxes);

      const isInfluencerCheckbox = addRoleContainer.querySelectorAll('.checkboxMakeInfulencer');
      this.utils.attachEventListeners('change', (element) => {
        const checkboxValue = element.checked;
        console.log(`new value for ${element.getAttribute('name')} is ${checkboxValue}`);
        isInfluencer = checkboxValue;
      }, isInfluencerCheckbox);

      this.utils.showLoading(false);
    };

    getEditRightsHtml(selectedRole);

    selectAddRoleType.addEventListener('change', (e) => {
      selectedRole = e.target.value;
      console.log(`new option value is ${selectedRole}`);

      getEditRightsHtml(selectedRole);
    });

    addRoleBtn.addEventListener('click', (e) => {
      e.preventDefault();
      addRoleErrorTxt.innerHTML = '';
      this.utils.showLoading(true);

      console.log('Add role btn - admin name: ' + userEmail);

      // TODO - check if custom claims come back after a user is deleted and then registers again
      
      this.global.utils.getData(this.global.references.DataStructure.users, (users) => {
        this.global.retrievedData.users = users;
        const userId = Object.keys(users).find(key => {
          return users[key].providerData.email === userEmail;
        });

        // TODO - if isInfluencer, also add to user associatedInfluencers
        // TODO - Update "influencers" on database add/remove based on isInfluencer value
        const objInfluencer = {
          groupinfluencerAdmins: {}
        };
        this.global.modules.Firebase.addRemoveInfluencer(objInfluencer, userId, isInfluencer, false);

        const userData = users[userId];
        userData.userId = userId;
        userData.accessLevel.ifcTeamMeber = userData.accessLevel.ifcTeamMeber || null;
        userData.accessLevel.isInfluencer = isInfluencer;
        userData.accessLevel.associatedGroups = userData.accessLevel.associatedGroups || null;
        userData.accessLevel.associatedPosts = userData.accessLevel.associatedPosts || null;
        userData.accessLevel.associatedInfluencers = userData.accessLevel.associatedInfluencers || null;
        userData.accessLevel.associatedPrivatePojects = userData.accessLevel.associatedPrivatePojects || null;
        
        const type = selectAddRoleType.value;
        this.global.firebaseFunctions.addAppRole({
          email: userEmail,
          type: type,
          isNewRegister: false,
          updatedRights: updatedRights,
          userId: userId,
          ifcTeamMeber: userData.accessLevel.ifcTeamMeber,
          isInfluencer: userData.accessLevel.isInfluencer,
          associatedGroups: userData.accessLevel.associatedGroups,
          associatedPosts: userData.accessLevel.associatedPosts,
          associatedInfluencers: userData.accessLevel.associatedInfluencers,
          associatedPrivatePojects: userData.accessLevel.associatedPrivatePojects
        }, (addRoleResult) => {
          addRoleErrorTxt.innerHTML = '';
          // TODO show success message
          // TODO may need to refresh user credentials first ????????????
          // refresh content
          this.findUserByEmail(userEmail, null, addRoleContainer, addRoleErrorTxt, userData);

        }, (errorMessage) => {
          this.utils.showLoading(false);
          addRoleErrorTxt.innerHTML = errorMessage;
        });

      });

    });
  }
}

export { Profile } 