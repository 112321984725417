class AddEditPopup {

  constructor (global) {
    this.name = 'AddEditPopup';
    this.isActive = false;
    this.global = global;
    this.utils = global.utils;
    this.resources = this.global.getResources();
    this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);
    this.addEditPopup = document.querySelector('#addEditPopup');
    this.onCloseCallback = null;
  } 

  init () {
    console.log('AddEditPopup component is loaded');
  }

  eventHandler (eventName) {
    switch (eventName) {
      case this.global.references.Events.newVideoAdded:
      case this.global.references.Events.videoDeleted:
      case this.global.references.Events.groupAddedUpdated:
      case this.global.references.Events.postAddedUpdated:
      case this.global.references.Events.groupDeleted:
      case this.global.references.Events.postDeleted:
      case this.global.references.Events.clearOnLogout:
          // console.log('eventHandler AddEditPopup - group/video added/deleted - closePopup');
          this.closePopup();
        break;
      default:
        break;
    }
  }

  openPopup () {
    this.isActive = true;
    this.addEditPopup.classList.add('show');
    this.utils.closeStickyHeader();
  }

  closePopup () {
    this.isActive = false;
    this.addEditPopup.classList.remove('show');

    const popupContent = this.addEditPopup.querySelector('#addEditContent');
    const popupHeader = this.addEditPopup.querySelector('#addEditHeader');
    popupHeader.innerHTML = '';
    popupContent.innerHTML = '';

    if (this.onCloseCallback && typeof this.onCloseCallback === 'function') this.onCloseCallback();
  }

  onAddEditPost (isEdit, postId, data, createdDate) {
    const that = this;
    return () => {      

      const addGroupOptions = (associatedGroups, addSelected, postedByGroup_id, isPostsEditor) => {
        if (isPostsEditor) {
          associatedGroups = that.global.retrievedData.groups;
        }

        let groupName = '';
        let optionsHtml = '';
        const attrSelected = addSelected ? ' selected' : '';
        let selected = '';
        let isFirst = true;
        let postingBy = null;
        let group = null;
        const getImg = (id, imgName) => { return that.global.config.firebase.storage.url.groupLogo.get.call(that.global.config.firebase.storage.url, id, imgName) };
        for (let prop in associatedGroups) {
          selected = postedByGroup_id ? (prop == postedByGroup_id ? attrSelected : '') : (isFirst ? attrSelected : '');

          group = that.global.retrievedData.groups[prop];
          if (selected) { postingBy = group}
          groupName = group.lang[that.uiLang].title;
          optionsHtml += `
            <option data-postedBy="postedByGroup" data-id="${prop}" data-title="${group.lang[that.uiLang].title}" data-image="${getImg(group.groupId, group.logo)}" value="${groupName}"${selected}>
              ${groupName}
            </option>
          `;
          isFirst = false;
        }
        
        return {
          optionsHtml: optionsHtml,
          postingBy: {
            title: postingBy ? postingBy.lang[that.uiLang].title : null,
            profileImage: postingBy ? getImg(postingBy.groupId, postingBy.logo) : null
          }
        };
      };
      
      const addInfluencerOptions = (user, associatedInfluencers, addSelected, postedByInfluencer_id, isPostsEditor) => {
        if (isPostsEditor) {
          let objUser = null;
          const filteredUsers = {}
          for (let prop in that.global.retrievedData.users) {
            objUser = that.global.retrievedData.users[prop]
            if (objUser.accessLevel.isInfluencer) {
              filteredUsers[prop] = objUser;
            }
          }
          associatedInfluencers = filteredUsers;
        }
        else if (user.accessLevel.isInfluencer) {
          associatedInfluencers = associatedInfluencers || {};
          associatedInfluencers[user.userId] = that.global.retrievedData.users[user.userId];
        }
        
        let userName = '';
        let optionsHtml = '';
        const attrSelected = addSelected ? ' selected' : '';
        let selected = '';
        let isFirst = true;
        let postingBy = null;
        let influencer = null;
        let profileImage = 'images/icons/profileImage-default.png';
        const getImg = (id, imgName) => { return that.global.config.firebase.storage.url.profilePic.get.call(that.global.config.firebase.storage.url, id, imgName) };
        for (let prop in associatedInfluencers) {
          selected = postedByInfluencer_id ? (prop == postedByInfluencer_id ? attrSelected : '') : (isFirst ? attrSelected : '');

          profileImage = 'images/icons/profileImage-default.png';
          influencer = that.global.retrievedData.users[prop];
          if (influencer.providerData.photoURL) {
            profileImage = getImg(influencer.userId, influencer.providerData.photoURL);
          }

          if (selected) { postingBy = influencer}
          userName = influencer.providerData.displayName ? influencer.providerData.displayName : influencer.providerData.email;
          optionsHtml += `
            <option data-postedBy="postedByInfluencer" data-id="${prop}" data-title="${userName}" data-image="${profileImage}" value="${userName}"${selected}>
              ${userName}
            </option>
          `;
          isFirst = false;
        }
        
        return {
          optionsHtml: optionsHtml,
          postingBy: {
            title: postingBy ? (postingBy.providerData.displayName ? postingBy.providerData.displayName : postingBy.providerData.email) : null,
            profileImage: postingBy ? (postingBy.providerData.photoURL ? getImg(postingBy.userId, postingBy.providerData.photoURL) : 'images/icons/profileImage-default.png') : null
          }
        };
      };

      const user = that.global.getUser();
      let postingAsOptions = ``;
      let postedByUserData = null;
      let postedByGroupData = null;
      let postedByTitle = '';
      let postedByProfileImage = '';

      const userId = user && user.userId ? user.userId : '';
      const postedByUserId = !isEdit ? userId : data.postedByUser;

      const postedByGroup_id = !isEdit ? null : data.postedByGroup;
      const postedByInfluencer_id = !isEdit ? null : data.postedByInfluencer;
      const isGroupPost = !isEdit ? false : !!data.postedByGroup;
      const isInfluencerPost = !isEdit ? false : !!data.postedByInfluencer;

      let originallyPostedBy = '';
      if (isEdit) {
        const users = that.global.retrievedData.users;
        if (data.postedByUser && users[data.postedByUser] && users[data.postedByUser].providerData) {
          const originalUser = users[data.postedByUser];
          originallyPostedBy = `
            <div class="postedBy">
              <span class="postedByLabel">${that.resources.posts.originallyPostedBy}</span>
              <span>${originalUser.providerData.displayName ? originalUser.providerData.displayName : originalUser.providerData.email}</span>
            </div>
          `;
        }
      }

      // TODO -- @@@ NEED @@@ associatedInfluencers for those that can edit other users post !!!!!!!!!!!!!!!!!!!!!!!!!!!!
      
      // TODO add to popup, non editable (origianlly posted by user and group/influencer)
      // TODO if is edit, make postedBy static with button option to edit (so not so easy to change or get confused about it)
      const isPostsEditor = (user.accessLevel && user.accessLevel.editRights && user.accessLevel.editRights.editPosts);
      if (isPostsEditor || (user.accessLevel && user.accessLevel.associatedGroups && (user.accessLevel.isInfluencer || user.accessLevel.associatedInfluencers))) {
        const addSelectedInfluencer = !isEdit ? false : isInfluencerPost;
        const addSelectedGroup = !isEdit ? true : isGroupPost;
        postedByUserData = addInfluencerOptions(user, user.accessLevel.associatedInfluencers, addSelectedInfluencer, postedByInfluencer_id, isPostsEditor);
        postedByGroupData = addGroupOptions(user.accessLevel.associatedGroups, addSelectedGroup, postedByGroup_id, isPostsEditor);
        postingAsOptions += postedByUserData.optionsHtml;
        postingAsOptions += postedByGroupData.optionsHtml;
        const postedByData = postedByGroupData.postingBy.title ? postedByGroupData : postedByUserData;
        postedByTitle = postedByData.postingBy.title ;
        postedByProfileImage = postedByData.postingBy.profileImage;
      }
      else if (user.accessLevel && user.accessLevel.associatedGroups) {
        postedByGroupData = addGroupOptions(user.accessLevel.associatedGroups, true, postedByGroup_id);
        postingAsOptions += postedByGroupData.optionsHtml;
        postedByTitle = postedByGroupData.postingBy.title;
        postedByProfileImage = postedByGroupData.postingBy.profileImage;
      }
      else if (user.accessLevel && user.accessLevel.isInfluencer || user.accessLevel.associatedInfluencers) {
        postedByUserData = addInfluencerOptions(user, user.accessLevel.associatedInfluencers, true, postedByInfluencer_id);
        postingAsOptions += postedByUserData.optionsHtml;
        postedByTitle = postedByUserData.postingBy.title;
        postedByProfileImage = postedByUserData.postingBy.profileImage;
      }

      const inputPostTitleEng = !isEdit ? '' : data?.lang?.eng?.title ?? '';
      const inputPostTitleHeb = !isEdit ? '' : data?.lang?.heb?.title ?? '';

      const inputPostTextEng = !isEdit ? that.resources.posts.lang.eng.newPostText : (data?.lang?.eng?.text ?? '') === '' ? that.resources.posts.lang.eng.newPostText : data.lang.eng.text;
      const inputPostTextHeb = !isEdit ? that.resources.posts.lang.heb.newPostText : (data?.lang?.heb?.text ?? '') === '' ? that.resources.posts.lang.heb.newPostText: data.lang.heb.text;

      const inputImageLink = !isEdit ? '' : data?.media?.imageLink ?? '';
      
      const value_inputYouTubeEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.youtube ?? '';
      const value_inputFacebookEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.fb ?? '';
      const value_inputInstaEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.insta ?? '';
      const value_inputTiktokEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.tiktok ?? '';
      const value_inputTwitterEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.twitter ?? '';
      const value_inputSpotifyEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.spotify ?? '';

      const value_inputSocialLinks_FB = !isEdit ? '' : data?.socialLinks?.fb ?? '';
      const value_inputSocialLinks_Insta = !isEdit ? '' : data?.socialLinks?.insta ?? '';
      const value_inputSocialLinks_Tiktok = !isEdit ? '' : data?.socialLinks?.tiktok ?? '';
      const value_inputSocialLinks_Twitter = !isEdit ? '' : data?.socialLinks?.twitter ?? '';
      const value_inputSocialLinks_LinkedIn = !isEdit ? '' : data?.socialLinks?.linkedIn ?? '';
      const value_inputSocialLinks_YouTube = !isEdit ? '' : data?.socialLinks?.youTube ?? '';
      const value_inputSocialLinks_Spotify = !isEdit ? '' : data?.socialLinks?.spotify ?? '';
      const value_inputSocialLinks_Web = !isEdit ? '' : data?.socialLinks?.website ?? '';

      // const userId = user && user.userId ? user.userId : '';
      // const postedByUserId = !isEdit ? userId : data.postedByUser;
      
      const imageName = !isEdit ? '' : data.media.image;
      const imageUrl =  !isEdit || !data.media.image ? '' : that.global.config.firebase.storage.url.postImage.get.call(that.global.config.firebase.storage.url, postId, imageName);
      const postImageClass = !isEdit || !data.media.image ? 'class="hidden"' : '';
      const imageUpladPreview = `<div id="imageUpladPreview" ${postImageClass}><img src="${imageUrl}"></div>`;
      
      const value_checkbox_isHidden = !isEdit ? false : data?.isHidden ?? false;
      const isChecked_hidden = value_checkbox_isHidden ? 'checked' : '';

      const buttonText = !isEdit ? that.resources.addEditPopup.buttonTextAddPost : that.resources.addEditPopup.buttonTextEditPost;
      const deletePostBtn = !isEdit ? '' : `<div id="addEditDelete"><span id="addEditDeleteBtn">${that.resources.addEditPopup.buttonTextDeletePost}</span></div>`;

      const objInputsEmbedMedia = { value_inputYouTubeEmbedLink, value_inputFacebookEmbedLink, value_inputInstaEmbedLink, value_inputTiktokEmbedLink, value_inputTwitterEmbedLink, value_inputSpotifyEmbedLink };
      const objInputsSocialLinks = {
          fb: value_inputSocialLinks_FB,
          insta: value_inputSocialLinks_Insta,
          tiktok: value_inputSocialLinks_Tiktok,
          twitter: value_inputSocialLinks_Twitter,
          linkedin: value_inputSocialLinks_LinkedIn,
          youtube: value_inputSocialLinks_YouTube,
          spotify: value_inputSocialLinks_Spotify,
          web: value_inputSocialLinks_Web
      }

      // TODO - Bug when there is quotes around the value for inputPostTitleEng then the double qoutes cause the text not to show up in edit popup

      let postTextEng = `
        <label class="addEditLabel eng">${that.resources.addEditPopup.lang.eng.eng}</label>
        <div class="addEditPostTitleContainer eng">
          <div class="addEditPostTitle">
            <label class="addEditLabel" for="inputPostTitleEng">${that.resources.addEditPopup.lang.eng.postTitle}</label>
            <input type="text" id="inputPostTitleEng" class="formInput" name="inputPostTitleEng" value="${inputPostTitleEng}" maxlength="100" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.postTitle}</span>
          </div>
        </div>
        <textarea id="inputPostTextEng" class="formInput" name="inputPostTextEng" rows="4">${inputPostTextEng}</textarea>
      `;
      let postTextHeb = `
        <label class="addEditLabel heb">${that.resources.addEditPopup.lang.heb.heb}</label>
        <div class="addEditPostTitleContainer heb">
          <div class="addEditPostTitle">
            <label class="addEditLabel" for="inputPostTitleHeb">${that.resources.addEditPopup.lang.heb.postTitle}</label>
            <input type="text" id="inputPostTitleHeb" class="formInput" name="inputPostTitleHeb" value="${inputPostTitleHeb}" maxlength="100" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.postTitle}</span>
          </div>
        </div>
        <textarea id="inputPostTextHeb" class="formInput" name="inputPostTextHeb" rows="4">${inputPostTextHeb}</textarea>
      `;

      let postText = '';
      if (that.uiLang == 'heb') {
        postText = postTextHeb + postTextEng;
      }
      else {
        postText = postTextEng + postTextHeb;
      }

      const header = isEdit ? that.resources.addEditPopup.header.editPost : that.resources.addEditPopup.header.addPost;
      const content = `
        <div class="postHeader">
          <div class="postHeaderLogo" style="background-image: url(${postedByProfileImage})"></div>
          <div class="postHeaderInfo">
            <div class="postHeaderInfoTitle">${postedByTitle}</div>
            <div class="postingAsOptions">
              <span class="postingAsOptionsTitle">${that.resources.addEditPopup.postingAs}</span>
              <select class="postingAsOptionsSelect">${postingAsOptions}<select>
            </div>
            ${originallyPostedBy}
          </div>
        </div>

        <div class="addEditItem addEditPostText">${postText}</div>

        <div class="addEditMediaContainer">
          <div class="addEditMediaOptions">
            <div class="addEditMediaItem image">
              <span class="addEditMediaIcon"></span>
              <span>${that.resources.addEditPopup.item.iconUploadImage}</span>
            </div>
            <div class="addEditMediaItem video">
              <span class="addEditMediaIcon"></span>
              <span>${that.resources.addEditPopup.item.iconUploadVideo}</span>
            </div>
          </div>

          <div class="addEditItem imageUpload addEditPostImage hidden">
            <span class="addEditLabel">${that.resources.addEditPopup.item.postImage}</span>
            <div id="addEditPostImage">
              ${imageUpladPreview}
              <input type="file" id="inputImageUpload" class="formInput" onchange="document.querySelector('#imageUpladPreview').classList.add('hidden')" name="inputImageUpload" required>
            </div>
            <div class="addEditItemHint">
              <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
              <span class="hintExample">${that.resources.addEditPopup.hint.postImageSize}</span>
            </div>
            <div id="addEditPostImageLink">
              <span class="addEditPostImageLabel">${that.resources.addEditPopup.item.postImageLink}</span>
              <input type="text" id="inputImageLink" class="formInput" name="inputImageLink" value="${inputImageLink}">
            </div>
            <div class="addEditItemHint">
              <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
              <span class="hintExample">${that.resources.addEditPopup.hint.postImageLink}</span>
            </div>
          </div>
          ${this.utils.getEmbeds(objInputsEmbedMedia, that.resources, true)}
        </div>

        ${that.utils.getInputsSocialLinks('post', objInputsSocialLinks, that.resources)}

        <div class="addEditItem isHiddenRow showhidePost">
          <label class="addEditLabel" for="isHiddenPost">${that.resources.addEditPopup.item.isHiddenPost}</label>
          <div class="isHiddenRowValue checkboxSliders">
            <label class="switch">
              <input id="checkbox_isHidden" name="isHiddenPost" type="checkbox" ${isChecked_hidden}>
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="errorTxt"></div>
        <div id="addEditPopupButtons" class="addEditItem">
          <button id="addEditSubmitBtn" class="largeButton" type="submit">${buttonText}</button>
          ${deletePostBtn}
        </div>
      `;
      that.openAddEditPopup(header, content, that.onOpenPostPopup, that.onSubmitAddEditPost, that.onSubmitDeletePost, isEdit, postId, imageName, createdDate, { postedByUserId: postedByUserId }, null);
    };
  }

  onOpenPostPopup  (popupContent, data, isEdit, global) {
    const postHeaderLogo = popupContent.querySelector('.postHeaderLogo');
    const postHeaderInfoTitle = popupContent.querySelector('.postHeaderInfoTitle');
    const postingAsOptionsSelect = popupContent.querySelector('.postingAsOptionsSelect');

    const addEditMediaMenuItems = popupContent.querySelectorAll('.addEditMediaItem');
    const addEditEmbedMenuItems = popupContent.querySelectorAll('.videoUploadEmbedItem');

    const videoUploadContainer = popupContent.querySelector('#videoUploadContainer');
    const inputYouTubeEmbedLink = popupContent.querySelector('#inputYouTubeEmbedLink');
    const inputFacebookEmbedLink = popupContent.querySelector('#inputFacebookEmbedLink');
    const inputInstaEmbedLink = popupContent.querySelector('#inputInstaEmbedLink');
    const inputTiktokEmbedLink = popupContent.querySelector('#inputTiktokEmbedLink');
    const inputTwitterEmbedLink = popupContent.querySelector('#inputTwitterEmbedLink');
    const inputSpotifyEmbedLink = popupContent.querySelector('#inputSpotifyEmbedLink');

    const embedInputs = { youtube: inputYouTubeEmbedLink, fb: inputFacebookEmbedLink, insta: inputInstaEmbedLink, tiktok: inputTiktokEmbedLink, twitter: inputTwitterEmbedLink, spotify: inputSpotifyEmbedLink };

    global.utils.initShowHideEmbeds(embedInputs, addEditEmbedMenuItems);
    
    const addEditPostImage = popupContent.querySelector('.addEditPostImage');
    const videoIcon = popupContent.querySelector('.addEditMediaItem.video');
    const imageIcon = popupContent.querySelector('.addEditMediaItem.image');

    const showHideMedia = (element) => {
      if (element.classList.contains('image')) {
        videoIcon.classList.remove('selected');
        element.classList.add('selected');
        videoUploadContainer.classList.add('hidden');
        // addEditVideoLink_YouTube.classList.add('hidden');
        addEditPostImage.classList.remove('hidden');
      }
      else if (element.classList.contains('video')) {
        imageIcon.classList.remove('selected');
        element.classList.add('selected');
        addEditPostImage.classList.add('hidden');
        videoUploadContainer.classList.remove('hidden');
        // addEditVideoLink_YouTube.classList.remove('hidden');
      }
    };
    global.utils.attachEventListeners('click', (element) => {
      showHideMedia(element);
    }, addEditMediaMenuItems);
    if (isEdit) {
      const hasPhoto = !addEditPostImage.querySelector('#imageUpladPreview').classList.contains('hidden');
      const hasVideo = Object.values(embedInputs).some(input => input.value !== '');

      const element = hasPhoto ? imageIcon : (hasVideo ? videoIcon : null);
      if (element) {
        showHideMedia(element);
      }
    }

    global.utils.attachEventListeners('change', (element) => {
      const selectedOption = element.options[element.selectedIndex];
      const selectedValue = selectedOption.value;
      console.log(`new value for posting as is ${selectedOption.text}`);

      const title = selectedOption.getAttribute('data-title');
      const profileImage = selectedOption.getAttribute('data-image');
      
      postHeaderLogo.style.backgroundImage = `url(${profileImage})`;
      postHeaderInfoTitle.innerHTML = title;
    }, [postingAsOptionsSelect]);

    const inputPostTextEng = popupContent.querySelector('#inputPostTextEng');
    const inputPostTextHeb = popupContent.querySelector('#inputPostTextHeb');

    const reduceFontSize = (element) => {
      const maxCharsPerRow = Math.ceil(element.offsetWidth / 12);
      const chars = element.value.length;
      const charRows = Math.floor(chars / maxCharsPerRow) + 1;
      const enterRows = element.value.split("\n").length - 1;
      const rows = enterRows + charRows;
      switch (true) {
        case rows < 2:
          element.classList.remove('rows2', 'rows3', 'rows4', 'rows5', 'rows6');
          break;
        case rows == 2:
          element.classList.remove('rows3', 'rows4', 'rows5', 'rows6');
          element.classList.add('rows2');
          break;
        case rows == 3:
          element.classList.remove('rows2', 'rows4', 'rows5', 'rows6');
          element.classList.add('rows3');
          break;
        case rows == 4:
          element.classList.remove('rows2', 'rows3', 'rows5', 'rows6');
          element.classList.add('rows4');
          break;
        case rows == 5:
          element.classList.remove('rows2', 'rows3', 'rows4', 'rows6');
          element.classList.add('rows5');
          break;
        case rows > 5:
          element.classList.remove('rows2', 'rows3', 'rows4', 'rows5');
          element.classList.add('rows6');
          break;
          
      }
    };
    global.utils.attachEventListeners('keyup', (element) => {
      reduceFontSize(element);
    }, [inputPostTextEng, inputPostTextHeb]);
    
    reduceFontSize(inputPostTextEng);
    reduceFontSize(inputPostTextHeb);

    global.utils.attachEventListeners('focus', (element, evt) => {
      console.log(element.value);
      let defaultText = '';
      switch (element.getAttribute('id')) {
        case 'inputPostTextEng':
          defaultText = global.resources.posts.lang.eng.newPostText;
          break;
        case 'inputPostTextHeb':
          defaultText = global.resources.posts.lang.heb.newPostText;
          break;
      }
      if (element.value === defaultText) {
        element.value = '';
      }
      element.classList.add('focused');

    }, [inputPostTextEng, inputPostTextHeb]);

    global.utils.attachEventListeners('blur', (element, evt) => {
      let defaultText = '';
      switch (element.getAttribute('id')) {
        case 'inputPostTextEng':
          defaultText = global.resources.posts.lang.eng.newPostText;
          break;
        case 'inputPostTextHeb':
          defaultText = global.resources.posts.lang.heb.newPostText;
          break;
      }
      if (element.value === '') {
        element.value = defaultText;
      }
      element.scrollTop = 0;
      element.classList.remove('focused');
    }, [inputPostTextEng, inputPostTextHeb]);
  }
  
  onSubmitAddEditPost  (isEdit, postId, imageName, createdDate, popupContent, data, global) {
    return () => {
      const addEditPopupButtons = popupContent.querySelector('#addEditPopupButtons');
      const submitBtnDisabled = addEditPopupButtons.classList.contains('disabled');
      if (submitBtnDisabled) return;

      addEditPopupButtons.classList.add('disabled');

      // TODO - check if in textarea inputGroupDesc*, html should be allow (check security - injections)
      // also check security for regular inputs

      const inputPostTitleEng = popupContent.querySelector('#inputPostTitleEng');
      const inputPostTitleHeb = popupContent.querySelector('#inputPostTitleHeb');
      const inputPostTextEng = popupContent.querySelector('#inputPostTextEng');
      const inputPostTextHeb = popupContent.querySelector('#inputPostTextHeb');

      const inputYouTubeEmbedLink = popupContent.querySelector('#inputYouTubeEmbedLink');
      const inputFacebookEmbedLink = popupContent.querySelector('#inputFacebookEmbedLink');
      const inputInstaEmbedLink = popupContent.querySelector('#inputInstaEmbedLink');
      const inputTiktokEmbedLink = popupContent.querySelector('#inputTiktokEmbedLink');
      const inputTwitterEmbedLink = popupContent.querySelector('#inputTwitterEmbedLink');
      const inputSpotifyEmbedLink = popupContent.querySelector('#inputSpotifyEmbedLink');

      const embedInputs = { youtube: inputYouTubeEmbedLink, fb: inputFacebookEmbedLink, insta: inputInstaEmbedLink, tiktok: inputTiktokEmbedLink, twitter: inputTwitterEmbedLink, spotify: inputSpotifyEmbedLink };

      const inputImageUpload = popupContent.querySelector('#inputImageUpload');
      const inputImageLink = popupContent.querySelector('#inputImageLink');

      // Social Links
      const inputSocialLinks_fb = popupContent.querySelector('#inputSocialLinks_fb').querySelector('input');
      const inputSocialLinks_insta = popupContent.querySelector('#inputSocialLinks_insta').querySelector('input');
      const inputSocialLinks_tiktok = popupContent.querySelector('#inputSocialLinks_tiktok').querySelector('input');
      const inputSocialLinks_twitter = popupContent.querySelector('#inputSocialLinks_twitter').querySelector('input');
      const inputSocialLinks_linkedin = popupContent.querySelector('#inputSocialLinks_linkedin').querySelector('input');
      const inputSocialLinks_youtube = popupContent.querySelector('#inputSocialLinks_youtube').querySelector('input');
      const inputSocialLinks_spotify = popupContent.querySelector('#inputSocialLinks_spotify').querySelector('input');
      const inputSocialLinks_web = popupContent.querySelector('#inputSocialLinks_web').querySelector('input');

      const selectPostedBy = popupContent.querySelector('.postingAsOptionsSelect');
      const selectedOption = selectPostedBy.options[selectPostedBy.selectedIndex];
      const postedByType = selectedOption.getAttribute('data-postedBy');
      const postedById = selectedOption.getAttribute('data-id');
      // const user = that.global.getUser();
      // const postedByUserId = user && user.userId ? user.userId : '';
      const objPostedBy = {};

      if (postedByType === 'postedByInfluencer') {
        objPostedBy['postedByInfluencer'] = postedById;
        objPostedBy['postedByGroup'] = null;
      }
      else if (postedByType === 'postedByGroup') {
        objPostedBy['postedByGroup'] = postedById;
        objPostedBy['postedByInfluencer'] = null;
      }
      
      objPostedBy['postedByUser'] = data.postedByUserId;

      const checkbox_isHidden = popupContent.querySelector('#checkbox_isHidden');
      const keepOriginalPhoto = isEdit && !document.querySelector('#imageUpladPreview').classList.contains('hidden');

      // Check form empty
      const hasVideo = Object.values(embedInputs).some(input => input.value !== '');
      let hasError = false;
      let elFirstError = null;
      const hasDefaultTxtEng = inputPostTextEng.value === global.resources.posts.lang.eng.newPostText;
      const hasDefaultTxtHeb = inputPostTextHeb.value === global.resources.posts.lang.heb.newPostText;
      const uiLang = global.config.uiLang.get.call(global.config.uiLang)
      const firstTextArea = uiLang === 'heb' ? inputPostTextHeb : inputPostTextEng;
      const element = firstTextArea;
      if (!keepOriginalPhoto && !inputImageUpload.files.length && !hasVideo && !inputPostTitleEng.value && !inputPostTitleHeb.value && hasDefaultTxtEng && hasDefaultTxtHeb) {
        if (!hasError) { elFirstError = element; }
        element.classList.add('err');
        hasError = true;
      }

      if (hasError) { 
        // Only need any one of these required feilds to post successfully (remove error if focusing on any one)
        const requiredInputs = [inputPostTitleEng, inputPostTitleHeb, inputPostTextEng, inputPostTextHeb, inputYouTubeEmbedLink, inputFacebookEmbedLink, inputInstaEmbedLink, inputTiktokEmbedLink, inputTwitterEmbedLink, inputSpotifyEmbedLink, inputImageUpload]
        const doOnFocus = (el) => {
          firstTextArea.classList.remove('err');
        };
        global.utils.attachEventListeners('focus', doOnFocus, requiredInputs);

        const elem = elFirstError.parentNode.classList.contains('addEditItem') ? elFirstError.parentNode : elFirstError.parentNode.parentNode;
        elem.scrollIntoView();
        addEditPopupButtons.classList.remove('disabled');
        return;
      }
      
      // Validage Image Field
      const hundredKb = (1024 * 1024) / 10;
      const maxSize = hundredKb * 2;
      if ((!keepOriginalPhoto && inputImageUpload.files.length) &&
        (inputImageUpload.files[0].size > maxSize || !inputImageUpload.files[0].name.match(/.(jpe?g|png)$/i))) {
        inputImageUpload.classList.add('err');

        // open upload image link
        const imageIcon = popupContent.querySelector('.addEditMediaItem.image');
        const videoIcon = popupContent.querySelector('.addEditMediaItem.video');
        const videoUploadContainer = popupContent.querySelector('#videoUploadContainer');
        const addEditVideoLink_YouTube = popupContent.querySelector('.addEditVideoLink_YouTube');
        const addEditPostImage = popupContent.querySelector('.addEditPostImage');
        videoIcon.classList.remove('selected');
        imageIcon.classList.add('selected');
        videoUploadContainer.classList.add('hidden');
        addEditVideoLink_YouTube.classList.add('hidden');
        addEditPostImage.classList.remove('hidden');

        const doOnFocus = (el) => {
          el.classList.remove('err');
        };
        global.utils.attachEventListeners('focus', doOnFocus, [inputImageUpload]);
        imageIcon.scrollIntoView();
        addEditPopupButtons.classList.remove('disabled');
        return;
      }

      // Clear empty field from value of default text
      if (hasDefaultTxtEng) inputPostTextEng.value = '';
      if (hasDefaultTxtHeb) inputPostTextHeb.value = '';

      // Allow user to input <br> html but replace with "return"
      const regex_br = /<br\s*[\/]?>/gi;
      inputPostTextEng.value = inputPostTextEng.value.replace(regex_br, '\n');
      inputPostTextHeb.value = inputPostTextHeb.value.replace(regex_br, '\n');

      // Post Photo
      let imageFile = null;
      let photoName = imageName;
      if (!keepOriginalPhoto && inputImageUpload.files.length) {
        imageFile = inputImageUpload.files[0];
        const originalName = imageFile.name;
        const extension = originalName.substring(originalName.lastIndexOf('.'));
        const timestamp = new Date().getTime();
        photoName = 'logo' + timestamp + extension;
      }

      // Embed Link Values
      // inputYouTubeEmbedLink, inputFacebookEmbedLink, inputInstaEmbedLink, inputTiktokEmbedLink, inputTwitterEmbedLink
      // const hasVideo = Object.values(embedInputs).some(input => input.value !== '');
      // hasVideo

      createdDate = !isEdit ? (new Date()).getTime() : (createdDate || (new Date()).getTime()); 

      const postData = { objPostedBy, checkbox_isHidden, photoName, inputImageLink, embedInputs, inputSpotifyEmbedLink, inputPostTitleEng, inputPostTitleHeb, inputPostTextEng, inputPostTextHeb, inputSocialLinks_fb, inputSocialLinks_tiktok, inputSocialLinks_web, inputSocialLinks_spotify, inputSocialLinks_insta, inputSocialLinks_youtube, inputSocialLinks_linkedin, inputSocialLinks_twitter };
      global.modules.Firebase.addEditPost(postData, imageFile, photoName, keepOriginalPhoto, isEdit, postId, createdDate, false);
      setTimeout(() => { addEditPopupButtons.classList.remove('disabled'); }, 200);
    };
  }

  onAddEditGroup (isEdit, groupId, data, createdDate) {
    const that = this;
    return () => {
      // values
      const inputAcronym = !isEdit ? '' : data?.acronym ?? '';
      const inputGroupNameEng = !isEdit ? '' : data?.lang?.eng?.title ?? '';
      const inputGroupNameHeb = !isEdit ? '' : data?.lang?.heb?.title ?? '';
      const inputGroupDescEng = !isEdit ? '' : data?.lang?.eng?.info ?? '';
      const inputGroupDescHeb = !isEdit ? '' : data?.lang?.heb?.info ?? '';
      
      const value_inputSocialLinks_FB = !isEdit ? '' : data?.socialLinks?.fb ?? '';
      const value_inputSocialLinks_Insta = !isEdit ? '' : data?.socialLinks?.insta ?? '';
      const value_inputSocialLinks_Tiktok = !isEdit ? '' : data?.socialLinks?.tiktok ?? '';
      const value_inputSocialLinks_Twitter = !isEdit ? '' : data?.socialLinks?.twitter ?? '';
      const value_inputSocialLinks_LinkedIn = !isEdit ? '' : data?.socialLinks?.linkedIn ?? '';
      const value_inputSocialLinks_YouTube = !isEdit ? '' : data?.socialLinks?.youtube ?? '';
      const value_inputSocialLinks_Spotify = !isEdit ? '' : data?.socialLinks?.spotify ?? '';
      const value_inputSocialLinks_Web = !isEdit ? '' : data?.socialLinks?.website ?? '';
      const value_inputSocialLinks_LinkTree = !isEdit ? '' : data?.socialLinks?.linktree ?? '';
      const value_inputSocialLinks_Email = !isEdit ? '' : data?.socialLinks?.email ?? '';
      const value_inputSocialLinks_Phone = !isEdit ? '' : data?.socialLinks?.phone ?? '';
      const value_inputSocialLinks_Donate = !isEdit ? '' : data?.socialLinks?.donate ?? '';

      const value_checkbox_isHidden = !isEdit ? false : data?.isHidden ?? false;
      const isChecked_hidden = value_checkbox_isHidden ? 'checked' : '';

      const imageName = !isEdit ? '' : data.logo;
      const imageUrl =  !isEdit ? '' : that.global.config.firebase.storage.url.groupLogo.get.call(that.global.config.firebase.storage.url, groupId, imageName);
      const groupLogoClass = !isEdit ? 'class="hidden"' : '';
      const imageUpladPreview = `<div id="imageUpladPreview" ${groupLogoClass}><img src="${imageUrl}"></div>`;

      const deleteGroupBtn = !isEdit ? '' : `<div id="addEditDelete"><span id="addEditDeleteBtn">${that.resources.addEditPopup.buttonTextDeleteGroup}</span></div>`;
      const buttonText = !isEdit ? that.resources.addEditPopup.buttonTextAddGroup : that.resources.addEditPopup.buttonTextEditGroup;

      const groupAdmins = !isEdit ? null : data.groupAdmins || null;
      let adminsClass = 'class="hidden"';
      const htmlAdmins = [];
      if (groupAdmins) {
        adminsClass = '';
        // TODO - change email below to display name if exists
        for (let prop in groupAdmins) {
          htmlAdmins.push(`<div data-id="${prop}" class="divGroupAdminAdded"><span>${groupAdmins[prop].email}</span><span class="clickToRemoveAdmin"></span></div>`);
        }
      }
      
      const objInputsSocialLinks = {
        fb: value_inputSocialLinks_FB,
        insta: value_inputSocialLinks_Insta,
        tiktok: value_inputSocialLinks_Tiktok,
        twitter: value_inputSocialLinks_Twitter,
        linkedin: value_inputSocialLinks_LinkedIn,
        youtube: value_inputSocialLinks_YouTube,
        spotify: value_inputSocialLinks_Spotify,
        web: value_inputSocialLinks_Web,
        linktree: value_inputSocialLinks_LinkTree,
        email: value_inputSocialLinks_Email,
        phone: value_inputSocialLinks_Phone,
        donate: value_inputSocialLinks_Donate
    }
      const header = isEdit ? that.resources.addEditPopup.header.editGroup : that.resources.addEditPopup.header.addGroup;
      const content = `
        <div class="addEditItem">
          <label class="addEditLabel" for="inputAcronym">${that.resources.addEditPopup.item.acronym}</label>
          <input type="text" id="inputAcronym" class="formInput" name="inputAcronym" value="${inputAcronym}" maxlength="10" required>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.groupAcronym}</span>
          </div>
        </div>
        <div class="addEditItem">
          <span class="addEditLabel">${that.resources.addEditPopup.item.fullName}</span>
          <div class="inputsByLang eng">
            <label for="inputGroupNameEng">${that.resources.addEditPopup.lang.eng.eng}</label>
            <input type="text" id="inputGroupNameEng" class="formInput" name="inputGroupNameEng" value="${inputGroupNameEng}" maxlength="100" required>
          </div>
          <div class="inputsByLang heb">
            <label for="inputGroupNameHeb">${that.resources.addEditPopup.lang.heb.heb}</label>
            <input type="text" id="inputGroupNameHeb" class="formInput" name="inputGroupNameHeb" value="${inputGroupNameHeb}" maxlength="100" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.groupFullName}</span>
          </div>
        </div>
        <div class="addEditItem">
          <span class="addEditLabel">${that.resources.addEditPopup.item.description}</span>
          <div class="inputsByLang eng">
            <label for="inputGroupDescEng">${that.resources.addEditPopup.lang.eng.eng}</label>
            <textarea id="inputGroupDescEng" class="formInput" name="inputGroupDescEng" rows="4">${inputGroupDescEng}</textarea>
          </div>
          <div class="inputsByLang heb">
            <label for="inputGroupDescHeb">${that.resources.addEditPopup.lang.heb.heb}</label>
            <textarea id="inputGroupDescHeb" class="formInput" name="inputGroupDescHeb" rows="4">${inputGroupDescHeb}</textarea>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.groupDesc}</span>
          </div>
        </div>
        <div class="addEditItem imageUpload addEditGroupLogo">
          <span class="addEditLabel">${that.resources.addEditPopup.item.groupLogo}</span>
          <div id="addEditGroupLogo">
            ${imageUpladPreview}
            <input type="file" id="inputImageUpload" class="formInput" onchange="document.querySelector('#imageUpladPreview').classList.add('hidden')" name="inputImageUpload" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.logoImageSize}</span>
          </div>
        </div>

        ${that.utils.getInputsSocialLinks('group', objInputsSocialLinks, that.resources)}

        <div class="addEditItem addGroupAdmins">
          <label class="addEditLabel" for="inputAddGroupAdmins">${that.resources.addEditPopup.item.groupAddAdmin}</label>
          <div id="groupAdminsAdded" ${adminsClass}>${htmlAdmins.join('')}</div>
          <div class="addAdminField">
            <div class="containerInputAddGroupAdmins">
              <input type="text" id="inputAddGroupAdmins" class="formInput" name="inputAddGroupAdmins" maxlength="50">
            </div>
            <button id="btnAddGroupAdmins" type="submit">${that.resources.profile.actions.add}</button>
          </div>
          <div id="errTextAddGroupAdmin"></div>

          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.groupAdminEmail}</span>
          </div>
        </div>

        <div class="addEditItem isHiddenRow">
          <label class="addEditLabel" for="isHiddenGroup">${that.resources.addEditPopup.item.isHiddenGroup}</label>
          <div class="isHiddenRowValue checkboxSliders">
            <label class="switch">
              <input id="checkbox_isHidden" name="isHiddenGroup" type="checkbox" ${isChecked_hidden}>
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div id="addEditPopupButtons" class="addEditItem">
          <button id="addEditSubmitBtn" class="largeButton" type="submit">${buttonText}</button>
          ${deleteGroupBtn}
        </div>
      `;

      that.openAddEditPopup(header, content, that.onOpenGroupPopup, that.onSubmitAddEditGroup, that.onSubmitDeleteGroup, isEdit, groupId, imageName, createdDate, { groupAdmins: groupAdmins }, null);
    };
  }

  onAddEditVideo (isEdit, videoId, data, uploadDate) {
    const that = this;
    return () => {

      // values
      const inputCategoryEnglish = !isEdit ? '' : data?.eng?.category ?? '';
      const inputCategoryHebrew = !isEdit ? '' : data?.heb?.category ?? '';
      const inputMainTitleEnglish = !isEdit ? '' : data?.eng?.title ?? '';
      const inputMainTitleHebrew = !isEdit ? '' : data?.heb?.title ?? '';
      const inputTitleFooterEnglish = !isEdit ? '' : data?.eng?.title2 ?? '';
      const inputTitleFooterHebrew = !isEdit ? '' : data?.heb?.title2 ?? '';

      // TODO - remove data.media.video.videoLink from database (no longer needed)

      const value_inputYouTubeEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.youtube ?? '';
      const value_inputFacebookEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.fb ?? '';
      const value_inputInstaEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.insta ?? '';
      const value_inputTiktokEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.tiktok ?? '';
      const value_inputTwitterEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.twitter ?? '';
      const value_inputSpotifyEmbedLink = !isEdit ? '' : data?.media?.video?.videoLinks?.spotify ?? '';

      const value_inputSocialLinks_FB = !isEdit ? '' : data?.socialLinks?.fb ?? '';
      const value_inputSocialLinks_Insta = !isEdit ? '' : data?.socialLinks?.insta ?? '';
      const value_inputSocialLinks_Tiktok = !isEdit ? '' : data?.socialLinks?.tiktok ?? '';
      const value_inputSocialLinks_Twitter = !isEdit ? '' : data?.socialLinks?.twitter ?? '';
      const value_inputSocialLinks_LinkedIn = !isEdit ? '' : data?.socialLinks?.linkedIn ?? '';
      const value_inputSocialLinks_YouTube = !isEdit ? '' : data?.socialLinks?.youTube ?? '';
      const value_inputSocialLinks_Spotify = !isEdit ? '' : data?.socialLinks?.spotify ?? '';
      const value_inputSocialLinks_Web = !isEdit ? '' : data?.socialLinks?.website ?? '';

      const imageName = !isEdit ? '' : data.poster;
      const imageUrl =  !isEdit ? '' : that.global.config.firebase.storage.url.videoCover.get.call(that.global.config.firebase.storage.url, videoId, imageName);
      const coverImageClass = !isEdit ? 'class="hidden"' : '';
      const imageUpladPreview = `<div id="imageUpladPreview" ${coverImageClass}><img src="${imageUrl}"></div>`;

      const buttonText = !isEdit ? that.resources.addEditPopup.buttonTextAddVideo : that.resources.addEditPopup.buttonTextEditVideo;
      const deleteVideoBtn = !isEdit ? '' : `<div id="addEditDelete"><span id="addEditDeleteBtn">${that.resources.addEditPopup.buttonTextDeleteVideo}</span></div>`;

      
      const objInputsEmbedMedia = { value_inputYouTubeEmbedLink, value_inputFacebookEmbedLink, value_inputInstaEmbedLink, value_inputTiktokEmbedLink, value_inputTwitterEmbedLink, value_inputSpotifyEmbedLink };
      const objInputsSocialLinks = {
        fb: value_inputSocialLinks_FB,
        insta: value_inputSocialLinks_Insta,
        tiktok: value_inputSocialLinks_Tiktok,
        twitter: value_inputSocialLinks_Twitter,
        linkedin: value_inputSocialLinks_LinkedIn,
        youtube: value_inputSocialLinks_YouTube,
        spotify: value_inputSocialLinks_Spotify,
        web: value_inputSocialLinks_Web
      }

      const header = isEdit ? that.resources.addEditPopup.header.editFeaturedVideo : that.resources.addEditPopup.header.addFeaturedVideo;
      const content = `
        <div class="addEditItem">
          <span class="addEditLabel">${that.resources.addEditPopup.item.category}</span>
          <div class="inputsByLang eng">
            <label for="inputCategoryEnglish">${that.resources.addEditPopup.lang.eng.eng}</label>
            <input type="text" id="inputCategoryEnglish" class="formInput" name="inputCategoryEnglish" value="${inputCategoryEnglish}" required>
          </div>
          <div class="inputsByLang heb">
            <label for="inputCategoryHebrew">${that.resources.addEditPopup.lang.heb.heb}</label>
            <input type="text" id="inputCategoryHebrew" class="formInput" name="inputCategoryHebrew" value="${inputCategoryHebrew}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.category}</span>
          </div>
        </div>
        <div class="addEditItem">
          <span class="addEditLabel">${that.resources.addEditPopup.item.titleMain}</span>
          <div class="inputsByLang eng">
            <label for="inputMainTitleEnglish">${that.resources.addEditPopup.lang.eng.eng}</label>
            <input type="text" id="inputMainTitleEnglish" class="formInput" name="inputMainTitleEnglish" value="${inputMainTitleEnglish}" required>
          </div>
          <div class="inputsByLang heb">
            <label for="inputMainTitleHebrew">${that.resources.addEditPopup.lang.heb.heb}</label>
            <input type="text" id="inputMainTitleHebrew" class="formInput" name="inputMainTitleHebrew" value="${inputMainTitleHebrew}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.videoTitle}</span>
          </div>
        </div>
        <div class="addEditItem">
          <span class="addEditLabel">${that.resources.addEditPopup.item.titleFooter}</span>
          <div class="inputsByLang eng">
            <label for="inputTitleFooterEnglish">${that.resources.addEditPopup.lang.eng.eng}</label>
            <input type="text" id="inputTitleFooterEnglish" class="formInput" name="inputTitleFooterEnglish" value="${inputTitleFooterEnglish}" required>
          </div>
          <div class="inputsByLang heb">
            <label for="inputTitleFooterHebrew">${that.resources.addEditPopup.lang.heb.heb}</label>
            <input type="text" id="inputTitleFooterHebrew" class="formInput" name="inputTitleFooterHebrew" value="${inputTitleFooterHebrew}" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.videoTitle2}</span>
          </div>
        </div>

        ${this.utils.getEmbeds(objInputsEmbedMedia, that.resources, false)}

        <div class="addEditItem imageUpload">
          <span class="addEditLabel">${that.resources.addEditPopup.item.coverImage}</span>
          <div id="addEditVideoCover">
            ${imageUpladPreview}
            <input type="file" id="inputImageUpload" class="formInput" onchange="document.querySelector('#imageUpladPreview').classList.add('hidden')" name="inputImageUpload" required>
          </div>
          <div class="addEditItemHint">
            <span class="hintLabel">${that.resources.addEditPopup.hint.hint}</span>
            <span class="hintExample">${that.resources.addEditPopup.hint.imageSize}</span>
          </div>
        </div>

        ${that.utils.getInputsSocialLinks('post', objInputsSocialLinks, that.resources)}

        <div id="errorTxt"></div>
        <div id="addEditPopupButtons" class="addEditItem">
          <button id="addEditSubmitBtn" class="largeButton" type="submit">${buttonText}</button>
          ${deleteVideoBtn}
        </div>
      `;
      that.openAddEditPopup(header, content, that.onOpenVideoPopup, that.onSubmitAddEditVideo, that.onSubmitDeleteVideo, isEdit, videoId, imageName, uploadDate, data, null);
    };
  }

  onOpenVideoPopup  (popupContent, data, isEdit, global) {
    const addEditEmbedMenuItems = popupContent.querySelectorAll('.videoUploadEmbedItem');

    const inputYouTubeEmbedLink = popupContent.querySelector('#inputYouTubeEmbedLink');
    const inputFacebookEmbedLink = popupContent.querySelector('#inputFacebookEmbedLink');
    const inputInstaEmbedLink = popupContent.querySelector('#inputInstaEmbedLink');
    const inputTiktokEmbedLink = popupContent.querySelector('#inputTiktokEmbedLink');
    const inputTwitterEmbedLink = popupContent.querySelector('#inputTwitterEmbedLink');
    const inputSpotifyEmbedLink = popupContent.querySelector('#inputSpotifyEmbedLink');

    const embedInputs = { youtube: inputYouTubeEmbedLink, fb: inputFacebookEmbedLink, insta: inputInstaEmbedLink, tiktok: inputTiktokEmbedLink, twitter: inputTwitterEmbedLink, spotify: inputSpotifyEmbedLink };

    global.utils.initShowHideEmbeds(embedInputs, addEditEmbedMenuItems);
  }

  onOpenGroupPopup (popupContent, data, isEdit, global) {
    const groupAdminsAdded = popupContent.querySelector('#groupAdminsAdded');
    const inputAddGroupAdmins = popupContent.querySelector('#inputAddGroupAdmins');
    const btnAddGroupAdmins = popupContent.querySelector('#btnAddGroupAdmins');
    const errTextAddGroupAdmin = popupContent.querySelector('#errTextAddGroupAdmin');

    errTextAddGroupAdmin.innerHTML = '';

    const bindRemoveAdmins = () => {
      const elementsAdminsAdded = popupContent.querySelectorAll('.clickToRemoveAdmin');
      global.utils.attachEventListeners('click', (elem, event) => {
        // TODO - need to reauthenticate if removing self
        
        // remove from list and div
        const removeId = elem.parentNode.getAttribute('data-id');
        data.groupAdmins[removeId] = null;
        elem.parentNode.remove();
      }, elementsAdminsAdded);
    };

    if (data && data.groupAdmins !== null && Object.keys(data.groupAdmins).length !== 0) {
      bindRemoveAdmins();
    }

    global.utils.attachEventListeners ('click', (element, event) => {
      // this.utils.showLoading(true);
      btnAddGroupAdmins.disabled = true;

      errTextAddGroupAdmin.innerHTML = '';
      
      inputAddGroupAdmins.classList.remove('err');
      const emailToAdd = inputAddGroupAdmins.value;
      const regExValidEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      
      if (!emailToAdd) {
        errTextAddGroupAdmin.innerHTML = global.resources.addEditPopup.errorTxt.emptyEmail;
        inputAddGroupAdmins.classList.add('err');
        btnAddGroupAdmins.disabled = false;
      }
      else if (!emailToAdd.match(regExValidEmail)) {
        errTextAddGroupAdmin.innerHTML = global.resources.addEditPopup.errorTxt.invalidEmail;
        inputAddGroupAdmins.classList.add('err');
        btnAddGroupAdmins.disabled = false;
      }
      else {
        // show loading on popup
        global.utils.getData(global.references.DataStructure.users, (users) => {
          global.retrievedData.users = users;
          if (users) {
              data.allUsers = users;
              const userId = Object.keys(users).find(key => { return users[key].providerData.email.normalize() === emailToAdd.normalize() });
              const userData = users[userId];

              if (userData) {
                data.groupAdmins = data.groupAdmins ? data.groupAdmins : {};
                if (!data.groupAdmins[userId]) {
                  data.groupAdmins[userId] = { id: userId, email: emailToAdd };

                  const emailHtml = `<div data-id="${userId}" class="divGroupAdminAdded"><span>${emailToAdd}</span><span class="clickToRemoveAdmin"></span></div>`;
                  groupAdminsAdded.insertAdjacentHTML('beforeend', emailHtml);

                  bindRemoveAdmins();

                  groupAdminsAdded.classList.remove('hidden');
                }
                inputAddGroupAdmins.value = '';
              }
              else {
                errTextAddGroupAdmin.innerHTML = global.resources.addEditPopup.errorTxt.userNotFound;
              }
              // this.utils.showLoading(false);
          }
          btnAddGroupAdmins.disabled = false;
        });
      }
    }, [btnAddGroupAdmins]);
  }

  openAddEditPopup (header, content, onOpen, onSubmit, onDelete, isEdit, id, imageName, date, data, onClose) {
    const that = this;
    const popupContent = this.addEditPopup.querySelector('#addEditContent');
    const popupHeader = this.addEditPopup.querySelector('#addEditHeader');

    // TODO - fix bug on scroll input hints remain floating
    // TODO - when popup open, cancel background scroll
    
    popupHeader.innerHTML = header;
    popupContent.innerHTML = content;

    const addEditSubmitBtn = document.querySelector('#addEditSubmitBtn');
    this.utils.attachEventListeners('click', onSubmit(isEdit, id, imageName, date, popupContent, data, this.global), [addEditSubmitBtn]);
    if (isEdit) {
      const onSubmitDelete = document.querySelector('#addEditDeleteBtn');
      this.utils.attachEventListeners('click', onDelete(id, imageName, this.global), [onSubmitDelete]);
    }

    this.openPopup();
    popupContent.scrollTop = 0;

    if (onOpen && typeof onOpen == 'function') { onOpen(popupContent, data, isEdit, this.global) }

    this.onCloseCallback = onClose;
    const closeBtn = this.addEditPopup.querySelector('.closeBtn');
    this.utils.attachEventListeners('click', function () { that.closePopup.call(that) }, [closeBtn]);
  }

  onSubmitAddEditGroup (isEdit, groupId, imageName, createdDate, popupContent, data, global) {
    const onAddEditGroup = () => {
      const addEditPopupButtons = popupContent.querySelector('#addEditPopupButtons');
      const submitBtnDisabled = addEditPopupButtons.classList.contains('disabled');
      if (submitBtnDisabled) return;

      addEditPopupButtons.classList.add('disabled');

      // TODO - check if in textarea inputGroupDesc*, html should be allow (check security - injections)
      // also check security for regular inputs

      // validate form
      const inputAcronym = popupContent.querySelector('#inputAcronym');
      const inputGroupNameEng = popupContent.querySelector('#inputGroupNameEng');
      const inputGroupNameHeb = popupContent.querySelector('#inputGroupNameHeb');
      const inputGroupDescEng = popupContent.querySelector('#inputGroupDescEng');
      const inputGroupDescHeb = popupContent.querySelector('#inputGroupDescHeb');
      const inputImageUpload = popupContent.querySelector('#inputImageUpload');
      
      // Social Links
      const inputSocialLinks_fb = popupContent.querySelector('#inputSocialLinks_fb').querySelector('input');
      const inputSocialLinks_insta = popupContent.querySelector('#inputSocialLinks_insta').querySelector('input');
      const inputSocialLinks_tiktok = popupContent.querySelector('#inputSocialLinks_tiktok').querySelector('input');
      const inputSocialLinks_twitter = popupContent.querySelector('#inputSocialLinks_twitter').querySelector('input');
      const inputSocialLinks_linkedin = popupContent.querySelector('#inputSocialLinks_linkedin').querySelector('input');
      const inputSocialLinks_spotify = popupContent.querySelector('#inputSocialLinks_spotify').querySelector('input');
      const inputSocialLinks_youtube = popupContent.querySelector('#inputSocialLinks_youtube').querySelector('input');
      const inputSocialLinks_web = popupContent.querySelector('#inputSocialLinks_web').querySelector('input');
      const inputSocialLinks_linktree = popupContent.querySelector('#inputSocialLinks_linktree').querySelector('input');
      const inputSocialLinks_email = popupContent.querySelector('#inputSocialLinks_email').querySelector('input');
      const inputSocialLinks_phone = popupContent.querySelector('#inputSocialLinks_phone').querySelector('input');
      const inputSocialLinks_donate = popupContent.querySelector('#inputSocialLinks_donate').querySelector('input');

      // Group Admins
      const inputAddGroupAdmins = popupContent.querySelector('#inputAddGroupAdmins');
      const errTextAddGroupAdmin = popupContent.querySelector('#errTextAddGroupAdmin');

      // Is Hidden
      const checkbox_isHidden = popupContent.querySelector('#checkbox_isHidden');

      const keepOriginalLogo = isEdit && !document.querySelector('#imageUpladPreview').classList.contains('hidden');

      // TODO - get required fields by checking required attribute on inputs (instead as hardcoded as below)
      const hundredKb = (1024 * 1024) / 10;
      const maxSize = hundredKb;
      const requiredInputs = [inputGroupNameEng, inputGroupNameHeb, inputGroupDescEng, inputGroupDescHeb, inputImageUpload];
      let hasError = false;
      let elFirstError = null;
      requiredInputs.forEach(element => {
        const isEmpty_nonFileInput = !(element.getAttribute('type') == 'file' && keepOriginalLogo) && (!element.value || element.value == '');
        if (isEmpty_nonFileInput || (element.getAttribute('type') == 'file' &&
          (!keepOriginalLogo && (!element.files.length || element.files[0].size > maxSize || !element.files[0].name.match(/.(jpe?g|png)$/i))))) {
            if (!hasError) { elFirstError = element; }
            element.classList.add('err');
            hasError = true;
        }
      });

      let atLeastOneAdminExists = false;
      if (data.groupAdmins && Object.keys(data.groupAdmins).length) {
        for (let prop in data.groupAdmins) {
          if (data.groupAdmins[prop] !== null) {
            atLeastOneAdminExists = true;
            break;
          }
        }
      }
      
      if (atLeastOneAdminExists === false) {
        errTextAddGroupAdmin.innerHTML = global.resources.addEditPopup.errorTxt.needAdmin;
       
        requiredInputs.push(inputAddGroupAdmins);
        if (!hasError) { elFirstError = inputAddGroupAdmins; }
        inputAddGroupAdmins.classList.add('err');
        hasError = true;
      }

      if (hasError) {
        const doOnFocus = (el) => {
          el.classList.remove('err');
          errTextAddGroupAdmin.innerHTML = '';
        };
        global.utils.attachEventListeners('focus', doOnFocus, requiredInputs);
        const elem = elFirstError.parentNode.classList.contains('addEditItem') ? elFirstError.parentNode : elFirstError.parentNode.parentNode;
        elem.scrollIntoView();
        addEditPopupButtons.classList.remove('disabled');
        return;
      }

      // Group Logo Name
      let imageFile = null;
      let logoName = imageName;
      if (!keepOriginalLogo) {
        imageFile = inputImageUpload.files[0];
        const originalName = imageFile.name;
        const extension = originalName.substring(originalName.lastIndexOf('.'));
        const timestamp = new Date().getTime();
        logoName = 'logo' + timestamp + extension;
      }

      createdDate = !isEdit ? (new Date()).getTime() : (createdDate || (new Date()).getTime());      

      const groupAdmins = data.groupAdmins;
      const groupData = { checkbox_isHidden, groupAdmins, logoName, inputAcronym, inputGroupNameEng, inputGroupNameHeb, inputGroupDescEng, inputGroupDescHeb, inputSocialLinks_fb, inputSocialLinks_insta, inputSocialLinks_tiktok, inputSocialLinks_twitter, inputSocialLinks_spotify, inputSocialLinks_youtube, inputSocialLinks_linktree, inputSocialLinks_linkedin, inputSocialLinks_web, inputSocialLinks_email, inputSocialLinks_phone, inputSocialLinks_donate };
      global.modules.Firebase.addEditGroup(groupData, imageFile, logoName, keepOriginalLogo, isEdit, groupId, createdDate, false, data.allUsers);
      setTimeout(() => { addEditPopupButtons.classList.remove('disabled'); }, 200);
    };
    return onAddEditGroup;
  }

  onSubmitAddEditVideo (isEdit, videoId, imageName, uploadDate, popupContent, data, global) {
    const onAddEditVideo = () => {
      // TODO add disable button while loading
      // const submitBtnDisabled = addEditPopupButtons.classList.contains('disabled');
      // if (submitBtnDisabled) return;

      // addEditPopupButtons.classList.add('disabled');

      // validate form
      const errorTxt = popupContent.querySelector('#errorTxt');
      const categoryEng = popupContent.querySelector('#inputCategoryEnglish');
      const categoryHeb = popupContent.querySelector('#inputCategoryHebrew');
      const titleEng = popupContent.querySelector('#inputMainTitleEnglish');
      const titleHeb = popupContent.querySelector('#inputMainTitleHebrew');
      const titleFooterEng = popupContent.querySelector('#inputTitleFooterEnglish');
      const titleFooterHeb = popupContent.querySelector('#inputTitleFooterHebrew');
      
      const inputYouTubeEmbedLink = popupContent.querySelector('#inputYouTubeEmbedLink');
      const inputFacebookEmbedLink = popupContent.querySelector('#inputFacebookEmbedLink');
      const inputInstaEmbedLink = popupContent.querySelector('#inputInstaEmbedLink');
      const inputTiktokEmbedLink = popupContent.querySelector('#inputTiktokEmbedLink');
      const inputTwitterEmbedLink = popupContent.querySelector('#inputTwitterEmbedLink');
      const inputSpotifyEmbedLink = popupContent.querySelector('#inputSpotifyEmbedLink');
      
      const embedInputs = { youtube: inputYouTubeEmbedLink, fb: inputFacebookEmbedLink, insta: inputInstaEmbedLink, tiktok: inputTiktokEmbedLink, twitter: inputTwitterEmbedLink, spotify: inputSpotifyEmbedLink };

      const inputImageUpload = popupContent.querySelector('#inputImageUpload');

      // Social Links
      const inputSocialLinks_fb = popupContent.querySelector('#inputSocialLinks_fb').querySelector('input');
      const inputSocialLinks_insta = popupContent.querySelector('#inputSocialLinks_insta').querySelector('input');;
      const inputSocialLinks_tiktok = popupContent.querySelector('#inputSocialLinks_tiktok').querySelector('input');
      const inputSocialLinks_twitter = popupContent.querySelector('#inputSocialLinks_twitter').querySelector('input');
      const inputSocialLinks_linkedin = popupContent.querySelector('#inputSocialLinks_linkedin').querySelector('input');
      const inputSocialLinks_youtube = popupContent.querySelector('#inputSocialLinks_youtube').querySelector('input');
      const inputSocialLinks_spotify = popupContent.querySelector('#inputSocialLinks_spotify').querySelector('input');
      const inputSocialLinks_web = popupContent.querySelector('#inputSocialLinks_web').querySelector('input');

      const keepOriginalCover = isEdit && !document.querySelector('#imageUpladPreview').classList.contains('hidden');

      // TODO - get required fields by checking required attribute on inputs (instead as hardcoded as below)
      const requiredInputs = [categoryEng, categoryHeb, titleEng, titleHeb, titleFooterEng, titleFooterHeb, inputImageUpload];
      let hasError = false;
      let elFirstError = null;
      requiredInputs.forEach(element => {
        const isEmpty_nonFileInput = !(element.getAttribute('type') == 'file' && keepOriginalCover) && (!element.value || element.value == '');
        const noFile_or_badType_fileInput = element.getAttribute('type') == 'file' && (!keepOriginalCover && (!element.files.length || !element.files[0].name.match(/.(jpe?g|png)$/i)));
        if (isEmpty_nonFileInput || noFile_or_badType_fileInput) {
          if (!hasError) { elFirstError = element; }
          element.classList.add('err');
          hasError = true;
        }
      });

      const hasVideo = Object.values(embedInputs).some(input => input.value !== '');
      if (!hasVideo) {
        const visibleInput = Object.values(embedInputs).find(input => {
          return !input.closest('.addEditItem').classList.contains('hidden');
        });
        if (visibleInput) {
          requiredInputs.push(visibleInput)
          if (!hasError) { elFirstError = visibleInput; }
          visibleInput.classList.add('err');
          hasError = true;
        }
      }

      if (hasError) {
        const doOnFocus = (el) => {
          el.classList.remove('err');
        };
        global.utils.attachEventListeners('focus', doOnFocus, requiredInputs);
        const elem = elFirstError.parentNode.classList.contains('addEditItem') ? elFirstError.parentNode : elFirstError.parentNode.parentNode;
        elem.scrollIntoView();
        return;
      }

      //  TODO - write error text - errorTxt.innerHTML = resources.addEditPopup.errorTxt.missingTitle;

      // Video Poster Name
      let imageFile = null;
      let posterName = imageName;
      if (!keepOriginalCover) {
        imageFile = inputImageUpload.files[0];
        const originalName = imageFile.name;
        const extension = originalName.substring(originalName.lastIndexOf('.')); 
        const timestamp = new Date().getTime();
        posterName = 'poster' + timestamp + extension;
      }

      uploadDate = !isEdit ? (new Date()).getTime() : uploadDate;
      
      const videoData = { posterName, categoryEng, categoryHeb, titleEng, titleHeb, titleFooterEng, titleFooterHeb, embedInputs, inputSocialLinks_fb, inputSocialLinks_tiktok, inputSocialLinks_web, inputSocialLinks_spotify, inputSocialLinks_insta, inputSocialLinks_youtube, inputSocialLinks_linkedin, inputSocialLinks_twitter };
      global.modules.Firebase.addEditVideo(videoData, imageFile, posterName, keepOriginalCover, isEdit, videoId, uploadDate);
    };
    return onAddEditVideo;
  }

  onSubmitDeletePost  (postId, imageName, global) {
    const onDeletePost = () => {
      const addEditPopupButtons = document.querySelector('#addEditPopupButtons');
      const submitBtnDisabled = addEditPopupButtons.classList.contains('disabled');
      if (submitBtnDisabled) return;

      const onConfirm = {
        func: () => { global.modules.Firebase.deletePost(postId); },
        btnConfirm: '',
        btnCancel: ''
      }

      const imageTag =  imageName ? `<img src="${global.config.firebase.storage.url.postImage.get.call(global.config.firebase.storage.url, postId, imageName)}">` : '';
      // TODO - add below: <span class="hiddenTitle"${logo}>${Title}</span>
      const contentHTML = `
        <div class='deleteItemConfirmContent'>
          <div class="deleteItemImagePreview">${imageTag}</div>
          <div class="deleteItemConfirmText">${global.resources.addEditPopup.confirmDeletePost}</div>
        </div>
      `;
      
      global.utils.openLightBox(contentHTML, null, onConfirm, global.resources);

    };
    return onDeletePost;
  }

  onSubmitDeleteGroup (groupId, imageName, global) {
    const onDeleteGroup = () => {
      const addEditPopupButtons = document.querySelector('#addEditPopupButtons');
      const submitBtnDisabled = addEditPopupButtons.classList.contains('disabled');
      if (submitBtnDisabled) return;

      const onConfirm = {
        func: () => { global.modules.Firebase.deleteGroup(groupId); },
        btnConfirm: '',
        btnCancel: ''
      }

      const imageUrl =  global.config.firebase.storage.url.groupLogo.get.call(global.config.firebase.storage.url, groupId, imageName);
      const contentHTML = `
        <div class='deleteItemConfirmContent'>
          <div class="deleteItemImagePreview"><img src="${imageUrl}"></div>
          <div class="deleteItemConfirmText">${global.resources.addEditPopup.confirmDeleteGroup}</div>
        </div>
      `;
      
      global.utils.openLightBox(contentHTML, null, onConfirm, global.resources);
    };
    return onDeleteGroup;
  }

  onSubmitDeleteVideo (videoId, imageName, global) {
    const onDeleteVideo = () => {
      const addEditPopupButtons = document.querySelector('#addEditPopupButtons');
      const submitBtnDisabled = addEditPopupButtons.classList.contains('disabled');
      if (submitBtnDisabled) return;

      const onConfirm = {
        func: () => { global.modules.Firebase.deleteVideo(videoId); },
        btnConfirm: '',
        btnCancel: ''
      };

      const imageUrl =  global.config.firebase.storage.url.videoCover.get.call(global.config.firebase.storage.url, videoId, imageName);
      const contentHTML = `
        <div class='deleteItemConfirmContent'>
          <div class="deleteItemImagePreview"><img src="${imageUrl}"></div>
          <div class="deleteItemConfirmText">${global.resources.addEditPopup.confirmDeleteVideo}</div>
        </div>
      `;
      
      global.utils.openLightBox(contentHTML, null, onConfirm, global.resources);
    };
    return onDeleteVideo;
  }

}

export { AddEditPopup } 