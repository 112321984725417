// class Player {
//     constructor(global) {
//         this.name = 'Player';
//         this.global = global;
//         this.utils = global.utils;
//         this.resources = this.global.getResources();
//         this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);

//         this.closeVideoPlayer = null;
//         this.player = null;
//     }

//     init() {
//         console.log('Player component is loaded');
//     }

//     close() {
//         if (this.closeVideoPlayer) this.closeVideoPlayer();
//     }

//     loadYouTubeAPI() {
//         return new Promise((resolve, reject) => {
//             if (window.YT) {
//                 console.log('YouTube API already loaded');
//                 resolve(window.YT);
//                 return;
//             }

//             const tag = document.createElement('script');
//             tag.src = 'https://www.youtube.com/iframe_api';
//             tag.onload = () => {
//                 window.onYouTubeIframeAPIReady = () => {
//                     console.log('YouTube API loaded');
//                     resolve(window.YT);
//                 };
//             };
//             tag.onerror = reject;
//             document.head.appendChild(tag);
//         });
//     }

//     onPlayerReady(event) {
//         console.log('Player is ready, starting video');
//         event.target.playVideo();
//     }

//     initPlayer(videoId) {
//         return this.loadYouTubeAPI().then(YT => {
//             return new Promise((resolve, reject) => {
//                 this.player = new YT.Player('video' + videoId, {
//                     events: {
//                         'onReady': event => {
//                             this.onPlayerReady(event);
//                             resolve(this.player);
//                         },
//                         'onError': error => {
//                             console.error('YouTube Player Error:', error);
//                             reject(error);
//                         }
//                     }
//                 });
//             });
//         }).catch(err => console.error('Failed to load YouTube IFrame API:', err));
//     }

//     openPlayerScreen(videoData, onClose) {
//         this.utils.closeLoginForm();
//         const ifcPlayer = document.querySelector('#ifcPlayer');
//         const ifcPlayerVideo = ifcPlayer.querySelector('#ifcPlayerVideo');
//         const ifcPlayerFooter = ifcPlayer.querySelector('#ifcPlayerFooter');
//         const closeBtn = ifcPlayer.querySelector('.closeBtn');
//         const appMain = document.querySelector('#appMain');
//         const embededVideo = videoData?.media?.video?.embed?.youtube ?? '';

//         const socialLinks = this.utils.getSocialLinks_large(videoData, this.resources.video.viewVideoOn);

//         ifcPlayerVideo.innerHTML = embededVideo;
//         ifcPlayerFooter.innerHTML = socialLinks;
//         ifcPlayer.classList.add('show');
//         appMain.style.display = 'none';

//         const doEachTime = () => {
//             ifcPlayer.classList.remove('show');
//             appMain.style.display = 'block';
//             closeBtn.removeEventListener('click', this.close.bind(this));
//             this.closeVideoPlayer = null;
//         };
//         this.closeVideoPlayer = onClose ? () => { onClose(); doEachTime(); } : doEachTime;

//         closeBtn.addEventListener('click', this.close.bind(this));
//     }

//     startPlayer(videoId, videoData) {
//         const onClose = () => {
//             if (this.player) {
//                 console.log('Stopping video player');
//                 this.player.stopVideo();
//             } else {
//                 console.log('Player is not defined or not ready');
//             }
//         };

//         this.openPlayerScreen(videoData, onClose.bind(this));
//         this.initPlayer(videoId).then(() => {
//             console.log('Player Setup Completed');
//         }).catch(err => console.error('Error initializing player:', err));
//     }
// }

// export { Player }

class Player {
    constructor(global) {
        this.name = 'Player';
        this.global = global;
        this.utils = global.utils;
        this.resources = this.global.getResources();
        this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);

        this.closeVideoPlayer = null;
        this.player = null;
        this.embedType = ''; // Add embedType as a class property
    }

    init() {
        console.log('Player component is loaded');
    }

    close() {
        if (this.closeVideoPlayer) this.closeVideoPlayer();
    }

    loadAPI(apiUrl, globalVarName, onLoadCallback) {
        return new Promise((resolve, reject) => {
            if (window[globalVarName]) {
                console.log(`${this.embedType} API script already loaded to DOM`);
                resolve(window[globalVarName]);
                return;
            }

            const tag = document.createElement('script');
            tag.src = apiUrl;
            tag.onload = () => {
                window[onLoadCallback] = () => {
                    console.log(`${this.embedType} API script loaded to DOM`);
                    resolve(window[globalVarName]);
                };
            };
            tag.onerror = reject;
            document.head.appendChild(tag);
        });
    }

    onPlayerReady(event) {
        console.log('Player is ready, starting video');
        event.target.playVideo();
    }

    // TODO - do we need to do initPlayer for posts as well???

    initPlayer(videoId) {
        let apiPromise;

        if (this.embedType === 'youtube') {
            apiPromise = this.loadAPI('https://www.youtube.com/iframe_api', 'YT', 'onYouTubeIframeAPIReady');
        } else if (this.embedType === 'tiktok') {
            // apiPromise = this.loadAPI('https://www.tiktok.com/embed.js', 'ttEmbed', 'onTikTokIframeAPIReady');
            apiPromise = Promise.resolve();
        }

        if (apiPromise) {
            return apiPromise.then(api => {
                return new Promise((resolve, reject) => {
                    if (this.embedType === 'youtube') {
                        this.player = new api.Player('video' + videoId, {
                            events: {
                                'onReady': event => {
                                    this.onPlayerReady(event);
                                    resolve(this.player);
                                },
                                'onError': error => {
                                    console.error('YouTube Player Error:', error);
                                    reject(error);
                                }
                            }
                        });
                    } else if (this.embedType === 'tiktok') {
                        console.log('TikTok Player is ready');
                        resolve(); // Assuming TikTok player doesn't have a similar initialization API as YouTube
                    }
                });
            }).catch(err => console.error(`Failed to load ${this.embedType} IFrame API:`, err));
        } else {
            return Promise.resolve();
        }
    }

    openPlayerScreen(videoData, onClose) {
        this.utils.closeLoginForm();
        const ifcPlayer = document.querySelector('#ifcPlayer');
        const ifcPlayerVideo = ifcPlayer.querySelector('#ifcPlayerVideo');
        const ifcPlayerFooter = ifcPlayer.querySelector('#ifcPlayerFooter');
        const closeBtn = ifcPlayer.querySelector('.closeBtn');
        const appMain = document.querySelector('#appMain');

        const embededVideo = Object.values(videoData.media.video.embed).find(embedCode => embedCode) || '';

        const socialLinks = this.utils.getSocialLinks_large(videoData, this.resources.video.viewVideoOn);

        ifcPlayerVideo.innerHTML = embededVideo;
        ifcPlayerFooter.innerHTML = socialLinks;
        ifcPlayer.classList.add('show');
        appMain.style.display = 'none';

        const doEachTime = () => {
            ifcPlayer.classList.remove('show');
            appMain.style.display = 'block';
            closeBtn.removeEventListener('click', this.close.bind(this));
            this.closeVideoPlayer = null;
        };
        this.closeVideoPlayer = onClose ? () => { onClose(); doEachTime(); } : doEachTime;

        closeBtn.addEventListener('click', this.close.bind(this));
    }

    startPlayer(videoId, videoData) {
        this.embedType = this.getEmbedType(videoData);

        if (!this.embedType) {
            console.log('No videos available');
            return;
        }

        const onClose = this.getOnCloseCallback();

        this.openPlayerScreen(videoData, onClose.bind(this));

        this.initPlayer(videoId).then(() => {
            console.log(`${this.embedType} Player Setup Completed`);
        }).catch(err => console.error(`Error initializing ${this.embedType} player:`, err));
    }

    getEmbedType(videoData) {
        const embedEntries = Object.entries(videoData.media.video.embed);
        for (const [key, value] of embedEntries) {
            if (value) {
                return key;
            }
        }
        return '';
    }

    getOnCloseCallback() {
        if (this.embedType === 'youtube') {
            return () => {
                if (this.player) {
                    console.log('Stopping YouTube player');
                    this.player.stopVideo();
                } else {
                    console.log('YouTube player is not defined or not ready');
                }
            };
        } else if (this.embedType === 'tiktok') {
            return () => {
                console.log('Handling TikTok player close');
                const ifcPlayerVideo = document.querySelector('#ifcPlayerVideo');
                if (ifcPlayerVideo) {
                    ifcPlayerVideo.innerHTML = ''; 
                }
            };
        }
        // Add more conditions here for other platforms
        return () => {
            console.log('Handling generic player close');
        };
    }
}

export { Player }




