class Team {

  constructor (global) {
    this.name = 'Team';
    this.global = global;
    this.utils = global.utils;
    this.resources = this.global.getResources();
    this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);
  } 

  init () {
    console.log('Team component is loaded');
    this.buildHtml();
    this.bindEvents();
  }

  buildHtml () {

    // HTML Our Team
    const teamMembers = [];
    let teamHtml = '';
    let member = '';
    teamMembers.push(`<h2>${this.resources.about.ourTeam.title}</h2>`);
    teamMembers.push('<div class="infoBoxes">');
    for (let prop in this.resources.about.ourTeam.team) {
      member = this.resources.about.ourTeam.team[prop];

      const socialLinks = this.utils.getSocialLinks_small(member, 'memeberLinkedIcons');

      // HTML Member Infobox
      teamMembers.push(`<div class="infoBox">
                        <header>
                          <h3 class="infoBoxTitle">${member.name}</h3>
                          <div class="memberTitle">${member.title}</div>
                        </header>
                        <content>
                          <div class="teamMemberPic"><img src="${member.pic}" /></div>
                          <div class="teamMemberAbout">
                            <span class="hiddenTitle" style="background-image:url(${member.pic})">${member.name}</span>
                            <div>${member.about}</div>
                          </div>
                        </content>
                        <footer>${socialLinks}</footer>
                      </div>`);
    }
    teamMembers.push('</div>');
    teamHtml = teamMembers.join('');

    const rootEl = document.querySelector('#contentContainer');
    const html = `
      <main class="contentTeam">
        <div id="teamHeader">
          <img id="ifcTeamPic" src="/images/Theme/MeetUp5-ZoomScreenshot.jpg" />
        </div>
        <div id="ourTeamText">
          ${teamHtml}
        </div>
      </main>
    `;
    rootEl.insertAdjacentHTML('beforeend', html);
  }

  bindEvents () {
  }
}

export { Team } 