class Groups {

  constructor (global) {
    this.name = 'Groups';
    this.global = global;
    this.resources = this.global.getResources();
    this.uiLang = this.global.config.uiLang.get.call(this.global.config.uiLang);
  } 

  init () {
    console.log('Groups component is loaded');
    this.buildHtml();
  }

  buildHtml () {
    const rootEl = document.querySelector('#contentContainer');
    const html = `
      <main class="contentGroups">
        <div class="contentGroupsWrapper">
          <div class="groupsHeader">
            <h2 class="leadingGroupsTitle">${this.resources.groupsGeneral.Title1}</h2>
            <p class="leadingGroupsTitle">${this.resources.groupsGeneral.Title2}</p>
          </div>
          <div id="divGroups_GroupsPage"></div>
        </div>
      </main>
    `;
    rootEl.insertAdjacentHTML('beforeend', html);

    const divGroups_GroupsPage = document.querySelector('#divGroups_GroupsPage');
    this.global.modules[this.global.references.ModuleNames.Groups].updateGroups(divGroups_GroupsPage);
  }
}

export { Groups } 