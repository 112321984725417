// export default {
//     googleClientID:'xxxx'
// };
class Config {

    constructor (global) {
        this.config = this.getConfig(global);
    }

    getConfig (global) {
        return {
            host: {
                hostUrl: global.references.Host.prodUrl,
                env: {
                    prod: global.references.Host.prodUrl,
                    test: global.references.Host.testUrl,
                    dev: global.references.Host.devUrl
                },
                get: function () {
                    return this.host.hostUrl;
                },
                set: function (env) {
                    this.host.hostUrl = this.host.env[env];
                }
            },
            uiLang: {
                defualtLang: 'eng', // TODO get eng/heb from refferences
                get: function () {
                    if (!localStorage.getItem('uiLang')) {
                        localStorage.setItem('uiLang', this.defualtLang);
                    }
                    return localStorage.getItem('uiLang');
                },
                set: function (lang) {
                    localStorage.setItem('uiLang', lang);
                }
            },
            firebase: {
                firebaseConfig: {
                    get: function () {
                        // For Firebase JS SDK v7.20.0 and later, measurementId is optional
                        return {
                            apiKey: "AIzaSyAf6raBs9kByI0aRQPon4Yw16Ir_mc-Qlc",
                            authDomain: "tools-website-core.firebaseapp.com",
                            projectId: "tools-website-core",
                            storageBucket: "tools-website-core.appspot.com",
                            messagingSenderId: "477379369178",
                            appId: "1:477379369178:web:0a8e8106ea396e52f12de3"
                        };
                    }
                },
                storage: {
                    url: {
                        videoCover: {
                           get: function (videoId, imageName) {
                                const url = `https://firebasestorage.googleapis.com/v0/b/tools-website-core.appspot.com/o/public%2Fvideos%2F${videoId}%2F${imageName}?alt=media&token=0c70a668-465a-426d-9bde-d3726f9d9bd5`;
                                return url;
                           }
                        },
                        groupLogo: {
                            get: function (groupId, imageName) {
                                const url = `https://firebasestorage.googleapis.com/v0/b/tools-website-core.appspot.com/o/public%2Fgroups%2F${groupId}%2F${imageName}?alt=media&token=088459fb-db44-41d6-b570-6a9f90bd3237`;
                                return url;
                            }
                        },
                        profilePic: {
                            get: function (userId, imageName) {
                                const url = `https://firebasestorage.googleapis.com/v0/b/tools-website-core.appspot.com/o/public%2Fusers%2F${userId}%2F${imageName}?alt=media&token=088459fb-db44-41d6-b570-6a9f90bd3237`;
                                return url;
                            }
                        },
                        postImage: {
                            get: function (postId, imageName) {
                                const url = `https://firebasestorage.googleapis.com/v0/b/tools-website-core.appspot.com/o/public%2Fposts%2F${postId}%2F${imageName}?alt=media&token=088459fb-db44-41d6-b570-6a9f90bd3237`;
                                return url;
                            }
                        }
                    }
                }
            },
            sort: {
                videoSort: {
                    sortType : global.references.Sort.oldestFirst,
                    get: function () {
                        // TODO - when changing sortType, not refreshing in browser
                        return this.videoSort.sortType;
                    },
                    set: function (sortType) {
                        this.videoSort.sortType = sortType;
                        return this.videoSort.sortType;
                    }
                },
                postsSort: {
                    sortType : global.references.Sort.newestFirst,
                    get: function () {
                        // TODO - when changing sortType, not refreshing in browser
                        return this.postsSort.sortType;
                    },
                    set: function (sortType) {
                        this.postsSort.sortType = sortType;
                        return this.postsSort.sortType;
                    }
                }
            },
            filter: {
                postsFilter: {
                    filterType : global.references.Filter.posts.all,
                    get: function () {
                        // TODO - when changing filterType, not refreshing in browser
                        return this.postsFilter.filterType;
                    },
                    set: function (filterType) {
                        this.postsFilter.filterType = filterType;
                        return this.postsFilter.filterType;
                    }
                }
            },
            metaTags: {
                get: function (metaTagsData) {
                    let metaData = {};
                    // <meta name="keywords" content="summary"></meta>
                    // <meta name="twitter:card" content="summary">
                    // <meta name="twitter:site" content="@content">
                    // <meta name="twitter:title" content="Home page">
                    // <meta name="twitter:description" content="An angular app that is actually search crawler bot friendly">
                    // <meta name="twitter:image" content="https://www.example.com/assets/seo.jpeg"></meta>
                    switch (metaTagsData.page) {
                        case 'home':
                            metaData = {
                                title: { property: 'og:title', content: 'IFC - The Israeli Free-Market Coalition' },
                                desc: { property: 'og:description', content: 'IFC is an umbrella organization that unites and strengthens groups in Israel working to promote Liberty and Free Market.' },
                                img: { property: 'og:image', content: `${this.host.get.call(this)}images/Theme/ogLogo.png` },
                                imgW: { property: 'og:image:width', content: '1280' },
                                imgH: { property: 'og:image:height', content: '720' },
                                url: { property: 'og:url', content: `${this.host.get.call(this)}?utm_page=home` },
                                type: { property: 'og:type', content: 'website' }
                            };
                            break;
                        case 'postsFeed':
                            const defualt = {
                                title: 'Posts Feed for The Israeli Free-Market Coalition',
                                desc: 'Content feed of posts directly uploaded by groups and influencers that are part of IFC, the Israeli Free-Market Coalition',
                                img: `images/Theme/ogLogo.png`,
                                imgW: '1280',
                                imgH: '720',
                                url: `?utm_page=postsFeed`,
                                type: 'website'
                            };
                            const desc = metaTagsData.data.desc ? (metaTagsData.data.desc.length > 120 ? metaTagsData.data.desc.substr(0, 119) + '...' : metaTagsData.data.desc) : defualt.desc;
                            metaData = {
                                title: { property: 'og:title', content: metaTagsData.data.title || defualt.title },
                                desc: { property: 'og:description', content: desc },
                                img: { property: 'og:image', content: (metaTagsData.data.img || defualt.img) },
                                imgW: { property: 'og:image:width', content: metaTagsData.data.imgW || defualt.imgW },
                                imgH: { property: 'og:image:height', content: metaTagsData.data.imgH || defualt.imgH },
                                url: { property: 'og:url', content: this.host.get.call(this) + (metaTagsData.data.url || defualt.url) },
                                type: { property: 'og:type', content: metaTagsData.data.type || defualt.type }
                            };
                            if (metaTagsData.data.video) {
                                metaData['video'] = { property: 'og:video', content: metaTagsData.data.video };
                                metaData['video:secure_url'] = { property: 'og:video:secure_url', content: metaTagsData.data.video };
                            }
                            break;
                    }
                    return metaData;
                }
                
            }
        };
    }
}
export { Config };